import { mergeAttributes, Node } from "@tiptap/core";
import {
  NodeViewContent,
  NodeViewProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from "@tiptap/react";
import { useContext } from "react";
import { textualize } from "shared/BlockBuilder/utils";
import { ToastContext } from "shared/components/ToastProvider";
import ANALYTICS from "shared/constants/analytics";
import CopyIcon from "shared/images/icons/blocks/copy.svg";
import copyToClipboard from "shared/utils/copyToClipboard";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import { CopyMessaging, StyledIcon } from "../styles";

interface MessagingOptions {
  HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    messaging: {
      /**
       * Set messaging node
       */
      setMessaging: () => ReturnType;
      /**
       * Unset messaging node
       */
      unsetMessaging: () => ReturnType;
    };
  }
}

export const MessagingRenderer = ({ editor, node }: NodeViewProps) => {
  const { addToast } = useContext(ToastContext);

  const copyMessaging = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      await copyToClipboard(node.textContent);
      addToast(textualize("ui.textEditor.messaging.copy.success") as string);

      const category = editor.isEditable
        ? ANALYTICS.CATEGORIES.BRIEFING_FORM
        : ANALYTICS.CATEGORIES.FULL_BRIEF;
      fireAnalyticsEvent(category, ANALYTICS.EVENTS.MESSAGING_COPIED);
    } catch {
      addToast(textualize("ui.textEditor.messaging.copy.error") as string);
    }
  };

  return (
    <NodeViewWrapper as="span" className="messaging">
      <NodeViewContent as="span" />
      <CopyMessaging
        aria-label={textualize("ui.textEditor.messaging.copy.label") as string}
        contentEditable={false}
        onClick={copyMessaging}
        type="button"
      >
        <StyledIcon component={CopyIcon} />
      </CopyMessaging>
    </NodeViewWrapper>
  );
};

export default Node.create<MessagingOptions>({
  name: "messaging",

  addOptions() {
    return {
      HTMLAttributes: {
        class: this.name,
      },
    };
  },

  content: "inline*",
  group: "inline",
  isolating: true,
  inline: true,
  marks: "bold italic strike underline",

  addAttributes() {
    return {
      textContent: {
        default: "",
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(MessagingRenderer);
  },

  parseHTML() {
    return [
      {
        tag: `span[className="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setMessaging:
        () =>
        ({ chain, commands, state }) => {
          const { $anchor, $from, $to, empty, from, to } = state.selection;
          const selectedText = state.doc.textBetween(from, to, "");

          if (empty) {
            return false;
          }

          // Handle partial/mixed messaging node selections
          if (
            $anchor.parent.type !== this.type &&
            $from.parent.type !== this.type &&
            $to.parent.type !== this.type
          ) {
            fireAnalyticsEvent(
              ANALYTICS.CATEGORIES.BRIEFING_FORM,
              ANALYTICS.EVENTS.MESSAGING_HIGHLIGHTED,
            );

            return commands.insertContent(
              `<span className="messaging">${selectedText}</span>`,
            );
          }

          const plainText = state.doc.textBetween(from, to, "");
          return chain().insertContent(plainText).focus().run();
        },
      unsetMessaging:
        () =>
        ({ chain, state }) => {
          const { $anchor } = state.selection;
          const startPos = $anchor.pos - $anchor.parentOffset;
          const endPos = startPos + $anchor.parent.nodeSize - 1;
          const plainText = state.doc.textBetween(startPos, endPos, "");

          return chain()
            .selectParentNode()
            .insertContent(plainText)
            .focus()
            .run();
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ chain, commands, state }) => {
          let result = false;
          const { selection } = state;
          const { $anchor, anchor, empty, from, to } = selection;
          const parentSize = $anchor.parent.content.size;
          const selectingEntireNode = parentSize <= to - from;
          const atStart = $anchor.parentOffset === 1;
          if (
            (!empty && selectingEntireNode) ||
            (atStart && parentSize === 1)
          ) {
            // Remove entire node if all content is selected
            chain().selectParentNode().insertContent(" ").run();
          }

          const afterMessagingNode =
            $anchor.nodeBefore?.type === this.type &&
            $anchor.nodeAfter?.type !== this.type;
          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name && afterMessagingNode) {
              result = true;
              commands.setNodeSelection(pos);

              return false;
            }

            return;
          });

          return result;
        }),
      Enter: () =>
        this.editor.commands.command(({ chain, commands, state }) => {
          let result = false;
          const { selection } = state;
          const { $anchor, anchor, empty } = selection;

          state.doc.nodesBetween(anchor - 1, anchor, (node) => {
            if (node.type.name === this.name && $anchor.parentOffset > 0) {
              if (!empty) {
                return;
              }

              result = true;
              const afterMessagingNode =
                $anchor.nodeBefore?.type === this.type &&
                $anchor.nodeAfter?.type !== this.type;
              const atEnd =
                $anchor.parentOffset === $anchor.parent.content.size;
              const nextNodePos =
                $anchor.pos -
                $anchor.parentOffset +
                $anchor.parent.content.size;
              const nextNode = state.doc.nodeAt(nextNodePos + 1);
              if (
                (atEnd && !nextNode) ||
                (atEnd && $anchor.parent.type !== this.type)
              ) {
                // Exit messaging content if at the end
                commands.insertContent("<p/>");
              } else {
                // Split messaging content if in the middle
                chain().insertContent("<p/>").joinBackward().run();

                if (atEnd && !!nextNode) {
                  // Handle continous content
                  chain()
                    .selectParentNode()
                    .unsetMessaging()
                    .selectTextblockStart()
                    .joinBackward()
                    .run();
                } else if (!atEnd && !afterMessagingNode) {
                  chain().selectTextblockStart().joinBackward().focus().run();
                }
              }
              return false;
            }

            return;
          });

          return result;
        }),
      ArrowRight: () =>
        this.editor.commands.command(({ chain, state }) => {
          const { selection } = state;
          const { $anchor, anchor } = selection;
          state.doc.nodesBetween(anchor - 1, anchor, (node) => {
            if (
              node.type.name === this.name &&
              $anchor.parentOffset === $anchor.parent.textContent.length
            ) {
              // Allow exiting of node
              return chain().selectParentNode().focus().run();
            }

            return;
          });

          return false;
        }),
    };
  },
});
