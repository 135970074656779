import COLORS from "shared/constants/colors";
import FONTS from "shared/constants/fonts";
import styled, { css } from "styled-components";

export const buttonStyles = css`
  appearance: none;
  background-color: ${COLORS.RED};
  border: 1px solid ${COLORS.RED};
  border-radius: 1rem;
  color: ${COLORS.WHITE};
  font: inherit;
  font-size: 0.75rem;
  font-weight: ${FONTS.WEIGHT.BOLD};
  outline: none;
  overflow: hidden;
  padding: 0.3125rem 1rem 0.4375rem;
  text-decoration: none;
  text-transform: uppercase;
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    opacity 0.3s ease;
  user-select: none;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 2px ${COLORS.BLUE};
    cursor: pointer;
  }

  &:active {
    background-color: ${COLORS.WHITE};
    color: ${COLORS.RED};
  }

  &:disabled {
    border: 1px solid ${COLORS.LIGHT_GREY};
    background-color: ${COLORS.LIGHT_GREY};
    color: rgba(255, 255, 255, 0.25);
    &:hover {
      box-shadow: none;
      cursor: default;
    }
  }
`;

export const secondaryButtonStyles = css`
  ${buttonStyles}
  background: transparent;
  border-color: ${COLORS.WHITE};
  color: ${COLORS.WHITE};
  margin-right: 1rem;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 2px ${COLORS.BLUE};
  }
`;

export const minorButtonStyles = css`
  ${buttonStyles}
  appearance: none;
  background: transparent;
  border-color: ${COLORS.WHITE};
  color: ${COLORS.WHITE};
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 2px ${COLORS.BLUE};
  }
`;

export const linkButtonStyles = css`
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  color: ${COLORS.WHITE};
  cursor: pointer;
  font-family: ${FONTS.FAMILY.BODY};
  font-size: 0.875rem;
  font-weight: ${FONTS.WEIGHT.SEMI_BOLD};
  padding: 0;
  transition: all 0.1s;

  &:focus,
  &:hover {
    box-shadow: none;
    color: ${COLORS.RED};
    text-decoration: underline;
  }

  &:disabled {
    color: ${COLORS.LIGHTER_GREY};
    cursor: default;
  }
`;

export const StyledButton = styled.button`
  ${buttonStyles}
`;

export default StyledButton;
