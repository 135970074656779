import { ConfirmButton } from "creators/components/Brief/styles";
import { KeyboardEventHandler, useCallback, useState } from "react";
import { textualize } from "shared/BlockBuilder/utils";
import KEYBOARD_SHORTCUTS from "shared/constants/shortcuts/keyboardShortcuts";
import { FieldContainer, ImagePathInput, ImagePathLabel } from "./styles";

export interface IProps {
  id: string;
  onSubmit: (path: string) => void;
}

export const AddImageForm = ({ id, onSubmit }: IProps) => {
  const [path, setPath] = useState<string>("");

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.key === KEYBOARD_SHORTCUTS.ENTER) {
        e.preventDefault();

        if (path) {
          onSubmit(path);
        }
      }
    },
    [onSubmit, path],
  );

  const handleSubmit = () => {
    if (path) {
      onSubmit(path);
    }
  };

  return (
    <div role="form">
      <div onKeyDown={onKeyDown} role="none">
        <FieldContainer>
          <ImagePathLabel htmlFor={`${id}Input`}>
            {textualize("briefImage.path")}
          </ImagePathLabel>
          <ImagePathInput
            id={`${id}Input`}
            onChange={(e) => setPath(e.currentTarget.value)}
            value={path}
          />
        </FieldContainer>
        <ConfirmButton
          disabled={!path}
          id={`${id}Submit`}
          onClick={handleSubmit}
          type="button"
        >
          {textualize("briefImage.add")}
        </ConfirmButton>
      </div>
    </div>
  );
};

export default AddImageForm;
