import { ConfirmButton } from "creators/components/Brief/styles";
import { KeyboardEventHandler, useCallback, useState } from "react";
import { textualize } from "shared/BlockBuilder/utils";
import KEYBOARD_SHORTCUTS from "shared/constants/shortcuts/keyboardShortcuts";
import { IOption } from "shared/types/components/Dropdown";
import { ASPECT_RATIO_ORDER } from "shared/utils/sortAspectRatios/sortAspectRatios";
import {
  AspectRatioDropdown,
  FieldContainer,
  Label,
  VideoPathInput,
} from "./styles";

export interface IProps {
  id: string;
  onSubmit: (path: string, ratio: string) => void;
}

export const ASPECT_RATIO_OPTIONS: IOption[] = ASPECT_RATIO_ORDER.map(
  (aspectRatio) => ({
    label: aspectRatio,
    value: aspectRatio,
  }),
);

export const AddVideoForm = ({ id, onSubmit }: IProps) => {
  const [path, setPath] = useState<string>("");
  const [ratio, setRatio] = useState<string>();

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.key === KEYBOARD_SHORTCUTS.ENTER) {
        e.preventDefault();

        if (!!path && !!ratio) {
          onSubmit(path, ratio);
        }
      }
    },
    [onSubmit, path, ratio],
  );

  const handleSubmit = () => {
    if (!!path && !!ratio) {
      onSubmit(path, ratio);
    }
  };

  const setAspectRatio = useCallback((option: IOption) => {
    setRatio(option.value);
  }, []);

  return (
    <div role="form">
      <div onKeyDown={onKeyDown} role="none">
        <FieldContainer>
          <Label htmlFor={`${id}Input`}>{textualize("briefVideo.path")}</Label>
          <VideoPathInput
            id={`${id}Input`}
            onChange={(e) => setPath(e.currentTarget.value)}
            value={path}
          />
        </FieldContainer>

        <FieldContainer>
          <Label htmlFor={`${id}AspectRatio`}>
            {textualize("briefVideo.aspectRatio")}
          </Label>
          <AspectRatioDropdown
            inputId={`${id}AspectRatio`}
            onChange={setAspectRatio}
            options={ASPECT_RATIO_OPTIONS}
          />
        </FieldContainer>

        <ConfirmButton
          disabled={!path || !ratio}
          id={`${id}Submit`}
          onClick={handleSubmit}
          type="button"
        >
          {textualize("briefVideo.add")}
        </ConfirmButton>
      </div>
    </div>
  );
};

export default AddVideoForm;
