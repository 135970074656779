import { baseButtonStyles } from "creators/components/Button/Button";
import StandardModal from "creators/components/Modal/StandardModal";
import DIMENSIONS from "creators/constants/dimensions";
import { h3Styles } from "creators/styling/Heading/Heading";
import styled from "styled-components";

export const StyledStandardModal = styled(StandardModal)`
  gap: 0.5rem;
  min-width: 33vw;
  text-align: center;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    gap: 1rem;
  }
`;

export const Heading = styled.h2`
  ${h3Styles}
`;

export const Message = styled.div`
  margin-bottom: var(--v-spacing-4);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    margin-bottom: var(--v-spacing-8);
  }
`;

export const ButtonLink = styled.a`
  ${baseButtonStyles}
  display: inline-block;
  text-decoration: none;
`;
