import { LDSingleKindContext } from "launchdarkly-js-client-sdk";

const ANONYMOUS_LAUNCH_DARKLY_USER: LDSingleKindContext = {
  avatar:
    "https://public.live.vidsy.co/brands/logos/994a6a86-a3ed-4e7c-8044-724dfdce6383",
  key: "ANONYMOUS",
  kind: "user",
  name: "Anonymous User",
};

export default ANONYMOUS_LAUNCH_DARKLY_USER;
