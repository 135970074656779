import styled, { css } from "styled-components";
import { NavRailContentItemIcon } from "../NavRail/styles";

interface IProps {
  $active?: boolean;
}

const NavRailLink = styled.a.attrs<IProps>(({ $active }) => ({
  "aria-current": $active ? "page" : undefined,
}))<IProps>`
  display: block;
  margin: 0 var(--vui-spacing-0-5) var(--vui-spacing-3);
  min-height: var(--vui-spacing-14);
  text-decoration: none;

  &:focus-visible,
  &:hover {
    color: rgb(var(--vui-color-on-surface-tertiary));

    ${NavRailContentItemIcon} {
      &::before {
        background-color: rgb(var(--vui-color-surface-tertiary));
        opacity: 1;
      }
    }
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }

  ${({ $active }) =>
    $active &&
    css`
      ${NavRailContentItemIcon} {
        color: rgb(var(--vui-color-inverse-on-surface));

        &::before {
          background-color: rgb(var(--vui-color-inverse-surface));
          opacity: 1;
        }
      }

      &:focus-visible,
      &:hover {
        ${NavRailContentItemIcon} {
          color: rgb(var(--vui-color-active-on-surface));

          &::before {
            background-color: rgb(var(--vui-color-active-surface));
          }
        }
      }
    `}
`;

export default NavRailLink;
