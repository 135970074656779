import Dropdown from "shared/components/Dropdowns/Dropdown";
import Input from "shared/components/Input";
import styled from "styled-components";

export const AspectRatioDropdown = styled(Dropdown)`
  & .dropdown__control {
    min-height: 2.25rem;

    &,
    &.dropdown__control--menu-is-focused,
    &.dropdown__control--menu-is-open {
      border: 1px solid var(--v-color-black);
      color: var(--v-color-black);
    }
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: var(--v-spacing-2);
`;

export const VideoPathInput = styled(Input)`
  border: 1px solid var(--v-color-black);
  display: block;
  width: 100%;
`;

export const FieldContainer = styled.div`
  margin-bottom: var(--v-spacing-4);
`;
