export const offsetSubmissionDate = (submissionDate?: Date) => {
  if (!submissionDate) {
    return undefined;
  }

  const offset = new Date(submissionDate);
  offset.setHours(offset.getHours() - 1);

  return offset;
};

export default offsetSubmissionDate;
