import { JSONContent } from "@tiptap/core";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import Document from "@tiptap/extension-document";
import History from "@tiptap/extension-history";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Strike from "@tiptap/extension-strike";
import Text from "@tiptap/extension-text";
import Underline from "@tiptap/extension-underline";
import { BubbleMenu, EditorContent, useEditor } from "@tiptap/react";
import { useCallback, useState } from "react";
import { textualize } from "shared/BlockBuilder/utils";
import Button from "shared/components/Button";
import MARKDOWN from "shared/constants/markdown";
import KEYBOARD_SHORTCUTS from "shared/constants/shortcuts/keyboardShortcuts";
import BoldIcon from "shared/images/icons/bold.svg";
import ExternalLinkIcon from "shared/images/icons/external-link.svg";
import ItalicIcon from "shared/images/icons/italic.svg";
import LinkIcon from "shared/images/icons/link.svg";
import MessagingIcon from "shared/images/icons/messaging.svg";
import OrderedListIcon from "shared/images/icons/ordered-list.svg";
import StrikethroughIcon from "shared/images/icons/strikethrough.svg";
import UnderlineIcon from "shared/images/icons/underline.svg";
import UnorderedListIcon from "shared/images/icons/unordered-list.svg";
import { Messaging } from "./extensions";
import {
  BubblePanel,
  Container,
  InnerRow,
  InputError,
  LinkButton,
  LinkInput,
  LinkPanel,
  LinkRow,
  PanelButton,
  StyledIcon,
  TextLink,
} from "./styles";

export interface IProps {
  editable: boolean;
  enabledMarkdown?: string[];
  initialValue?: JSONContent | string;
  onBlur?: () => void;
  onChange: (value: string) => void;
  onFocus?: () => void;
  role?: string;
}

export const ENABLED_MARKDOWN = [
  MARKDOWN.BOLD,
  MARKDOWN.HEADING,
  MARKDOWN.IMAGE,
  MARKDOWN.ITALIC,
  MARKDOWN.LINK,
  MARKDOWN.LIST_ITEM,
  MARKDOWN.LIST_ITEM_CHILD,
  MARKDOWN.ORDERED_LIST,
  MARKDOWN.PARAGRAPH,
  MARKDOWN.STRIKETHROUGH,
  MARKDOWN.UNDERLINE,
  MARKDOWN.UNORDERED_LIST,
  MARKDOWN.VIDEO,
];

export const extensions = [
  Document,
  Bold,
  BulletList,
  History,
  Italic,
  Link.configure({
    openOnClick: false,
  }),
  ListItem,
  Messaging,
  OrderedList,
  Paragraph.extend({
    marks: "bold link",
  }),
  Strike,
  Text,
  Underline,
];

export const validateURL = (url: string) => {
  let error = "";
  try {
    const formattedURL = new URL(url);

    if (formattedURL.protocol !== "https:") {
      error = `${textualize("ui.textEditor.linkInput.invalidLink.protocol")}`;
    }
  } catch {
    error = `${textualize("ui.textEditor.linkInput.invalidLink.generic")}`;
  }

  return error;
};

const TextEditor = ({
  enabledMarkdown = ENABLED_MARKDOWN,
  editable,
  initialValue,
  onBlur,
  onChange,
  onFocus,
  ...rest
}: IProps) => {
  const editor = useEditor({
    content: initialValue,
    editable,
    extensions,
    injectCSS: false,
    onBlur() {
      onBlur?.();
    },
    onFocus() {
      onFocus?.();
    },
    onUpdate({ editor }) {
      onChange(JSON.stringify(editor.getJSON()));
    },
  });

  const [currentLink, setCurrentLink] = useState("");
  const [linkError, setLinkError] = useState("");
  const [linkOpen, setLinkOpen] = useState(false);

  const cleanupMenu = useCallback(() => {
    setCurrentLink("");
    setLinkError("");
    setLinkOpen(false);
  }, []);

  if (!editor) {
    return null;
  }

  const applyLink = () => {
    if (currentLink && !linkError) {
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({
          href: currentLink,
          target: "_blank",
        })
        .run();
    } else {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
      setLinkError("");
    }
    setLinkOpen(false);
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const error = validateURL(e.currentTarget.value);
    if (error) {
      setLinkError(error);
    } else {
      setLinkError("");
    }
    setCurrentLink(e.currentTarget.value);
  };

  const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === KEYBOARD_SHORTCUTS.ENTER) {
      e.preventDefault();
      e.stopPropagation();

      if (currentLink && !linkError) {
        editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .setLink({
            href: currentLink,
            target: "_blank",
          })
          .run();
      } else {
        editor.chain().focus().extendMarkRange("link").unsetLink().run();
        setLinkError("");
      }
      setLinkOpen(false);
    }
  };

  const handleLinkClick = () => {
    if (linkOpen) {
      setLinkOpen(false);
    } else {
      const existingLink = editor.getAttributes("link").href || "";
      if (existingLink === "" || existingLink !== currentLink) {
        setCurrentLink(existingLink);
      }
      setLinkOpen(true);
    }
  };

  const removeLink = () => {
    if (currentLink && !linkError) {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
    }
    setLinkOpen(false);
  };

  return (
    <Container {...rest}>
      {editor && editable && (
        <>
          {/* Default menu to display */}
          <BubbleMenu
            editor={editor}
            shouldShow={({ editor, state }) =>
              !state.selection.empty && !editor.isActive("messaging")
            }
            tippyOptions={{
              onHidden: cleanupMenu,
            }}
          >
            <BubblePanel>
              {/* TODO: Panel icons don't scale well for various reasons, check with product */}

              <PanelButton
                isActive={editor.isActive("bold")}
                isVisible={enabledMarkdown.includes(MARKDOWN.BOLD)}
                onClick={() => editor.chain().focus().toggleBold().run()}
                title={textualize("ui.textEditor.bold") as string}
                type="button"
              >
                <StyledIcon
                  component={BoldIcon}
                  label={textualize("ui.textEditor.bold")}
                />
              </PanelButton>

              <PanelButton
                isActive={editor.isActive("bulletList")}
                isVisible={enabledMarkdown.includes(MARKDOWN.UNORDERED_LIST)}
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                title={textualize("ui.textEditor.bulletList") as string}
                type="button"
              >
                <StyledIcon
                  component={UnorderedListIcon}
                  label={textualize("ui.textEditor.bulletList")}
                />
              </PanelButton>

              <PanelButton
                isActive={editor.isActive("orderedList")}
                isVisible={enabledMarkdown.includes(MARKDOWN.ORDERED_LIST)}
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                title={textualize("ui.textEditor.orderedList") as string}
                type="button"
              >
                <StyledIcon
                  component={OrderedListIcon}
                  label={textualize("ui.textEditor.orderedList")}
                />
              </PanelButton>

              <PanelButton
                isActive={editor.isActive("link")}
                isVisible={enabledMarkdown.includes(MARKDOWN.LINK)}
                onClick={handleLinkClick}
                title={textualize("ui.textEditor.link") as string}
                type="button"
              >
                <StyledIcon
                  component={LinkIcon}
                  label={textualize("ui.textEditor.link")}
                />
              </PanelButton>
              {linkOpen && (
                <LinkPanel>
                  <LinkRow hasError={!!linkError}>
                    <LinkInput
                      hasError={!!linkError}
                      onChange={changeHandler}
                      onKeyDown={keyDownHandler}
                      placeholder={
                        textualize(
                          "ui.textEditor.linkInput.placeholder",
                        ) as string
                      }
                      type="text"
                      value={currentLink}
                    />
                    <LinkButton
                      disabled={!currentLink || !!linkError}
                      onClick={() => window.open(currentLink)}
                      title={
                        textualize("ui.textEditor.linkInput.open") as string
                      }
                      type="button"
                    >
                      <StyledIcon
                        component={ExternalLinkIcon}
                        label={textualize("ui.textEditor.linkInput.open")}
                      />
                    </LinkButton>
                  </LinkRow>
                  {!!linkError && <InputError>{linkError}</InputError>}
                  <InnerRow>
                    <TextLink
                      disabled={!currentLink || !!linkError}
                      onClick={removeLink}
                      title={textualize("ui.textEditor.removeLink") as string}
                      type="button"
                    >
                      {textualize("ui.textEditor.remove")}
                    </TextLink>
                    <Button
                      disabled={!currentLink || !!linkError}
                      onClick={applyLink}
                      title={textualize("ui.textEditor.applyLink") as string}
                      type="button"
                    >
                      {textualize("ui.textEditor.apply")}
                    </Button>
                  </InnerRow>
                </LinkPanel>
              )}
              <PanelButton
                isActive={editor.isActive("messaging")}
                isVisible={enabledMarkdown.includes(MARKDOWN.MESSAGING)}
                onClick={() =>
                  editor.isActive("messaging")
                    ? editor.commands.unsetMessaging()
                    : editor.commands.setMessaging()
                }
                title={
                  editor.isActive("messaging")
                    ? (textualize("ui.textEditor.messaging.remove") as string)
                    : (textualize("ui.textEditor.messaging.apply") as string)
                }
                type="button"
              >
                <StyledIcon
                  component={MessagingIcon}
                  label={textualize("ui.textEditor.messaging.highlight")}
                />
              </PanelButton>
            </BubblePanel>
          </BubbleMenu>

          {/* Alternative menu to display for messaging highlight */}
          <BubbleMenu
            editor={editor}
            shouldShow={({ editor, state }) =>
              !state.selection.empty && editor.isActive("messaging")
            }
            tippyOptions={{
              onHidden: cleanupMenu,
            }}
          >
            <BubblePanel>
              {
                <PanelButton
                  isActive={editor.isActive("messaging")}
                  isVisible={enabledMarkdown.includes(MARKDOWN.MESSAGING)}
                  onClick={() =>
                    editor.isActive("messaging")
                      ? editor.commands.unsetMessaging()
                      : editor.commands.setMessaging()
                  }
                  title={
                    editor.isActive("messaging")
                      ? (textualize("ui.textEditor.messaging.remove") as string)
                      : (textualize("ui.textEditor.messaging.apply") as string)
                  }
                  type="button"
                >
                  <StyledIcon
                    component={MessagingIcon}
                    label={textualize("ui.textEditor.messaging.highlight")}
                  />
                </PanelButton>
              }
              <PanelButton
                isActive={editor.isActive("bold")}
                isVisible={enabledMarkdown.includes(MARKDOWN.BOLD)}
                onClick={() => editor.chain().focus().toggleBold().run()}
                title={textualize("ui.textEditor.bold") as string}
                type="button"
              >
                <StyledIcon
                  component={BoldIcon}
                  label={textualize("ui.textEditor.bold")}
                />
              </PanelButton>
              <PanelButton
                isActive={editor.isActive("italic")}
                isVisible={enabledMarkdown.includes(MARKDOWN.ITALIC)}
                onClick={() => editor.chain().focus().toggleItalic().run()}
                title={textualize("ui.textEditor.italic") as string}
                type="button"
              >
                <StyledIcon
                  component={ItalicIcon}
                  label={textualize("ui.textEditor.italic")}
                />
              </PanelButton>
              <PanelButton
                isActive={editor.isActive("underline")}
                isVisible={enabledMarkdown.includes(MARKDOWN.UNDERLINE)}
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                title={textualize("ui.textEditor.underline") as string}
                type="button"
              >
                <StyledIcon
                  component={UnderlineIcon}
                  label={textualize("ui.textEditor.underline")}
                />
              </PanelButton>
              <PanelButton
                isActive={editor.isActive("strike")}
                isVisible={enabledMarkdown.includes(MARKDOWN.STRIKETHROUGH)}
                onClick={() => editor.chain().focus().toggleStrike().run()}
                title={textualize("ui.textEditor.strikethrough") as string}
                type="button"
              >
                <StyledIcon
                  component={StrikethroughIcon}
                  label={textualize("ui.textEditor.strikethrough")}
                />
              </PanelButton>
            </BubblePanel>
          </BubbleMenu>
        </>
      )}
      <EditorContent editor={editor} />
    </Container>
  );
};

export default TextEditor;
