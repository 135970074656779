import { Block, BLOCK_TYPE } from "shared/BlockBuilder/global";
import { default as sharedTextualize } from "shared/services/textualize";
import copy from "./copy";

export interface ICollatedSection {
  section: Block;
  children: Block[] | undefined;
}

export function collateSections(blocks: Block[]): ICollatedSection[] {
  const sections = blocks.filter((block) => block.type === BLOCK_TYPE.Section);

  const collated = sections.map((section) => {
    const children: Block[] = [];
    section.content?.forEach((id) => {
      const child = blocks.find((block) => block.id === id);
      if (child) {
        children.push(child);
      }
    });

    return {
      children: children.length ? children : undefined,
      section,
    };
  });

  return collated;
}

export const textualize = sharedTextualize(copy);
