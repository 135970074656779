import { gql } from "@apollo/client";

export const CurrencyTaxonFileds = gql`
  fragment CurrencyTaxonFileds on Taxon {
    id
    label
    symbolCodePoints
  }
`;

export const CurrencyTaxonomyFields = gql`
  fragment CurrencyTaxonomyFields on Taxonomy {
    id
    values {
      ...CurrencyTaxonFileds
    }
  }
  ${CurrencyTaxonFileds}
`;

export const CreatorTaxonomiesFields = gql`
  fragment CreatorTaxonomiesFields on CreatorTaxonomies {
    currency {
      ...CurrencyTaxonomyFields
    }
  }
  ${CurrencyTaxonomyFields}
`;

export const GET_CREATOR_TAXONOMIES = gql`
  query GetCreatorTaxonomies {
    taxonomies {
      ...CreatorTaxonomiesFields
    }
  }
  ${CreatorTaxonomiesFields}
`;

export default {
  GET_CREATOR_TAXONOMIES,
};
