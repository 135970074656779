import {
  hideCaptionsAction,
  muteVideoAction,
  pauseVideoAction,
  playVideoAction,
  showCaptionsAction,
  unmuteVideoAction,
} from "creators/actions/video";
import { VideoControlsContext } from "creators/providers/VideoControlsProvider";
import { useCallback, useContext } from "react";

const useVideoControls = () => {
  const { dispatch, videoControlsState } = useContext(VideoControlsContext);

  const onHideCaptions = useCallback(() => {
    dispatch(hideCaptionsAction());
  }, [dispatch]);

  const onMute = useCallback(() => {
    dispatch(muteVideoAction());
  }, [dispatch]);

  const onPause = useCallback(() => {
    dispatch(pauseVideoAction());
  }, [dispatch]);

  const onPlay = useCallback(() => {
    dispatch(playVideoAction());
  }, [dispatch]);

  const onShowCaptions = useCallback(() => {
    dispatch(showCaptionsAction());
  }, [dispatch]);

  const onUnmute = useCallback(() => {
    dispatch(unmuteVideoAction());
  }, [dispatch]);

  return {
    muted: videoControlsState.muted,
    onHideCaptions,
    onMute,
    onPause,
    onPlay,
    onShowCaptions,
    onUnmute,
    playing: videoControlsState.playing,
    showCaptions: videoControlsState.showCaptions,
  };
};

export default useVideoControls;
