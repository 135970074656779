import FormInput, {
  IProps as IFormInput,
} from "creators/components/forms/FormInput/FormInput";
import { useField } from "formik";
import normalizeUrl from "creators/utils/normalizeUrl";
import { useCallback } from "react";

interface IProps {
  className?: string;
  inputProps?: Omit<IFormInput, "onChange" | "value">;
  name: string;
}

function FormikURLInput({ className, inputProps, ...props }: IProps) {
  const [{ onBlur, ...field }, meta, { setValue }] = useField<string>(props);

  const normalizeAndBlurUrl = useCallback(
    (e: React.FocusEvent) => {
      const url = normalizeUrl(e);

      setValue(url);
      onBlur(e);
    },
    [onBlur, setValue],
  );

  let error;

  if (meta.touched && meta.error) {
    error = meta.error;
  }

  return (
    <FormInput
      {...field}
      onBlur={normalizeAndBlurUrl}
      {...inputProps}
      className={className}
      error={error}
    />
  );
}

export default FormikURLInput;
