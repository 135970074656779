import { css } from "styled-components";

const spacing = css`
  --v-spacing-0-5: 0.125rem; /* 2px */
  --v-spacing-1: 0.25rem; /* 4px */
  --v-spacing-2: 0.5rem; /* 8px */
  --v-spacing-3: 0.75rem; /* 12px */
  --v-spacing-4: 1rem; /* 16px */
  --v-spacing-5: 1.25rem; /* 20px */
  --v-spacing-6: 1.5rem; /* 24px */
  --v-spacing-7: 1.75rem; /* 28px */
  --v-spacing-8: 2rem; /* 32px */
  --v-spacing-10: 2.5rem; /* 40px */
  --v-spacing-12: 3rem; /* 48px */
  --v-spacing-16: 4rem; /* 64px */
  --v-spacing-24: 6rem; /* 96px */
`;

export default spacing;
