export interface IVideo {
  CAPTIONS?: string;
  SOURCE: string;
}

export interface IVideos {
  [name: string]: IVideo;
}

export const VIDEOS: IVideos = {
  CURRENCY: {
    CAPTIONS: "https://client-assets.vidsy.co/onboarding-videos/currency.vtt",
    SOURCE: "https://client-assets.vidsy.co/onboarding-videos/currency.mp4",
  },
  EMAIL_VERIFIED: {
    CAPTIONS:
      "https://client-assets.vidsy.co/onboarding-videos/get-started.vtt",
    SOURCE: "https://client-assets.vidsy.co/onboarding-videos/get-started.mp4",
  },
  LOCATION: {
    CAPTIONS: "https://client-assets.vidsy.co/onboarding-videos/location.vtt",
    SOURCE: "https://client-assets.vidsy.co/onboarding-videos/location.mp4",
  },
  PAYEE_NAME: {
    CAPTIONS:
      "https://client-assets.vidsy.co/onboarding-videos/payment-name.vtt",
    SOURCE: "https://client-assets.vidsy.co/onboarding-videos/payment-name.mp4",
  },
  PORTFOLIO: {
    CAPTIONS: "https://client-assets.vidsy.co/onboarding-videos/showreel.vtt",
    SOURCE: "https://client-assets.vidsy.co/onboarding-videos/showreel.mp4",
  },
  SKILLS: {
    CAPTIONS:
      "https://client-assets.vidsy.co/onboarding-videos/brief-types-remix-cgc.vtt",
    SOURCE:
      "https://client-assets.vidsy.co/onboarding-videos/brief-types-remix-cgc.mp4",
  },
};

export default VIDEOS;
