import get from "lodash.get";

export const getPaginatedData = (data: any, pathToPagination?: string[]) => {
  if (!pathToPagination) {
    // Default to first key in object
    return data[Object.keys(data)[0]];
  }

  return get(data, pathToPagination, {});
};

export default getPaginatedData;
