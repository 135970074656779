import {
  disabledStyles,
  errorStyles,
  focusedStyles,
  inputStyles,
} from "creators/components/TextInput/TextInput";
import { css } from "styled-components";

export const controlStyling = css<{ hasError?: boolean }>`
  & .dropdown__control {
    ${inputStyles};
    ${({ hasError }) => hasError && errorStyles};
    cursor: pointer;
    overflow: hidden;
  }

  & .dropdown__control:hover,
  & .dropdown__control--is-focused {
    ${focusedStyles};
    border: 0.125rem solid
      ${({ hasError }) =>
        hasError ? "var(--v-color-red)" : "var(--v-color-black)"};
  }

  & .dropdown__control.dropdown__control--is-disabled {
    ${disabledStyles};

    & .dropdown__dropdown-indicator {
      background-color: var(--v-color-grey-light);
    }
  }
`;

export const dropdownStyling = css`
  cursor: pointer;

  & .dropdown__menu {
    box-shadow: 0 0.25rem 1.5rem rgba(var(--v-color-black-rgb), 0.84);
    border-radius: 0.5rem;
  }
`;

export const indicatorStyling = css`
  & .dropdown__dropdown-indicator {
    background: inherit;
    color: inherit;
    height: 1rem;
    padding: var(--v-spacing-1);
    width: 1rem;

    &:hover {
      color: inherit;
    }
  }

  & .dropdown__indicator-separator {
    display: none;
  }
`;

export const inputStyling = css`
  & .dropdown__input input {
    font: inherit;
    line-height: 1;
  }

  & .dropdown__placeholder {
    color: rgba(var(--v-color-black-rgb), 0.4);
  }

  & .dropdown__value-container {
    padding: 0;

    div:last-child {
      /* Input container */
      color: var(--v-color-black);
      padding: 0;
    }
  }
`;

export const optionStyling = css`
  & .dropdown__option {
    background-color: inherit;
    color: var(--v-color-black);
    cursor: pointer;
    padding: var(--v-spacing-1) var(--v-spacing-2);
  }

  & .dropdown__option--is-disabled {
    color: var(--v-color-grey);
    cursor: default;
  }

  & .dropdown__option--is-focused {
    background-color: var(--v-color-grey-light);
  }
`;

export const singleValueStyling = css`
  & .dropdown__single-value {
    line-height: 1.5;
  }

  & .dropdown__single-value--is-disabled {
    color: var(--v-color-grey-light);
  }
`;

export default {
  controlStyling,
  dropdownStyling,
  indicatorStyling,
  inputStyling,
  optionStyling,
  singleValueStyling,
};
