import DIMENSIONS, { PX } from "creators/constants/dimensions/dimensions";
import { createContext, ReactNode, useEffect, useState } from "react";

interface IProps {
  children?: ReactNode;
}

export enum ScreenSize {
  Mobile,
  Tablet,
  Desktop,
}

export const ViewportContext = createContext<ScreenSize>(ScreenSize.Mobile);

const getInitialSize = () => {
  if (window.innerWidth < PX.BREAKPOINT_MD) {
    return ScreenSize.Mobile;
  } else if (window.innerWidth < PX.BREAKPOINT_LG) {
    return ScreenSize.Tablet;
  }
  return ScreenSize.Desktop;
};

export const ViewportProvider = ({ children }: IProps) => {
  const [size, setSize] = useState<ScreenSize>(getInitialSize());

  useEffect(() => {
    const mobile = window.matchMedia(
      `(max-width: calc(${DIMENSIONS.BREAKPOINT_MD} - 0.0625rem))`,
    );
    const tablet = window.matchMedia(
      `(max-width: calc(${DIMENSIONS.BREAKPOINT_LG} - 0.0625rem))`,
    );

    const updateSize = () => {
      if (mobile.matches) {
        setSize(ScreenSize.Mobile);
      } else if (tablet.matches) {
        setSize(ScreenSize.Tablet);
      } else {
        setSize(ScreenSize.Desktop);
      }
    };

    if (mobile.addEventListener) {
      mobile.addEventListener("change", updateSize);
      tablet.addEventListener("change", updateSize);
    } else {
      // Support older versions of the spec
      mobile.addListener(updateSize);
      tablet.addListener(updateSize);
    }

    updateSize();

    return () => {
      if (mobile.removeEventListener) {
        mobile.removeEventListener("change", updateSize);
        tablet.removeEventListener("change", updateSize);
      } else {
        mobile.removeListener(updateSize);
        tablet.removeListener(updateSize);
      }
    };
  }, []);

  return (
    <ViewportContext.Provider value={size}>{children}</ViewportContext.Provider>
  );
};

export default ViewportProvider;
