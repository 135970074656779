import smallTextStyles from "creators/styling/SmallText";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";

export enum Color {
  Blue = "blue",
  Green = "green",
  Red = "red",
}

export interface IProps extends Omit<HTMLAttributes<HTMLSpanElement>, "color"> {
  className?: string;
  color: Color;
}

export const blueStyles = css`
  background: var(--v-color-tags-blue-bg);
  color: var(--v-color-tags-blue-text);
`;

export const greenStyles = css`
  background: var(--v-color-tags-green-bg);
  color: var(--v-color-tags-green-text);
`;

export const redStyles = css`
  background: var(--v-color-tags-red-bg);
  color: var(--v-color-tags-red-text);
`;

export const getColorStyles = (color: Color) => {
  switch (color) {
    case Color.Blue:
    default:
      return blueStyles;
    case Color.Green:
      return greenStyles;
    case Color.Red:
      return redStyles;
  }
};

export const Tag = styled.span<IProps>`
  ${smallTextStyles};
  border-radius: 1rem;
  padding: var(--v-spacing-1) var(--v-spacing-4);
  ${({ color }) => getColorStyles(color)};
`;

export default Tag;
