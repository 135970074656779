import videoControlsReducer from "creators/reducers/video";
import { IVideoControlsState } from "creators/types/video";
import { ReactNode, useReducer, createContext } from "react";
import { IAction } from "shared/types/actions";

interface IProps {
  children: ReactNode;
}

interface IContext {
  dispatch: React.Dispatch<IAction>;
  videoControlsState: IVideoControlsState;
}

export const INITIAL_VIDEO_CONTROLS_STATE = {
  muted: true,
  playing: true,
  showCaptions: true,
};

export const VideoControlsContext = createContext<IContext>({
  dispatch: () => null,
  videoControlsState: INITIAL_VIDEO_CONTROLS_STATE,
});

function VideoControlsContextProvider({ children }: IProps) {
  const [videoControlsState, dispatch] = useReducer(
    videoControlsReducer,
    INITIAL_VIDEO_CONTROLS_STATE,
  );

  return (
    <VideoControlsContext.Provider value={{ dispatch, videoControlsState }}>
      {children}
    </VideoControlsContext.Provider>
  );
}

export default VideoControlsContextProvider;
