interface TrackConfig {
  category?: string;
  label?: string;
}

export interface ITraits {
  [key: string]: unknown;
}

class Analytics {
  // Segment will associate any anonymous actions with that user once they log in, but Mixpanel still needs to be told manually.
  // https://segment.com/docs/connections/destinations/catalog/mixpanel/#alias
  static alias(id: string) {
    if (this.isEnabled()) {
      return window.analytics.alias(id, undefined, {
        integrations: {
          All: false,
          Mixpanel: true,
        },
      });
    }
  }

  static isEnabled(): boolean {
    // eslint-disable-next-line no-prototype-builtins
    if (window.hasOwnProperty("analytics")) {
      return true;
    }

    return false;
  }

  static hasLoaded(): boolean {
    if (this.isEnabled() && window.analytics.initialized) {
      return true;
    }

    return false;
  }

  static virtualPageView(): void {
    if (this.isEnabled()) {
      return window.analytics.page();
    }
  }

  static fireEvent(action: string, config?: TrackConfig) {
    if (this.isEnabled()) {
      return window.analytics.track(action, config);
    }
  }

  static identify<T = ITraits>(id: string, name: string, traits?: T) {
    if (this.isEnabled()) {
      return window.analytics.identify(id, {
        name,
        ...traits,
      });
    }
  }
}

export default Analytics;
