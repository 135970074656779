import { useEffect, useState } from "react";

// Adapted react-reduce-motion hook to work with Safari 13
// https://github.com/infiniteluke/react-reduce-motion
export function useReduceMotion() {
  const [matches, setMatch] = useState(
    window.matchMedia("(prefers-reduced-motion: reduce)").matches,
  );
  useEffect(() => {
    const mq = window.matchMedia("(prefers-reduced-motion: reduce)");
    const handleChange = () => {
      setMatch(mq.matches);
    };
    handleChange();

    if (mq.addEventListener) {
      mq.addEventListener("change", handleChange);
    } else {
      mq.addListener(handleChange);
    }
    return () => {
      if (mq.removeEventListener) {
        mq.removeEventListener("change", handleChange);
      } else {
        mq.removeListener(handleChange);
      }
    };
  }, []);
  return matches;
}

export default useReduceMotion;
