import { SecondaryButton } from "creators/components/Button";
import DIMENSIONS from "creators/constants/dimensions";
import FONTS from "creators/constants/fonts";
import QuestionMarkIcon from "creators/images/icons/tooltip-question-mark.svg";
import textualize from "creators/utils/textualize";
import {
  HTMLAttributes,
  MutableRefObject,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from "react";
import Icon from "shared/components/Icon";
import styled, { css } from "styled-components";

// Position of the tooltip relative to its sibling
export enum Position {
  Bottom = "BOTTOM",
  BottomLeft = "BOTTOM_LEFT",
  BottomRight = "BOTTOM_RIGHT",
  Left = "LEFT",
  Right = "RIGHT",
  Top = "TOP",
  TopLeft = "TOP_LEFT",
  TopRight = "TOP_RIGHT",
}

const bottomArrowStyles = css`
  border-top-color: var(--v-color-white);
  border-width: 0.75rem 0.5rem 0 0.5rem;
  bottom: -0.75rem;
`;

const leftArrowStyles = css`
  border-right-color: var(--v-color-white);
  border-width: 0.5rem 0.75rem 0.5rem 0;
  left: -0.75rem;
`;

const rightArrowStyles = css`
  border-left-color: var(--v-color-white);
  border-width: 0.5rem 0 0.5rem 0.75rem;
  right: -0.75rem;
`;

const topArrowStyles = css`
  border-top-color: var(--v-color-white);
  border-width: 0.75rem 0.5rem 0 0.5rem;
  top: -0.75rem;
`;

const arrowStyles = {
  [Position.Bottom]: css`
    ${topArrowStyles}
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
  `,
  [Position.BottomLeft]: css`
    ${topArrowStyles}
    right: 0;
    transform: translateX(-1rem) rotate(180deg);
  `,
  [Position.BottomRight]: css`
    ${topArrowStyles}
    left: 0;
    transform: translateX(1rem) rotate(180deg);
  `,
  [Position.Left]: css`
    ${rightArrowStyles}
    top: 50%;
    transform: translateY(-0.5rem);
  `,
  [Position.Right]: css`
    ${leftArrowStyles}
    top: 50%;
    transform: translateY(-0.5rem);
  `,
  [Position.Top]: css`
    ${bottomArrowStyles}
    left: 50%;
    transform: translateX(-50%);
  `,
  [Position.TopLeft]: css`
    ${bottomArrowStyles}
    right: 0;
    transform: translateX(-1.5rem);
  `,
  [Position.TopRight]: css`
    ${bottomArrowStyles}
    left: 0;
    transform: translateX(1.5rem);
  `,
};

const tooltipStyles = {
  [Position.Bottom]: css`
    left: 1rem;
    top: 0;
    transform: translate(-50%, 20%);
  `,
  [Position.BottomLeft]: css`
    left: 0.5rem;
    top: 0;
    transform: translate(-90%, 20%);
  `,
  [Position.BottomRight]: css`
    left: -0.5rem;
    top: 0;
    transform: translateY(20%);
  `,
  [Position.Left]: css`
    left: -0.5rem;
    transform: translate(-100%, -60%);
  `,
  [Position.Right]: css`
    left: 2rem;
    transform: translateY(-60%);
  `,
  [Position.Top]: css`
    bottom: 2.25rem;
    left: 1rem;
    transform: translateX(-50%);
  `,
  [Position.TopLeft]: css`
    bottom: 2.25rem;
    left: 1rem;
    transform: translateX(-90%);
  `,
  [Position.TopRight]: css`
    bottom: 2.25rem;
    left: -1rem;
  `,
};

const Close = styled(SecondaryButton)`
  display: block;
  margin-top: var(--v-spacing-4);
  pointer-events: all;
`;

const Container = styled.div<{ position: Position; showModalTip?: boolean }>`
  display: block;
  position: relative;

  > div {
    &:first-child {
      display: ${(props) => (props.showModalTip ? "none" : "inline-block")};

      @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
        display: block;
      }

      &:focus {
        outline: none;
      }

      &:focus,
      &:hover {
        cursor: pointer;

        @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
          cursor: default;
        }
      }
    }

    &:last-child {
      align-items: center;
      cursor: default;
      display: ${(props) => (props.showModalTip ? "flex" : "none")};
      justify-content: center;
      left: 50%;
      opacity: ${(props) => (props.showModalTip ? "1" : "0")};
      pointer-events: ${(props) => props.showModalTip && "auto"};
      position: absolute;
      top: 50%;
      transform: translate(-50%, 50%);

      @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
        align-items: normal;
        display: block;
        justify-content: normal;
        left: unset;
        opacity: 0;
        pointer-events: none;
        top: unset;
        transform: none;
        ${(props) => tooltipStyles[props.position]}
      }

      > div {
        background: var(--v-color-white);
        border-radius: 0.5rem;
        box-shadow: 0 0.25rem 1.5rem #00000029;
        font-size: 1.0625rem;
        height: fit-content;
        max-width: 22rem;
        padding: var(--v-spacing-4);
        transition: opacity 300ms;

        @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
          height: auto;
        }

        &::before {
          @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
            border-color: transparent;
            border-style: solid;
            content: "";
            height: 0;
            opacity: 1;
            position: absolute;
            width: 0;
            ${(props) => arrowStyles[props.position]}
          }
        }

        > ${Close} {
          width: 100%;

          @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
            display: none;
          }
        }
      }
    }
  }

  &:hover > :last-child {
    opacity: unset;
    pointer-events: auto;

    @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
      opacity: 1;
    }
  }
`;

const StyledIcon = styled(Icon)`
  color: var(--v-color-grey);
  height: 1.75rem;
  width: 1.75rem;
`;

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  label?: ReactNode | string;
  position?: Position;
  tip: ReactNode | string;
}

const Tooltip = ({
  children,
  className,
  id,
  label = "Tooltip trigger",
  position = Position.Bottom,
  tip,
}: IProps) => {
  const [showModalTip, setShowModalTip] = useState(false);

  const openModalTip = useCallback(() => {
    if (!showModalTip) {
      setShowModalTip(true);
    }
  }, [showModalTip]);

  const closeModalTip = useCallback(() => {
    if (showModalTip) {
      setShowModalTip(false);
    }
  }, [showModalTip]);

  return (
    <Container
      className={className}
      position={position}
      showModalTip={showModalTip}
    >
      <div
        aria-label={`${label}`}
        onClick={openModalTip}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            openModalTip();
          }
        }}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
      <div>
        <div id={id} role="tooltip">
          <span>{tip}</span>
          {showModalTip && (
            <Close onClick={closeModalTip}>{textualize("common.close")}</Close>
          )}
        </div>
      </div>
    </Container>
  );
};

export const Toggletip = ({ className, id, label, position, tip }: IProps) => (
  <Tooltip
    className={className}
    id={id}
    label={label}
    position={position}
    tip={tip}
  >
    <StyledIcon component={QuestionMarkIcon} />
  </Tooltip>
);

export default Tooltip;

// -------

export interface TooltipProps extends HTMLAttributes<HTMLSpanElement> {
  children: ReactNode;
  id: string;
  label: string;
  tip: string;
  position: Position;
}

const TipContainer = styled.span`
  --v-tooltip-arrow-height: 0.75rem;
  --v-tooltip-font-size: 1rem;
  --v-tooltip-width: 22rem;
  --v-trigger-color: var(--v-color-grey-dark);
  --v-trigger-height: 1.75rem;
  --v-trigger-width: 1.75rem;

  display: inline-block;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    position: relative;
  }
`;

const Trigger = styled.button`
  all: unset;
  color: var(--v-trigger-color);
  display: inline-block;
  height: var(--v-trigger-height);
  vertical-align: middle;
  width: var(--v-trigger-width);
  z-index: 2;
`;

const styledTooltipArrow = {
  [Position.Bottom]: css`
    bottom: calc(0px - var(--v-tooltip-arrow-height));
    left: calc((var(--v-trigger-width) - 1rem) / 2);
    transform: rotate(180deg);
  `,
  [Position.Top]: css`
    left: calc((var(--v-trigger-width) - 1rem) / 2);
    top: calc(0px - var(--v-tooltip-arrow-height));
  `,
};

const TooltipArrow = styled.span<{
  position: Position.Bottom | Position.Top;
  visible: boolean;
}>`
  content: unset;
  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    ${(props) =>
      props.visible
        ? css`
            border-color: transparent;
            border-style: solid;
            border-top-color: var(--v-color-white);
            border-width: var(--v-tooltip-arrow-height) 0.5rem 0.5rem;
            content: "";
            position: absolute;
            z-index: 10;
            ${styledTooltipArrow[props.position]}
          `
        : css``}
  }
`;

const StyledTooltip = styled.span<{
  position: Position;
  visible: boolean;
}>`
  ${(props) =>
    props.visible
      ? css`
          background: var(--v-color-white);
          border-radius: 0.5rem;
          box-shadow: 0 0.25rem 1.5rem #00000029;
          box-sizing: border-box;
          display: block;
          font-size: var(--v-tooltip-font-size);
          font-weight: ${FONTS.WEIGHT.REGULAR};
          height: fit-content;
          left: 50%;
          line-height: 1.5;
          padding: var(--v-spacing-4);
          position: absolute;
          transform: translate(-50%, 0.5rem);
          transition: opacity 300ms;
          width: calc(100vw - (var(--v-spacing-4) * 2));
          z-index: 9;

          @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
            left: -1rem;
            transform: initial;
            width: var(--v-tooltip-width);
          }
        `
      : css``}
`;

const NewClose = styled(SecondaryButton)<{ visible: boolean }>`
  ${(props) =>
    props.visible
      ? css`
          display: block;
          margin-top: var(--v-spacing-4);
          @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
            display: none;
          }
        `
      : css`
          display: none;
        `}
`;

export const NewTooltip = ({
  children,
  className,
  id,
  label,
  tip,
}: TooltipProps) => {
  const [tooltipMessage, setTooltipMessage] = useState<ReactNode | null>(null);
  const [tooltipTop, setTooltipTop] = useState(false);
  const containerRef = useRef() as MutableRefObject<HTMLSpanElement>;
  const triggerRef = useRef() as MutableRefObject<HTMLButtonElement>;
  const tooltipRef = useRef() as MutableRefObject<HTMLSpanElement>;

  const handleDropdownPosition = () => {
    const remSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize,
    );
    const extractRem = (string: string) =>
      parseFloat(string.split("rem")[0]) * remSize;

    const screenWidth = window.innerWidth;
    const mobileBreakpoint = extractRem(DIMENSIONS.BREAKPOINT_MD);

    // Remove any added styles if on mobile
    if (screenWidth < mobileBreakpoint) {
      tooltipRef.current.style.removeProperty("top");
      tooltipRef.current.style.removeProperty("bottom");
      tooltipRef.current.style.removeProperty("transform");
      return;
    }

    const arrowHeightVar = getComputedStyle(
      containerRef.current,
    ).getPropertyValue("--v-tooltip-arrow-height");
    const tooltipFontSizeVar = getComputedStyle(
      containerRef.current,
    ).getPropertyValue("--v-tooltip-font-size");
    const tooltipWidthVar = getComputedStyle(
      containerRef.current,
    ).getPropertyValue("--v-tooltip-width");

    const arrowHeight = extractRem(arrowHeightVar);
    const screenHeight = window.innerHeight;
    const screenPadding = 16;
    const tooltipMinHeight = 2 * remSize + 1.5 * extractRem(tooltipFontSizeVar);
    const tooltipWidth = extractRem(tooltipWidthVar);
    const triggerPosition = triggerRef.current.getBoundingClientRect();

    let translateX = `0`;
    let translateY = arrowHeightVar;

    if (triggerPosition.x + tooltipWidth + arrowHeight > screenWidth) {
      const sizeExceed = triggerPosition.x + tooltipWidth;
      translateX = `${screenWidth - sizeExceed - screenPadding}px`;
    }

    if (triggerPosition.bottom + tooltipMinHeight > screenHeight) {
      tooltipRef.current.style.top = "unset";
      tooltipRef.current.style.bottom = `calc(100% + ${translateY})`;
      translateY = `0`;
      setTooltipTop(true);
    } else {
      tooltipRef.current.style.removeProperty("top");
      tooltipRef.current.style.removeProperty("bottom");
      setTooltipTop(false);
    }

    tooltipRef.current.style.transform = `translate(${translateX}, ${translateY})`;
  };

  const handleInteraction = () => {
    handleDropdownPosition();
    setTooltipMessage(tip);
  };

  const handleBlur = () => {
    setTooltipMessage(null);
  };

  return (
    <TipContainer
      className={className}
      onBlur={handleBlur}
      onMouseOut={handleBlur}
      onMouseOver={handleInteraction}
      ref={containerRef}
    >
      <Trigger
        aria-label={label}
        id={id}
        onClick={handleInteraction}
        onFocus={handleInteraction}
        ref={triggerRef}
        type="button"
      >
        {children}
      </Trigger>

      <span id={id} role="status">
        <TooltipArrow
          position={tooltipTop ? Position.Top : Position.Bottom}
          visible={tooltipMessage ? true : false}
        />
        <StyledTooltip
          position={tooltipTop ? Position.Top : Position.Bottom}
          ref={tooltipRef}
          visible={tooltipMessage ? true : false}
        >
          {tooltipMessage}
          <NewClose
            onClick={handleBlur}
            visible={tooltipMessage ? true : false}
          >
            {textualize("common.close")}
          </NewClose>
        </StyledTooltip>
      </span>
    </TipContainer>
  );
};
