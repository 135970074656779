import ANALYTICS from "creators/constants/analytics/analytics";
import { useEffect } from "react";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";

export const ONBOARDING_STARTED_KEY = "onboarding-started";

export interface IOnboardedProgress {
  [externalID: string]: number;
}

const getStorageData: () => IOnboardedProgress = () =>
  JSON.parse(window.localStorage.getItem(ONBOARDING_STARTED_KEY) || "{}");

export const getStepForCreator = (externalID: string) => {
  const storageData = getStorageData();

  if (storageData[externalID] !== undefined) {
    return storageData[externalID];
  }

  return null;
};

export const useOnboardingProgressAnalytics = (
  creatorExternalID: string,
  stepIndex: number,
) => {
  useEffect(() => {
    const storageData = getStorageData();

    const onboardingStarted = getStepForCreator(creatorExternalID) !== null;

    const onboardingComplete = stepIndex === 4;

    if (!onboardingComplete && !onboardingStarted) {
      // Onboarding has started

      fireAnalyticsEvent(
        ANALYTICS.CATEGORIES.ONBOARDING,
        ANALYTICS.EVENT.ONBOARDING_START,
      );

      const newStorageData = { ...storageData, [creatorExternalID]: stepIndex };

      window.localStorage.setItem(
        ONBOARDING_STARTED_KEY,
        JSON.stringify(newStorageData),
      );
    } else if (onboardingComplete && onboardingStarted) {
      // Onboarding has completed

      fireAnalyticsEvent(
        ANALYTICS.CATEGORIES.ONBOARDING,
        ANALYTICS.EVENT.ONBOARDING_COMPLETE,
      );

      const newStorageData = { ...storageData };
      delete newStorageData[creatorExternalID];

      if (Object.keys(newStorageData).length > 0) {
        window.localStorage.setItem(
          ONBOARDING_STARTED_KEY,
          JSON.stringify(newStorageData),
        );
      } else {
        window.localStorage.removeItem(ONBOARDING_STARTED_KEY);
      }
    } else {
      // Creator has moved to a different step in the onboarding process

      const previousStepIndex = storageData[creatorExternalID];

      // Only record the latest step the creator got to in the process
      if (previousStepIndex < stepIndex) {
        const newStorageData = {
          ...storageData,
          [creatorExternalID]: stepIndex,
        };

        window.localStorage.setItem(
          ONBOARDING_STARTED_KEY,
          JSON.stringify(newStorageData),
        );
      }
    }
  }, [creatorExternalID, stepIndex]);
};

export default useOnboardingProgressAnalytics;
