import DIMENSIONS from "creators/constants/dimensions";
import styled from "styled-components";

export default styled.div`
  align-self: center;
  background: var(--v-color-white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-items: center;
  max-height: 100%;
  overflow: auto;
  padding: var(--v-spacing-4);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding: var(--v-spacing-8);
  }

  &:focus {
    outline: none;
  }
`;
