import LabelledCheckBox, {
  IProps as ILabelledCheckBox,
} from "creators/components/LabelledCheckBox/LabelledCheckBox";
import { useField } from "formik";

interface IProps {
  className?: string;
  inputProps?: Omit<ILabelledCheckBox, "onChange" | "value">;
  name: string;
}

function FormikCheckbox({ className, inputProps, ...props }: IProps) {
  const [field] = useField(props);

  return (
    <LabelledCheckBox
      {...field}
      {...inputProps}
      checked={field.value}
      className={className}
    />
  );
}

export default FormikCheckbox;
