import DIMENSIONS from "creators/constants/dimensions";
import FONTS from "creators/constants/fonts";
import { css } from "styled-components";

export const h1Styles = css`
  font-size: 2rem;
  font-weight: ${FONTS.WEIGHT.BOLD};
  line-height: 1.25;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    font-size: 3rem;
  }
`;

export const h2Styles = css`
  font-size: 1.5rem;
  font-weight: ${FONTS.WEIGHT.BOLD};
  line-height: 1.25;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    font-size: 2rem;
  }
`;

export const h3Styles = css`
  font-size: 1rem;
  font-weight: ${FONTS.WEIGHT.BOLD};
  line-height: 1.5;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    font-size: 1.5rem;
  }
`;

export default { h1Styles, h2Styles, h3Styles };
