import { useQuery } from "@apollo/client";
import ERRORS from "creators/constants/errors";
import { GET_BRIEF_TEASER } from "creators/queries/GetBriefTeaser";
import { GetBriefTeaserQuery as IGetBriefTeaser } from "creators/types/graphql";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import getGraphQLErrors from "shared/utils/getGraphQLErrors";

export interface ICheckResults {
  exists: boolean;
  loading: boolean;
}

export interface IParams {
  id?: string;
  previewHash?: string;
}

// Used by live briefs to check if a brief exists and show a 404 on the main page if not
export const useTeaserExistenceCheck = (): ICheckResults => {
  // Get brief ID from route
  const { id, previewHash } = useParams<IParams>();

  // Only check when mounting
  const [initialTeaserID, setInitialTeaserID] = useState(id);

  // Clear the cached ID when heading back to live briefs page
  useEffect(() => {
    if (id === undefined) {
      setInitialTeaserID(undefined);
    }
  }, [id]);

  const { loading, error } = useQuery<IGetBriefTeaser>(GET_BRIEF_TEASER, {
    variables: { id: initialTeaserID, previewHash },
    skip: initialTeaserID === undefined,
  });

  return {
    exists:
      error && getGraphQLErrors(error).includes(ERRORS.NOT_FOUND)
        ? false
        : true,
    loading,
  };
};

export default useTeaserExistenceCheck;
