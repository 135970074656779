export interface ITimeBetween {
  days: number;
  hours: number;
  minutes: number;
}

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

export const calculateTimeBetween = (
  startingDate = new Date(),
  targetDate: Date,
): ITimeBetween => {
  // Add (almost) a minute to compensate calculation for lack of second unit
  const remaining = targetDate.getTime() - startingDate.getTime() + MINUTE - 1;

  return {
    days: Math.max(0, Math.floor(remaining / DAY)),
    hours: Math.max(0, Math.floor(remaining / HOUR)) % 24,
    minutes: Math.max(0, Math.floor(remaining / MINUTE)) % 60,
  };
};

export default calculateTimeBetween;
