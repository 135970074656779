import { gql } from "@apollo/client";
import { BriefFieldsWithSubmission } from "creators/fragments/brief";
import { NextPageOnlyFields } from "creators/fragments/pageInfo";

export const LiveBriefsEdgesFields = gql`
  fragment LiveBriefsEdgesFields on BriefEdge {
    cursor
    node {
      ...BriefFieldsWithSubmission
    }
  }
  ${BriefFieldsWithSubmission}
`;

export const LiveBriefsFields = gql`
  fragment LiveBriefsFields on BriefsResponse {
    edges {
      ...LiveBriefsEdgesFields
    }
    pageInfo {
      ...NextPageOnlyFields
    }
  }
  ${LiveBriefsEdgesFields}
  ${NextPageOnlyFields}
`;

export const GET_LIVE_BRIEFS = gql`
  query GetLiveBriefs(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $type: BriefType
  ) {
    liveBriefsV2(
      after: $after
      before: $before
      first: $first
      last: $last
      type: $type
      includeBriefsWithoutSubmission: true
      submissionStates: [
        APPLIED
        APPLICATION_WITHDRAWN
        DECLINED
        INVITE_DECLINED
      ]
    ) {
      ...LiveBriefsFields
    }
  }
  ${LiveBriefsFields}
`;

export default {
  GET_LIVE_BRIEFS,
};
