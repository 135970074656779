import NavigationButton from "creators/components/NavigationButton";
import OverlayBackdrop from "creators/components/OverlayBackdrop";
import DIMENSIONS from "creators/constants/dimensions";
import IDS from "creators/constants/ids";
import BriefTeaser from "creators/pages/BriefTeaser";
import { BriefAnalyticsContext } from "creators/providers/BriefAnalyticsProvider";
import { useCallback, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import styled from "styled-components";

const Container = styled.div`
  background: var(--v-color-white);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  will-change: transform;
  z-index: 2;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    background: transparent;
    flex-direction: row;
    gap: 1rem;
  }
`;

const StyledCloseButton = styled(NavigationButton)`
  align-self: flex-start;
  margin-top: 0;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    margin-top: var(--v-spacing-4);
  }
`;

interface IRouteParams {
  id?: string;
  previewHash?: string;
  slug?: string;
}

const AnimatedContainer = animated(Container);
const AnimatedOverlayBackdrop = animated(OverlayBackdrop);

export const BriefTeaserContainer = () => {
  const { cleanAnalyticsData } = useContext(BriefAnalyticsContext);
  const match = useRouteMatch<IRouteParams>({
    exact: true,
    path: ["/brief/:id(\\d+)/:slug?", "/brief/:id(\\d+)/teaser/:previewHash"],
  });
  const history = useHistory();

  const closeBrief = useCallback(() => {
    cleanAnalyticsData();
    history.push("/");
  }, [cleanAnalyticsData, history]);

  const transitionData = {
    id: match?.params.id,
    previewHash: match?.params.previewHash,
    slug: match?.params.slug,
  };

  const navMenuTransitions = useTransition(transitionData, {
    enter: { transform: "translateX(0%)" },
    from: {
      transform: "translateX(100%)",
    },
    key: (item: IRouteParams) => item.id,
    leave: { transform: "translateX(100%)" },
  });

  const navOverlayTransitions = useTransition(transitionData, {
    config: {
      // Hide overlay faster than brief
      clamp: true,
      friction: 13,
    },
    enter: { opacity: 1 },
    from: { opacity: 0 },
    key: (item: IRouteParams) => item.id,
    leave: { opacity: 0 },
  });

  return (
    <>
      {/* Animate overlay in and out when needed */}
      {navOverlayTransitions(
        (props, { id }, { key }) =>
          id && (
            <AnimatedOverlayBackdrop
              key={key}
              onClick={closeBrief}
              style={props}
            />
          ),
      )}

      {navMenuTransitions(
        (props, { id, previewHash, slug }, { key }) =>
          id && (
            <AnimatedContainer key={key} onClose={closeBrief} style={props}>
              <StyledCloseButton
                id={IDS.BRIEF_TEASER.CLOSE}
                onClick={closeBrief}
              />

              <BriefTeaser briefID={id} previewHash={previewHash} slug={slug} />
            </AnimatedContainer>
          ),
      )}
    </>
  );
};

export default BriefTeaserContainer;
