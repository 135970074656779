import IDS from "creators/constants/ids";
import { Location } from "history";
import { ReactNode, useCallback, useState } from "react";
import { match } from "react-router";
import {
  ActiveIcon,
  Disabled,
  StyledAnchor,
  StyledAnimatedIcon,
  StyledButton,
  StyledButtonInner,
  StyledNavLink,
} from "./styles";

export interface IProps {
  className?: string;
  disabled?: boolean;
  href?: string;
  icon: string;
  id?: string;
  isActive?: (match: match<any> | null, location: Location<unknown>) => boolean;
  label: ReactNode | string;
  onClick?: () => void;
  rel?: string;
  showActiveIcon?: boolean;
  target?: string;
  to?: string;
}

export const GlobalNavItem: React.FC<React.PropsWithChildren<IProps>> = ({
  className,
  href,
  icon,
  id,
  isActive,
  label,
  onClick,
  rel,
  showActiveIcon = false,
  target,
  to,
}) => {
  const [hovered, setHovered] = useState(false);

  const onHoverEnter = useCallback(() => {
    setHovered(true);
  }, []);
  const onHoverLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const sharedProps = {
    className,
    id,
    onClick,
  };

  const hoverProps = {
    onBlur: onHoverLeave,
    onFocus: onHoverEnter,
    onMouseEnter: onHoverEnter,
    onMouseLeave: onHoverLeave,
  };

  const content = (
    <>
      <StyledAnimatedIcon path={icon} playing={hovered} />
      <span>{label}</span>

      {showActiveIcon && <ActiveIcon id={IDS.NAV.ACTIVE_ICON} />}
    </>
  );

  if (to) {
    return (
      <StyledNavLink
        {...sharedProps}
        {...hoverProps}
        exact={true}
        isActive={isActive}
        rel={rel}
        target={target}
        to={to}
      >
        {content}
      </StyledNavLink>
    );
  }

  if (href) {
    return (
      <StyledAnchor
        {...sharedProps}
        {...hoverProps}
        href={href}
        rel={rel}
        target={target}
      >
        {content}
      </StyledAnchor>
    );
  }

  if (onClick) {
    return (
      <StyledButton {...sharedProps} {...hoverProps}>
        <StyledButtonInner>{content}</StyledButtonInner>
      </StyledButton>
    );
  }

  return <Disabled {...sharedProps}>{content}</Disabled>;
};

export default GlobalNavItem;
