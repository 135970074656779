import { gql } from "@apollo/client";

export const MoneyFields = gql`
  fragment MoneyFields on Money {
    amount
    currencyCode
  }
`;

export default {
  MoneyFields,
};
