import Environment from "shared/types/environment";

// Possible domains that might host staging environments
const STAGING_DOMAINS = [".staging.vidsy.co", ".vidsy.engineering"];

export const getEnvironment = (context: Window = window): Environment => {
  const prodEnv = process.env.NODE_ENV === "production";

  if (!prodEnv) {
    return Environment.Development;
  }

  if (
    STAGING_DOMAINS.some((domain) => context.location.hostname.endsWith(domain))
  ) {
    return Environment.Staging;
  }

  return Environment.Live;
};

export default getEnvironment;
