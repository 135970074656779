import { ApolloError } from "@apollo/client";
import Button from "creators/components/Button";
import CrashReporterBoundary from "creators/components/CrashReporterBoundary";
import textualize from "creators/utils/textualize";
import { ErrorThrower } from "shared/components/errors/ModuleError";
import styled from "styled-components";

interface IDataProps {
  error: ApolloError;
}

const ModuleError = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: var(--v-spacing-8);
`;

const StyledButton = styled(Button)`
  margin-top: var(--v-spacing-8);
`;

export const DataErrorMessage = () => (
  <ModuleError>
    {textualize("error.data.description")}
    <StyledButton onClick={() => window.location.reload()}>
      {textualize("error.data.reloadPage")}
    </StyledButton>
  </ModuleError>
);

export const DataError = ({ error }: IDataProps) => (
  <CrashReporterBoundary fallback={DataErrorMessage}>
    <ErrorThrower error={error} />
  </CrashReporterBoundary>
);

export default DataError;
