import { css } from "styled-components";

export const baseScrollbarStyles = css`
  scrollbar-color: var(--v-color-grey) var(--v-color-grey-light);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 0.25rem;
    width: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--v-color-grey);
    border-radius: 0.25rem;
    border: 0.25rem solid transparent;
  }

  &::-webkit-scrollbar-track {
    background: var(--v-color-grey-light);
  }
`;

export default baseScrollbarStyles;
