import get from "lodash/get";
import {
  Attributes,
  cloneElement,
  ComponentClass,
  createElement,
  FunctionComponent,
  isValidElement,
  ReactChild,
  ReactElement,
  ReactFragment,
  ReactNode,
} from "react";

type Key = number | string;

export interface CopyDefinition {
  [key: string]: string | CopyDefinition;
}

export interface Props {
  count?: number;
  [key: string]: any;
}

const getText = (
  copy: CopyDefinition,
  key: string,
  count?: number | undefined,
): string => {
  const value = get(copy, key);
  if (value === undefined) {
    throw new Error(`Missing dictionary key "${key}"`);
  }

  if (typeof value === "string") {
    return value;
  }

  const other = value?.other || undefined;
  const zero = value?.zero || other;
  const one = value?.one || other;

  let text = other;

  if (count === 0) {
    text = zero;
  } else if (count === 1) {
    text = one;
  }

  if (text === undefined) {
    throw new Error(`Missing dictionary subkeys in "${key}"`);
  }

  if (typeof text === "string") {
    return text;
  }

  throw new Error(`Missing string for dictionary key "${key}"`);
};

const prepareChild = <P extends Record<string, any>>(
  variable:
    | number
    | string
    | ReactElement<P>
    | ComponentClass<P>
    | FunctionComponent<P>,
  props: P,
  key: Key,
): ReactChild => {
  if (typeof variable === "number" || typeof variable === "string") {
    return variable;
  }

  const child: ReactElement<P> =
    typeof variable === "function"
      ? // Note that this can be ComponentClass or a FunctionComponent, but
        // TypeScript has trouble differentiating between them in this context
        createElement(variable as FunctionComponent<P>, props)
      : variable;

  if (isValidElement(child)) {
    return cloneElement(child as ReactElement<any>, { key } as Attributes);
  }

  throw new Error(
    `Invalid sustitution value supplied: expected number, string, React element or React component, but received ${typeof variable}`,
  );
};

const replaceVariables = <P extends Record<string, any>>(
  text: string,
  props: P,
): ReactFragment | string => {
  const re = /{{\s(\w+)\s}}/g;

  let isPlainText = true;

  const children = text
    .split(re)
    .reduce(
      (accum: Array<ReactChild | null>, fragment: string, index: number) => {
        if (index % 2 === 0) {
          accum.push(fragment);
        } else if (fragment && fragment in props) {
          const variable = props[fragment];

          // Use Object.assign instead of spread operator because of TypeScript bug
          // https://github.com/Microsoft/TypeScript/issues/13557
          const child = prepareChild<P>(
            variable,
            Object.assign({}, props),
            index,
          );

          if (child && isValidElement(child)) {
            isPlainText = false;
          }

          accum.push(child);
        } else {
          throw new Error(`Missing substitution value for "${fragment}"`);
        }

        return accum;
      },
      [],
    );

  return isPlainText
    ? children.join("")
    : ((<>{children}</>) as unknown as ReactFragment);
};

const textualize = <P extends Props = Record<string, any>>(
  copy: CopyDefinition,
  identifier: string,
  props?: P,
): ReactNode => {
  if (props) {
    const text = getText(copy, identifier, props.count);

    return replaceVariables<P>(text, props);
  } else {
    const text = getText(copy, identifier);

    return replaceVariables<Props>(text, {});
  }
};

export default <P extends Props = Record<string, any>>(
    copy: CopyDefinition,
  ): ((identifier: string, props?: P) => ReactNode) =>
  <P extends Props>(identifier: string, props?: P) =>
    textualize(copy, identifier, props);
