import { ApolloError } from "@apollo/client";

export const getGraphQLErrors = (apolloError?: ApolloError): string[] => {
  if (!apolloError || !apolloError.graphQLErrors) {
    return [];
  }

  return apolloError.graphQLErrors.map(
    (gqlError) => gqlError.extensions?.code as string,
  );
};

export default getGraphQLErrors;
