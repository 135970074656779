import Environment from "shared/types/environment";
import processEnvWarning from "shared/utils/processEnvWarning";

processEnvWarning(["ENDPOINT"], "API");

export const API = {
  API: {
    [Environment.Development]:
      process.env.API_ENDPOINT || "https://api.staging.vidsy.co/v1/query",
    [Environment.Live]: "https://api.vidsy.co/v1/query",
    [Environment.Staging]:
      process.env.API_ENDPOINT || "https://api.staging.vidsy.co/v1/query",
  },
  CLIENTS: {
    [Environment.Development]:
      process.env.API_ENDPOINT || "https://client-gql.staging.vidsy.co/query",
    [Environment.Live]: "https://client-gql.vidsy.co/query",
    [Environment.Staging]:
      process.env.API_ENDPOINT || "https://client-gql.staging.vidsy.co/query",
  },
  CREATORS: {
    [Environment.Development]:
      process.env.API_ENDPOINT || "https://creator-gql.staging.vidsy.co/query",
    [Environment.Live]: "https://creator-gql.vidsy.co/query",
    [Environment.Staging]:
      process.env.API_ENDPOINT || "https://creator-gql.staging.vidsy.co/query",
  },
  INTEGRATIONS: {
    [Environment.Development]:
      process.env.API_ENDPOINT ||
      "https://integrations-gql.staging.vidsy.co/query",
    [Environment.Live]: "https://integrations-gql.vidsy.co/query",
    [Environment.Staging]:
      process.env.API_ENDPOINT ||
      "https://integrations-gql.staging.vidsy.co/query",
  },
  INTERNAL: {
    [Environment.Development]:
      process.env.API_ENDPOINT || "https://graphql-api.staging.vidsy.co/query",
    [Environment.Live]: "https://graphql-api.vidsy.co/query",
    [Environment.Staging]:
      process.env.API_ENDPOINT || "https://graphql-api.staging.vidsy.co/query",
  },
};

export default API;
