import { ViewerFieldsFragment as IViewer } from "creators/types/graphql";

const onboardingIncomplete = ({
  currency,
  location,
  portfolio,
  skills,
}: IViewer) =>
  !skills || skills.length < 1 || !portfolio || !location || !currency;

export default onboardingIncomplete;
