import { MouseEvent } from "react";

export const scrollToAnchor = (e: MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault();

  const targetEl = document.querySelector(
    e.currentTarget.getAttribute("href")!,
  );

  if (targetEl) {
    targetEl.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

export default scrollToAnchor;
