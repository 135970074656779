import { useQuery } from "@apollo/client";
import Prompt from "creators/components/Prompt";
import IDS from "creators/constants/ids";
import { GET_ACTIVE_BRIEF } from "creators/queries/GetActiveBrief";
import {
  GetActiveBriefQuery as IGetActiveBrief,
  SubmissionState,
} from "creators/types/graphql";
import textualize from "creators/utils/textualize";

const LiveBriefsPrompt = () => {
  const { data } = useQuery<IGetActiveBrief>(GET_ACTIVE_BRIEF);
  const submission = data?.viewerSubmissions.edges?.[0]?.node;

  if (submission?.state === SubmissionState.Invited) {
    return (
      <Prompt
        id={IDS.LIVE_BRIEFS.ACTIVE_BRIEF_PROMPT.CONTAINER}
        link={{
          id: IDS.LIVE_BRIEFS.ACTIVE_BRIEF_PROMPT.LINK,
          text: textualize("liveBriefs.activeBriefPrompt.link"),
          to: "/active-brief",
        }}
        message={textualize("liveBriefs.activeBriefPrompt.message", {
          title: submission.brief.title,
        })}
      />
    );
  }

  return null;
};

export default LiveBriefsPrompt;
