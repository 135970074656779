import {
  analyticsBriefTypeMap,
  AnalyticsProps,
} from "creators/types/analytics";
import {
  ActiveBriefFieldsFragment as IActiveBrief,
  BriefTeaserFieldsFragment as ITeaserBrief,
} from "creators/types/graphql";
import { createContext, ReactNode, useCallback, useState } from "react";

interface IProps {
  children: ReactNode;
}

interface IContext {
  briefAnalytics: AnalyticsProps;
  cleanAnalyticsData: () => void;
  saveAnalyticsData: (brief: IActiveBrief | ITeaserBrief) => void;
}

export const BriefAnalyticsContext = createContext<IContext>({
  briefAnalytics: {},
  cleanAnalyticsData: () => null,
  saveAnalyticsData: () => null,
});

const BriefAnalyticsProvider = ({ children }: IProps) => {
  const [briefAnalytics, setBriefAnalytics] = useState({});

  const saveAnalyticsData = useCallback(
    (brief: IActiveBrief | ITeaserBrief) => {
      const brand = brief.brand;
      const paymentAmount = brief.paymentAmount;

      setBriefAnalytics({
        brandID: brand.id,
        brandName: brand.name,
        briefAmount: paymentAmount.amount,
        briefCurrency: paymentAmount.currencyCode,
        briefID: brief.id,
        briefName: brief.slug,
        briefState: "-",
        briefType: analyticsBriefTypeMap[brief.type],
      });
    },
    [],
  );

  const cleanAnalyticsData = useCallback(() => {
    setBriefAnalytics({});
  }, []);

  return (
    <BriefAnalyticsContext.Provider
      value={{ briefAnalytics, saveAnalyticsData, cleanAnalyticsData }}
    >
      {children}
    </BriefAnalyticsContext.Provider>
  );
};

export default BriefAnalyticsProvider;
