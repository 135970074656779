import FONTS from "creators/constants/fonts";
import customProperties from "creators/styling/CustomProperties";
import { h1Styles, h2Styles, h3Styles } from "creators/styling/Heading/Heading";
import linkStyles from "creators/styling/Link";
import smallStyles from "creators/styling/SmallText";
import useFeature from "shared/hooks/useFeature";
import { createGlobalStyle, css } from "styled-components";
import reset from "styled-reset";
import VuiGlobalStyle from "vui/styles";

export const innerStyles = css`
  h1 {
    ${h1Styles}
  }

  h2 {
    ${h2Styles}
  }

  h3 {
    ${h3Styles}
  }

  a {
    ${linkStyles}
  }

  strong {
    font-weight: ${FONTS.WEIGHT.BOLD};
  }

  em {
    font-style: italic;
  }

  small {
    ${smallStyles}
  }
`;

const CreatorsGlobalStyle = createGlobalStyle`
  /* CSS Reset */
  ${reset}

  :root {
    ${customProperties}
  }

  html {
    background-color: var(--v-color-white);
    /* Box model */
    box-sizing: border-box;
    color: var(--v-color-black);
    font: 16px ${FONTS.FAMILY.BODY};
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    /* Fonts */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
  }

  ${innerStyles}
`;

const GlobalStyle: React.FC = () => {
  const creatorsVUI = useFeature("creatorsVUI");

  if (creatorsVUI) {
    return <VuiGlobalStyle />;
  }

  return <CreatorsGlobalStyle />;
};

export default GlobalStyle;
