import { useQuery } from "@apollo/client";
import VuiNavItem from "creators/components/vui/NavItem";
import EXTERNAL_LINKS from "creators/constants/externalLinks";
import { routes as homeRoutes } from "creators/pages/Home";
import { GET_VIEWER } from "creators/queries/GetViewer";
import {
  EmailVerificationStage,
  GetViewerQuery as IGetViewer,
} from "creators/types/graphql";
import onboardingIncomplete from "creators/utils/onboardingIncomplete";
import textualize from "creators/utils/textualize";
import flatten from "lodash/flatten";
import { useRouteMatch } from "react-router-dom";
import { NavRailContent, NavRailFooter } from "vui/components/NavRail";
import AccountCircleIcon from "vui/icons/account-circle.svg";
import VuiAwardIcon from "vui/icons/award.svg";
import AccountCircleFilledIcon from "vui/icons/filled/account-circle.svg";
import VuiAwardFilledIcon from "vui/icons/filled/award.svg";
import VuiHomeFilledIcon from "vui/icons/filled/home.svg";
import VuiPastTaskFilledIcon from "vui/icons/filled/past-task.svg";
import VuiPaymentsFilledIcon from "vui/icons/filled/payments.svg";
import VuiTaskFilledIcon from "vui/icons/filled/task.svg";
import VuiHelpIcon from "vui/icons/help.svg";
import VuiHomeIcon from "vui/icons/home.svg";
import VuiPastTaskIcon from "vui/icons/past-task.svg";
import VuiPaymentsIcon from "vui/icons/payments.svg";
import VuiSchoolIcon from "vui/icons/school.svg";
import VuiTaskIcon from "vui/icons/task.svg";

export interface IPreviewBriefParams {
  id: string;
  previewHash: string;
}

const GlobalNav: React.FC = () => {
  const { data: viewerData } = useQuery<IGetViewer>(GET_VIEWER);

  const activeBriefRoute = useRouteMatch("/active-brief");
  const previewBriefRoute = useRouteMatch<IPreviewBriefParams>(
    "/brief/:id(\\d+)/full/:previewHash",
  );

  const homeRoute = useRouteMatch({
    path: flatten(homeRoutes.map((route) => route.path)),
    exact: true,
  });
  const pastBriefsRoute = useRouteMatch("/previous-briefs");
  const paymentsRoute = useRouteMatch("/payments");
  const awardsRoute = useRouteMatch("/achievements");
  const settingsRoute = useRouteMatch("/settings");

  const verifiedAndOnboarded =
    !!viewerData?.viewer &&
    viewerData.viewer.emailVerificationStage ===
      EmailVerificationStage.Verified &&
    !!viewerData.viewer.termsAndConditionsAccepted &&
    !onboardingIncomplete(viewerData.viewer);

  const isActiveBriefPage = !!activeBriefRoute || !!previewBriefRoute;

  return (
    <>
      <NavRailContent>
        <VuiNavItem
          active={!!homeRoute}
          icon={VuiHomeIcon}
          iconFilled={VuiHomeFilledIcon}
          label={textualize("vuiNav.home")}
          to="/"
        />

        {verifiedAndOnboarded && (
          <>
            <VuiNavItem
              active={isActiveBriefPage}
              icon={VuiTaskIcon}
              iconFilled={VuiTaskFilledIcon}
              label={textualize("vuiNav.activeBrief")}
              to={verifiedAndOnboarded ? "/active-brief" : undefined}
            />
            <VuiNavItem
              active={!!pastBriefsRoute}
              icon={VuiPastTaskIcon}
              iconFilled={VuiPastTaskFilledIcon}
              label={textualize("vuiNav.pastBriefs")}
              to={verifiedAndOnboarded ? "/previous-briefs" : undefined}
            />
            <VuiNavItem
              active={!!paymentsRoute}
              icon={VuiPaymentsIcon}
              iconFilled={VuiPaymentsFilledIcon}
              label={textualize("vuiNav.payments")}
              to={verifiedAndOnboarded ? "/payments" : undefined}
            />

            <VuiNavItem
              active={!!awardsRoute}
              icon={VuiAwardIcon}
              iconFilled={VuiAwardFilledIcon}
              label={textualize("vuiNav.awards")}
              to={verifiedAndOnboarded ? "/achievements" : undefined}
            />
          </>
        )}
        <VuiNavItem
          href={EXTERNAL_LINKS.CREATOR_HUB}
          icon={VuiSchoolIcon}
          label={textualize("vuiNav.creatorHub")}
          rel="noopener"
          target="_blank"
        />
        <VuiNavItem
          href={EXTERNAL_LINKS.HELP}
          icon={VuiHelpIcon}
          label={textualize("vuiNav.help")}
        />
      </NavRailContent>

      <NavRailFooter>
        <VuiNavItem
          active={!!settingsRoute}
          icon={AccountCircleIcon}
          iconFilled={AccountCircleFilledIcon}
          label={textualize("vuiNav.account")}
          to={"/settings"}
        />
      </NavRailFooter>
    </>
  );
};

export default GlobalNav;
