import { components } from "react-select";
import { StyledCheckboxIcon } from "shared/components/CheckBox";
import Icon from "shared/components/Icon";
import RadioButton from "shared/components/RadioButton";
import {
  Icon as RadioIcon,
  StyledInput,
} from "shared/components/RadioButton/RadioButton";
import COLORS from "shared/constants/colors";
import CloseIcon from "shared/images/icons/close.svg";
import styled from "styled-components";

export interface IDropdownIndicatorIconProps {
  open: boolean;
}

export const createDropdownIndicator = (Component: any) => (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <DropdownIndicatorIcon
          component={Component}
          open={props.selectProps.menuIsOpen}
        />
      </components.DropdownIndicator>
    )
  );
};

export const DropdownIndicatorIcon = styled(Icon)<IDropdownIndicatorIconProps>`
  height: 100%;
  width: 100%;
  transform: rotate(${(props) => (props.open ? "180deg" : 0)});

  & svg {
    display: block;
  }
`;

export const OptionIcon = styled(Icon)`
  margin-right: 0.25rem;

  > svg {
    vertical-align: top;
  }
`;

export const ImageOptionIcon = styled.img`
  margin-right: 0.25rem;
`;

export const Middle = styled.div`
  align-items: center;
  display: flex;
`;

export const StyledRadioButton = styled(RadioButton)`
  align-items: center;
  display: flex;

  ${StyledInput} {
    :checked + ${RadioIcon} {
      border-color: ${COLORS.RED};
    }

    :focus + ${RadioIcon}, :hover + ${RadioIcon} {
      border-color: ${COLORS.RED};
      box-shadow: none;
    }

    :checked + ${RadioIcon}::before {
      background-color: ${COLORS.RED};
    }
  }

  ${RadioIcon} {
    border-color: ${COLORS.GREY};
    height: 1rem;
    width: 1rem;

    ::before {
      height: 0.625rem;
      margin: 0.125rem;
      width: 0.625rem;
    }
  }
`;

const OptionCheckbox = styled(StyledCheckboxIcon)`
  border-color: ${COLORS.LIGHTER_GREY};
  height: 1.25rem;
  padding: 0.0625rem;
  width: 1.25rem;
`;

export const Option = (props: any) => {
  const optionValue = props?.value || undefined;
  const className = optionValue ? `option-${optionValue}` : "";

  return (
    <components.Option {...props} className={className}>
      <Middle>
        {props?.isMulti && (
          <OptionCheckbox
            checked={props.isSelected}
            disabled={props.isDisabled}
          />
        )}
        {props.data.icon &&
          (typeof props.data.icon === "string" ? (
            <ImageOptionIcon alt="" src={props.data.icon} />
          ) : (
            <>
              <OptionIcon component={props.data.icon} />
            </>
          ))}
        <span>{props.data.label}</span>
      </Middle>
    </components.Option>
  );
};

export const RadioOption = (props: any) => {
  const optionValue = props?.value || undefined;
  const className = optionValue ? `option-${optionValue}` : "";
  return (
    <components.Option {...props} className={className}>
      <StyledRadioButton
        checked={props.isSelected}
        label={props.data.label}
        name={props.data.label}
        onChange={() => props.selectOption(props.data)}
        value={props.data.value}
      />
    </components.Option>
  );
};

export const SingleValue = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <Middle>
        {props.data.icon &&
          (typeof props.data.icon === "string" ? (
            <ImageOptionIcon alt="" src={props.data.icon} />
          ) : (
            <OptionIcon component={props.data.icon} />
          ))}
        <span>{props.data.label}</span>
      </Middle>
    </components.SingleValue>
  );
};

export const MultiValueContainer = ({ children, ...props }: any) => {
  return (
    <components.MultiValueContainer {...props}>
      <Middle>{children}</Middle>
    </components.MultiValueContainer>
  );
};

export const MultiValueLabel = (props: any) => {
  return (
    <components.MultiValueLabel {...props}>
      <Middle>
        {props.data.icon &&
          (typeof props.data.icon === "string" ? (
            <ImageOptionIcon alt="" src={props.data.icon} />
          ) : (
            <OptionIcon component={props.data.icon} />
          ))}
        <span>{props.data.label}</span>
      </Middle>
    </components.MultiValueLabel>
  );
};

export const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon component={CloseIcon} />
    </components.MultiValueRemove>
  );
};

export default {
  Option,
  SingleValue,
  MultiValueLabel,
  MultiValueContainer,
  MultiValueRemove,
};
