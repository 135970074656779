import Tag, { Color } from "creators/components/Tag";
import styled from "styled-components";

export const Heading = styled.h2`
  margin-bottom: var(--v-spacing-12);
`;

export const Subheading = styled.h3`
  margin-bottom: var(--v-spacing-4);
`;

export const Message = styled.p`
  margin-bottom: var(--v-spacing-12);
`;

export const UnsuccessfulVerification = styled(Tag).attrs(() => ({
  color: Color.Red,
}))`
  display: inline-block;
  margin-bottom: var(--v-spacing-4);
`;
