import ANIMATIONS from "creators/constants/animations";
import ErrorPage from "creators/pages/Error";
import textualize from "creators/utils/textualize";
import { Helmet } from "react-helmet";
import useLottieAnimation from "shared/hooks/useLottieAnimation";
import styled from "styled-components";

const AnimationContainer = styled.div`
  margin: var(--v-spacing-8) 0;
  max-height: 40vh;
`;

export const NotFound = () => {
  const { AnimationComponent } = useLottieAnimation({
    animation: ANIMATIONS.NOT_FOUND,
  });

  return (
    <ErrorPage>
      <Helmet title={textualize("notFound.heading") as string} />

      <AnimationContainer>{AnimationComponent}</AnimationContainer>

      <h1>{textualize("notFound.heading")}</h1>
      <span>{textualize("notFound.body")}</span>
    </ErrorPage>
  );
};

export default NotFound;
