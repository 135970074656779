import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";

export interface IProps extends HTMLAttributes<HTMLInputElement> {
  hasError?: boolean;
  value: string;
}

export const errorStyles = css`
  border-color: var(--v-color-red);
  box-shadow: inset 0 0 0 0.125rem var(--v-color-red);
`;

export const inputStyles = css`
  appearance: none;
  background-color: var(--v-color-white);
  border: 0.125rem solid var(--v-color-black);
  border-radius: 0.5rem;
  color: var(--v-color-black);
  font: inherit;
  line-height: 1;
  padding: 0.8125rem var(--v-spacing-4);
`;

export const disabledStyles = css`
  background: var(--v-color-grey-light);
  border-color: var(--v-color-grey-light);

  :focus,
  :hover {
    box-shadow: none;
  }
`;

export const focusedStyles = css`
  box-shadow: inset 0 0 0 0.125rem var(--v-color-black);
  outline: none;
`;

export const TextInput = styled.input<IProps>`
  ${inputStyles};

  :focus,
  :hover {
    ${focusedStyles};
  }

  :disabled,
  :read-only {
    ${disabledStyles};
  }

  ${({ hasError }) => hasError && errorStyles}
`;

export default TextInput;
