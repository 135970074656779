import { css } from "styled-components";

const colors = css`
  --vui-color-mono-1: 255, 255, 255; // #ffffff
  --vui-color-mono-2: 247, 246, 248; // #f7f6f8
  --vui-color-mono-3: 238, 238, 238; // #eeeeee
  --vui-color-mono-4: 224, 224, 224; // #e0e0e0
  --vui-color-mono-5: 128, 128, 128; // #808080
  --vui-color-mono-6: 88, 88, 90; // #58585a
  --vui-color-mono-7: 48, 48, 54; // #303036
  --vui-color-mono-8: 36, 36, 41; // #242429
  --vui-color-mono-9: 23, 23, 26; // #17171a
  --vui-color-mono-10: 12, 12, 14; // #0c0c0e
  --vui-color-vidsy-brand: 227, 39, 78; // #e3274e

  --vui-color-palette-positive: 20, 240, 167;
  --vui-color-palette-positive-variant: 194, 255, 222;
  --vui-color-palette-cautionary: 248, 202, 0;
  --vui-color-palette-cautionary-variant: 255, 255, 172;
  --vui-color-palette-negative: var(--vui-color-vidsy-brand);
  --vui-color-palette-negative-variant: 255, 227, 245;
  --vui-color-palette-informative: 53, 97, 220;
  --vui-color-palette-informative-variant: 220, 255, 255;

  --vui-color-background-light: var(--vui-color-mono-1);
  --vui-color-on-background-light: var(--vui-color-mono-9);
  --vui-color-background-variant-light: var(--vui-color-mono-2);
  --vui-color-on-background-variant-light: var(--vui-color-mono-9);
  --vui-color-background-dark: var(--vui-color-mono-10);
  --vui-color-on-background-dark: var(--vui-color-mono-1);
  --vui-color-background-variant-dark: var(--vui-color-mono-10);
  --vui-color-on-background-variant-dark: var(--vui-color-mono-1);
  --vui-color-surface-primary-light: var(--vui-color-mono-1);
  --vui-color-surface-primary-outline-light: var(--vui-color-mono-3);
  --vui-color-on-surface-primary-light: var(--vui-color-mono-9);
  --vui-color-on-surface-primary-variant-light: var(--vui-color-mono-6);
  --vui-color-surface-secondary-light: var(--vui-color-mono-2);
  --vui-color-surface-secondary-outline-light: var(--vui-color-mono-3);
  --vui-color-on-surface-secondary-light: var(--vui-color-mono-9);
  --vui-color-on-surface-secondary-variant-light: var(--vui-color-mono-6);
  --vui-color-surface-tertiary-light: var(--vui-color-mono-4);
  --vui-color-surface-tertiary-outline-light: var(--vui-color-mono-5);
  --vui-color-on-surface-tertiary-light: var(--vui-color-mono-9);
  --vui-color-on-surface-tertiary-variant-light: var(--vui-color-mono-7);
  --vui-color-active-surface-light: var(--vui-color-mono-6);
  --vui-color-active-surface-outline-light: var(--vui-color-mono-6);
  --vui-color-active-on-surface-light: var(--vui-color-mono-1);
  --vui-color-inverse-surface-light: var(--vui-color-mono-9);
  --vui-color-inverse-surface-outline-light: var(--vui-color-mono-9);
  --vui-color-inverse-on-surface-light: var(--vui-color-mono-1);
  --vui-color-inverse-on-surface-variant-light: var(--vui-color-mono-3);
  --vui-color-disabled-surface-light: var(--vui-color-mono-4);
  --vui-color-disabled-surface-outline-light: var(--vui-color-mono-6);
  --vui-color-disabled-on-surface-light: var(--vui-color-mono-6);
  --vui-color-sentiment-positive-light: var(--vui-color-palette-positive);
  --vui-color-sentiment-positive-variant-light: var(
    --vui-color-palette-positive-variant
  );
  --vui-color-on-sentiment-positive-light: var(--vui-color-mono-9);
  --vui-color-on-sentiment-positive-variant-light: var(--vui-color-mono-9);
  --vui-color-sentiment-cautionary-light: var(--vui-color-palette-cautionary);
  --vui-color-sentiment-cautionary-variant-light: var(
    --vui-color-palette-cautionary-variant
  );
  --vui-color-on-sentiment-cautionary-light: var(--vui-color-mono-9);
  --vui-color-on-sentiment-cautionary-variant-light: var(--vui-color-mono-9);
  --vui-color-sentiment-negative-light: var(--vui-color-palette-negative);
  --vui-color-sentiment-negative-variant-light: var(
    --vui-color-palette-negative-variant
  );
  --vui-color-on-sentiment-negative-light: var(--vui-color-mono-1);
  --vui-color-on-sentiment-negative-variant-light: var(--vui-color-mono-9);
  --vui-color-sentiment-informative-light: var(--vui-color-palette-informative);
  --vui-color-sentiment-informative-variant-light: var(
    --vui-color-palette-informative-variant
  );
  --vui-color-on-sentiment-informative-light: var(--vui-color-mono-1);
  --vui-color-on-sentiment-informative-variant-light: var(--vui-color-mono-9);

  --vui-color-background-dark: var(--vui-color-mono-10);
  --vui-color-on-background-dark: var(--vui-color-mono-1);
  --vui-color-sentiment-positive-dark: var(--vui-color-palette-positive);
  --vui-color-sentiment-positive-variant-dark: var(
    --vui-color-palette-positive
  );
  --vui-color-on-sentiment-positive-dark: var(--vui-color-mono-9);
  --vui-color-on-sentiment-positive-variant-dark: var(--vui-color-mono-9);
  --vui-color-sentiment-cautionary-dark: var(--vui-color-palette-cautionary);
  --vui-color-sentiment-cautionary-variant-dark: var(
    --vui-color-palette-cautionary
  );
  --vui-color-on-sentiment-cautionary-dark: var(--vui-color-mono-9);
  --vui-color-on-sentiment-cautionary-variant-dark: var(--vui-color-mono-9);
  --vui-color-sentiment-negative-dark: var(--vui-color-palette-negative);
  --vui-color-sentiment-negative-variant-dark: var(
    --vui-color-palette-negative
  );
  --vui-color-on-sentiment-negative-dark: var(--vui-color-mono-1);
  --vui-color-on-sentiment-negative-variant-dark: var(--vui-color-mono-1);
  --vui-color-sentiment-informative-dark: var(--vui-color-palette-informative);
  --vui-color-sentiment-informative-variant-dark: var(
    --vui-color-palette-informative
  );
  --vui-color-on-sentiment-informative-dark: var(--vui-color-mono-1);
  --vui-color-on-sentiment-informative-variant-dark: var(--vui-color-mono-1);

  /* Light by default */
  color-scheme: light;

  --vui-color-background-white: var(--vui-color-mono-1);
  --vui-color-background-black: var(--vui-color-mono-10);
  --vui-color-background: var(--vui-color-background-light);
  --vui-color-on-background: var(--vui-color-on-background-light);
  --vui-color-background-variant: var(--vui-color-background-variant-light);
  --vui-color-on-background-variant: var(
    --vui-color-on-background-variant-light
  );
  --vui-color-surface-primary: var(--vui-color-surface-primary-light);
  --vui-color-surface-primary-outline: var(
    --vui-color-surface-primary-outline-light
  );
  --vui-color-on-surface-primary: var(--vui-color-on-surface-primary-light);
  --vui-color-on-surface-primary-variant: var(
    --vui-color-on-surface-primary-variant-light
  );
  --vui-color-surface-secondary: var(--vui-color-surface-secondary-light);
  --vui-color-surface-secondary-outline: var(
    --vui-color-surface-secondary-outline-light
  );
  --vui-color-on-surface-secondary: var(--vui-color-on-surface-secondary-light);
  --vui-color-on-surface-secondary-variant: var(
    --vui-color-on-surface-secondary-variant-light
  );
  --vui-color-surface-tertiary: var(--vui-color-surface-tertiary-light);
  --vui-color-surface-tertiary-outline: var(
    --vui-color-surface-tertiary-outline-light
  );
  --vui-color-on-surface-tertiary: var(--vui-color-on-surface-tertiary-light);
  --vui-color-on-surface-tertiary-variant: var(
    --vui-color-on-surface-tertiary-variant-light
  );
  --vui-color-active-surface: var(--vui-color-active-surface-light);
  --vui-color-active-surface-outline: var(
    --vui-color-active-surface-outline-light
  );
  --vui-color-active-on-surface: var(--vui-color-active-on-surface-light);
  --vui-color-disabled-surface: var(--vui-color-disabled-surface-light);
  --vui-color-disabled-surface-outline: var(
    --vui-color-disabled-surface-outline-light
  );
  --vui-color-disabled-on-surface: var(--vui-color-disabled-on-surface-light);
  --vui-color-inverse-surface: var(--vui-color-inverse-surface-light);
  --vui-color-inverse-surface-outline: var(
    --vui-color-inverse-surface-outline-light
  );
  --vui-color-inverse-on-surface: var(--vui-color-inverse-on-surface-light);
  --vui-color-inverse-on-surface-variant: var(
    --vui-color-inverse-on-surface-variant-light
  );

  --vui-color-sentiment-positive: var(--vui-color-sentiment-positive-light);
  --vui-color-sentiment-positive-variant: var(
    --vui-color-sentiment-positive-variant-light
  );
  --vui-color-on-sentiment-positive: var(
    --vui-color-on-sentiment-positive-light
  );
  --vui-color-on-sentiment-positive-variant: var(
    --vui-color-on-sentiment-positive-variant-light
  );
  --vui-color-sentiment-cautionary: var(--vui-color-sentiment-cautionary-light);
  --vui-color-sentiment-cautionary-variant: var(
    --vui-color-sentiment-cautionary-variant-light
  );
  --vui-color-on-sentiment-cautionary: var(
    --vui-color-on-sentiment-cautionary-light
  );
  --vui-color-on-sentiment-cautionary-variant: var(
    --vui-color-on-sentiment-cautionary-variant-light
  );
  --vui-color-sentiment-negative: var(--vui-color-sentiment-negative-light);
  --vui-color-sentiment-negative-variant: var(
    --vui-color-sentiment-negative-variant-light
  );
  --vui-color-on-sentiment-negative: var(
    --vui-color-on-sentiment-negative-light
  );
  --vui-color-on-sentiment-negative-variant: var(
    --vui-color-on-sentiment-negative-variant-light
  );
  --vui-color-sentiment-informative: var(
    --vui-color-sentiment-informative-light
  );
  --vui-color-sentiment-informative-variant: var(
    --vui-color-sentiment-informative-variant-light
  );
  --vui-color-on-sentiment-informative: var(
    --vui-color-on-sentiment-informative-light
  );
  --vui-color-on-sentiment-informative-variant: var(
    --vui-color-on-sentiment-informative-variant-light
  );

  /* TODO: Define dark colours */
  /* Add class for dark theme */
  &.theme-dark {
    color-scheme: dark;

    --vui-color-background: var(--vui-color-background-dark);
    --vui-color-on-background: var(--vui-color-on-background-dark);
    --vui-color-background-variant: var(--vui-color-background-variant-dark);
    --vui-color-on-background-variant: var(
      --vui-color-on-background-variant-dark
    );

    --vui-color-sentiment-positive: var(--vui-color-sentiment-positive-dark);
    --vui-color-sentiment-positive-variant: var(
      --vui-color-sentiment-positive-variant-dark
    );
    --vui-color-on-sentiment-positive: var(
      --vui-color-on-sentiment-positive-dark
    );
    --vui-color-on-sentiment-positive-variant: var(
      --vui-color-on-sentiment-positive-variant-dark
    );
    --vui-color-sentiment-cautionary: var(
      --vui-color-sentiment-cautionary-dark
    );
    --vui-color-sentiment-cautionary-variant: var(
      --vui-color-sentiment-cautionary-variant-dark
    );
    --vui-color-on-sentiment-cautionary: var(
      --vui-color-on-sentiment-cautionary-dark
    );
    --vui-color-on-sentiment-cautionary-variant: var(
      --vui-color-on-sentiment-cautionary-variant-dark
    );
    --vui-color-sentiment-negative: var(--vui-color-sentiment-negative-dark);
    --vui-color-sentiment-negative-variant: var(
      --vui-color-sentiment-negative-variant-dark
    );
    --vui-color-on-sentiment-negative: var(
      --vui-color-on-sentiment-negative-dark
    );
    --vui-color-on-sentiment-negative-variant: var(
      --vui-color-on-sentiment-negative-variant-dark
    );
    --vui-color-sentiment-informative: var(
      --vui-color-sentiment-informative-dark
    );
    --vui-color-sentiment-informative-variant: var(
      --vui-color-sentiment-informative-variant-dark
    );
    --vui-color-on-sentiment-informative: var(
      --vui-color-on-sentiment-informative-dark
    );
    --vui-color-on-sentiment-informative-variant: var(
      --vui-color-on-sentiment-informative-variant-dark
    );
  }
`;

export default colors;
