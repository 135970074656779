import { HTMLAttributes, ReactNode } from "react";
import { Container, Message, StyledAnchor, StyledLink } from "./styles";

export interface ILink extends HTMLAttributes<HTMLAnchorElement> {
  text: ReactNode | string;
  to?: string;
}

interface IProps {
  className?: string;
  id: string;
  link: ILink;
  message: ReactNode | string;
}

const Prompt: React.FC<React.PropsWithChildren<IProps>> = ({
  className,
  id,
  link,
  message,
}) => {
  const { text: linkText, to, ...linkProps } = link;

  return (
    <Container className={className} id={id}>
      <Message>{message}</Message>
      {to ? (
        <StyledLink {...linkProps} to={to}>
          {linkText}
        </StyledLink>
      ) : (
        <StyledAnchor {...linkProps}>{linkText}</StyledAnchor>
      )}
    </Container>
  );
};

export default Prompt;
