import { ChangeEvent } from "react";
import styled from "styled-components";

export interface IProps {
  "aria-label"?: string;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  onChange?: (e: ChangeEvent) => void;
  value?: string;
}

export const CheckBoxToggle = styled.input`
  appearance: none;
  background: var(--v-color-white);
  border: 0.125rem solid var(--v-color-black);
  border-radius: 1em;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  height: 2em;
  margin: 0;
  position: relative;
  width: 4em;

  ::before {
    background: var(--v-color-black);
    border-radius: 50%;
    content: "";
    height: 1.5em;
    left: 0;
    margin: 0.125em;
    position: absolute;
    width: 1.5em;
  }

  :checked {
    background: var(--v-color-green-active);
    border-color: var(--v-color-green-active);

    ::before {
      background: var(--v-color-white);
      transform: translateX(2em);
    }
  }

  :disabled {
    border-color: var(--v-color-grey);
    cursor: default;

    :focus-visible {
      box-shadow: none;
    }

    ::before {
      background: var(--v-color-grey);
    }

    :checked {
      background: var(--v-color-grey);

      ::before {
        background: var(--v-color-white);
      }
    }
  }

  :focus-visible {
    box-shadow: 0 0 0 0.125rem var(--v-color-black);
    outline: none;
  }
`;

export const CheckBox: React.FC<React.PropsWithChildren<IProps>> = (props) => (
  <CheckBoxToggle type="checkbox" {...props} />
);

export default CheckBox;
