import GlobalNav from "creators/components/GlobalNav";
import NavigationButton from "creators/components/NavigationButton";
import IDS from "creators/constants/ids";
import textualize from "creators/utils/textualize";
import styled from "styled-components";

export interface IProps {
  className?: string;
  id?: string;
  onClose: () => void;
  style?: {
    // React spring animation styles
    transform: string;
  };
}

const Container = styled.nav`
  display: flex;
  gap: 1rem;
  height: 100%;
  left: 0;
  max-width: 100%;
  position: fixed;
  top: 0;
  will-change: transform;
  z-index: 3;
`;

const StyledGlobalNav = styled(GlobalNav)`
  background: var(--v-color-white);
  padding: var(--v-spacing-4);
  width: 11rem;
`;

const StyledCloseButton = styled(NavigationButton)`
  align-self: flex-start;
  margin-top: var(--v-spacing-4);
`;

export const MobileNavMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  className,
  id,
  onClose,
  style,
}) => (
  <Container
    aria-label={textualize("nav.label") as string}
    className={className}
    id={id}
    style={style}
  >
    <StyledGlobalNav />

    <StyledCloseButton id={IDS.NAV.CLOSE} onClick={onClose} />
  </Container>
);

export default MobileNavMenu;
