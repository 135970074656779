import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

import colors from "./colors";
import shape from "./shape";
import spacing from "./spacing";
import state from "./state";
import typography from "./typography";

export default createGlobalStyle`
  /* CSS Reset */
  ${reset}

  :root {
    ${colors}
    ${shape}
    ${spacing}
    ${state}
    ${typography}
  }

  html {
    background-color: rgb(var(--vui-color-background));
    /* Box model */
    box-sizing: border-box;
    color: rgb(var(--vui-color-on-background));
    font-family: var(--vui-brand-font);
    font-size: 16px;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    /* Fonts */
    /* TODO: Update these fields with typography variables once clarified */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1 {
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: -0.02rem;
    line-height: 1.25;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.25;
  }

  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.25;
  }

  h4 {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25;
  }

  /* TODO: Clarify anchor link styling */
  a {
    color: inherit;
    text-decoration: none;
  }

  /* TODO: Clarify which elements to apply body sizing to */
  p, span {
    font-size: 0.875rem;
    line-height: 1.5;

  }
  
  a {
    text-decoration: underline;
  }

  /* TODO: Clarify default strong size */
  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  small {
    font-size: 0.6875rem;
    line-height: 1;
  }
`;
