import EXTERNAL_LINKS from "creators/constants/externalLinks";
import textualize from "creators/utils/textualize";

interface IProps {
  id: string;
  onClick?: () => void;
}

export const PrivacyPolicyLink = ({ id, onClick }: IProps) => (
  <a
    href={EXTERNAL_LINKS.PRIVACY_POLICY}
    id={id}
    onClick={onClick}
    rel="noreferrer"
    target="_blank"
  >
    {textualize("common.termsAndConditions.privacyPolicy")}
  </a>
);

export const TermsAndConditionsLink = ({ id, onClick }: IProps) => (
  <a
    href={EXTERNAL_LINKS.TERMS_AND_CONDITIONS}
    id={id}
    onClick={onClick}
    rel="noreferrer"
    target="_blank"
  >
    {textualize("common.termsAndConditions.termsAndConditions")}
  </a>
);

export default {
  PrivacyPolicyLink,
  TermsAndConditionsLink,
};
