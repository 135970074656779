import { Auth0DecodedHash } from "auth0-js";
import Cookies from "js-cookie";
import Application from "shared/types/application";
import Environment from "shared/types/environment";
import isApp from "shared/utils/isApp";

const COOKIE_DEFAULT_OPTIONS = {
  SameSite: "Strict",
  Secure: !["development", "test"].includes(process.env.NODE_ENV as string)
    ? true
    : false,
};

const REFRESH_TOKEN_ENABLED_APP = isApp(Application.Clients);

interface ISession extends Auth0DecodedHash {
  anonymous?: boolean;
  expiresAt?: number;
}

const COOKIE_DOMAIN_REGEX = /^[^:/]+:\/\/(?:[^.]+\.)?(.*)/;

function getStagingCookieName() {
  const match = window.location.origin.match(COOKIE_DOMAIN_REGEX);
  return match && match[1] ? `.${match[1]}` : undefined;
}

class SessionService {
  private cookieName: string;
  private refreshTokenName: string;
  private domain?: string;
  private session: ISession;

  constructor(
    environment: string,
    cookieSuffix?: string,
    singleSite?: boolean,
  ) {
    this.cookieName = `vidsy-${environment.toLowerCase()}-session${
      cookieSuffix ? `-${cookieSuffix}` : ""
    }`;
    this.refreshTokenName = `refreshToken-${environment.toLowerCase()}${
      cookieSuffix ? `-${cookieSuffix}` : ""
    }`;

    let sessionCookie;
    if (Cookies.get(this.cookieName)) {
      try {
        sessionCookie = JSON.parse(Cookies.get(this.cookieName)!);
      } catch {
        // Broken syntax, ignore cookies in invalid format
      }
    }

    const domain =
      environment === Environment.Staging
        ? getStagingCookieName()
        : ".vidsy.co";

    this.domain = !["development", "test"].includes(
      process.env.NODE_ENV as string,
    )
      ? !singleSite
        ? domain
        : undefined
      : undefined;

    const refreshToken = localStorage.getItem(this.refreshTokenName) || "";
    const accessToken = sessionCookie?.accessToken || "";
    const idToken = sessionCookie?.idToken || "";

    this.session = {
      anonymous: sessionCookie?.anonymous || false,
      expiresAt: parseInt(sessionCookie?.expiresAt || "0"),
      refreshToken,
      tokenType: sessionCookie?.tokenType || "",
      ...(REFRESH_TOKEN_ENABLED_APP && refreshToken
        ? { accessToken }
        : { idToken: accessToken || idToken }),
    };

    this.getSession = this.getSession.bind(this);
    this.isAnonymous = this.isAnonymous.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  public getRefreshTokenName(): string {
    return this.refreshTokenName;
  }

  public updateSession(data: Auth0DecodedHash) {
    // data.expiresIn = 30; // Uncomment this line to test cookie expiration and fetching a new token
    this.session = { ...this.session, ...data };
    if (this.session.expiresIn) {
      this.session.expiresAt =
        this.session.expiresIn * 1000 + new Date().getTime();

      const options = {
        domain: this.domain,
        expires: new Date(this.session.expiresAt),
        ...COOKIE_DEFAULT_OPTIONS,
      };

      const sessionCookie = {
        anonymous: this.session.anonymous,
        expiresAt: this.session.expiresAt.toString(),
        tokenType: this.session.tokenType || "",
        ...(REFRESH_TOKEN_ENABLED_APP &&
        this.session.accessToken &&
        this.session.refreshToken
          ? { accessToken: this.session.accessToken || "" }
          : { idToken: this.session.idToken || "" }),
      };

      if (
        REFRESH_TOKEN_ENABLED_APP &&
        this.session.accessToken &&
        this.session.refreshToken
      ) {
        localStorage.setItem(this.refreshTokenName, this.session.refreshToken);
      }

      Cookies.set(this.cookieName, JSON.stringify(sessionCookie), options);
    }
  }

  public isRefreshTokenEnabled() {
    return !!localStorage.getItem(this.refreshTokenName);
  }

  public getSession() {
    if (this.isAuthenticated()) {
      return this.session;
    }
    return null;
  }

  public clear() {
    this.session = {};
    sessionStorage.clear();
    Cookies.remove(this.cookieName, {
      domain: this.domain,
    });
    if (this.isRefreshTokenEnabled()) {
      localStorage.removeItem(this.refreshTokenName);
    }
  }

  public flagAnonymous() {
    this.session.anonymous = true;
  }

  public isAnonymous() {
    return !!this.session.anonymous;
  }

  public isAuthenticated() {
    if (!this.session.expiresAt) {
      return false;
    }
    return new Date().getTime() < this.session.expiresAt;
  }

  public willExpireInMinutes(minutes: number) {
    const timeout = minutes * 60 * 1000; // Convert minutes to milliseconds;
    const now = new Date().getTime();

    if (!this.session.expiresAt) {
      return 0;
    }

    if (this.session.expiresAt - now < timeout) {
      return this.session.expiresAt - now;
    }
    return false;
  }

  public checkLocalAuthentication() {
    const sessionCookie = Cookies.get(this.cookieName);
    if (!sessionCookie) {
      return false;
    }
    return this.isAuthenticated();
  }
}

export default SessionService;
