import Dropdown from "creators/components/Dropdown";
import FormError from "creators/components/forms/FormError";
import FormLabel from "creators/components/forms/FormLabel";
import smallTextStyles from "creators/styling/SmallText";
import { FocusEvent, useCallback } from "react";
import { IOption } from "shared/types/components/Dropdown";
import styled from "styled-components";

export interface IProps {
  className?: string;
  error?: string;
  help?: string;
  id?: string;
  label?: string;
  onBlur?: (e: FocusEvent) => void;
  onChange: (value: string) => void;
  options?: IOption[];
  value: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const HelpText = styled.div`
  ${smallTextStyles}
  line-height: 1.125rem;
`;

export const FormDropdown: React.FC<React.PropsWithChildren<IProps>> = ({
  className,
  error,
  help,
  id,
  label,
  onBlur,
  onChange,
  options = [],
  value,
}) => {
  const handleChange = useCallback(
    (newOption: IOption) => {
      onChange(newOption.value);
    },
    [onChange],
  );

  return (
    <Container className={className}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}

      {error && <FormError>{error}</FormError>}

      <Dropdown
        hasError={!!error}
        inputId={id}
        onBlur={onBlur}
        onChange={handleChange}
        options={options}
        value={options.find((option) => option.value === value)}
      />

      {help && <HelpText>{help}</HelpText>}
    </Container>
  );
};

export default FormDropdown;
