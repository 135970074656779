import VIDEO from "creators/constants/actions/video";
import { IVideoControlsState } from "creators/types/video";
import { IAction } from "shared/types/actions";

function videoReducer(
  state: IVideoControlsState,
  action: IAction,
): IVideoControlsState {
  switch (action.type) {
    case VIDEO.HIDE_CAPTIONS:
      return {
        ...state,
        showCaptions: false,
      };
    case VIDEO.MUTE:
      return {
        ...state,
        muted: true,
      };
    case VIDEO.PAUSE:
      return {
        ...state,
        playing: false,
      };
    case VIDEO.PLAY:
      return {
        ...state,
        playing: true,
      };
    case VIDEO.SHOW_CAPTIONS:
      return {
        ...state,
        showCaptions: true,
      };
    case VIDEO.UNMUTE:
      return {
        ...state,
        muted: false,
      };
    default:
      throw new Error();
  }
}

export default videoReducer;
