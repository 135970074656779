import { forwardRef } from "react";
import Icon from "shared/components/Icon";
import COLORS from "shared/constants/colors";
import CheckIcon from "shared/images/icons/check.svg";
import smallTextStyles from "shared/styling/SmallText";
import styled, { css } from "styled-components";

interface IProps {
  ariaLabel?: string;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  id: string;
  label?: React.ReactNode;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckBoxLabel = styled.label`
  cursor: pointer;
  padding-right: 0.5rem;
  user-select: none;
`;

export const FormCheckBoxLabel = styled.label`
  background: ${COLORS.DARK_GREY};
  cursor: pointer;
  padding-right: 0.5rem;
  user-select: none;
`;

const checkedStyling = css`
  background-color: ${COLORS.GREEN};
  border-color: ${COLORS.GREEN};
  color: ${COLORS.DARK_GREY};
`;

const disabledStyling = css`
  background-color: ${COLORS.LIGHT_GREY};
  border-color: ${COLORS.LIGHT_GREY};
`;

export const StyledCheckboxIcon = styled(Icon).attrs({ component: CheckIcon })<{
  checked?: boolean;
  disabled?: boolean;
}>`
  border: 1px solid ${COLORS.WHITE};
  border-radius: 2px;
  color: transparent;
  height: 1.5rem;
  margin-right: 0.5rem;
  padding: 0.125rem;
  transition: all 0.3s ease;
  vertical-align: sub;
  width: 1.5rem;

  ${({ checked }) => checked && checkedStyling};
  ${({ disabled }) => disabled && disabledStyling};
`;

export const StyledCheckboxInput = styled.input`
  height: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  width: 0;
  &:checked + ${CheckBoxLabel}, &:checked + ${FormCheckBoxLabel} {
    ${StyledCheckboxIcon} {
      ${checkedStyling};
    }
  }
  &:checked + ${CheckBoxLabel}, &:focus + ${FormCheckBoxLabel} {
    ${StyledCheckboxIcon} {
      border-color: ${COLORS.WHITE};
    }
  }
  &:focus + ${CheckBoxLabel}, &:focus + ${FormCheckBoxLabel} {
    outline: 0.125rem solid ${COLORS.BLUE};
  }
  &:disabled {
    & + ${CheckBoxLabel} {
      color: ${COLORS.LIGHT_GREY};
      cursor: default;

      ${StyledCheckboxIcon} {
        ${disabledStyling}
      }
    }
  }
`;

export const CheckBoxHeader = styled.div`
  margin-bottom: 2rem;
  position: relative;
  &::before {
    background: ${COLORS.GREEN};
    content: "";
    height: 1px;
    position: absolute;
    top: 50%;
    width: 100%;
  }
  ${CheckBoxLabel},
  ${FormCheckBoxLabel} {
    font-size: 0.75rem;
    letter-spacing: 0.085rem;
    position: relative;
    text-transform: uppercase;
  }
`;

// TODO: Make styles default when ContentGroups are updated
export const UpdatedCheckboxHeader = styled(CheckBoxHeader)`
  margin-bottom: 1rem;
  &::before {
    background: ${COLORS.WHITE};
  }
  transform: translateX(-0.5rem);

  ${StyledCheckboxIcon} {
    background: ${COLORS.DARK_GREY};
    margin-right: 0;
    position: absolute;
    right: calc(100% - 0.1875rem);
    top: -0.3125rem;
  }

  ${CheckBoxLabel} {
    ${smallTextStyles};
    padding: 0 0.5rem 0 1rem;
    position: relative;
    top: -0.1875rem;
  }
`;

export const CheckBox = forwardRef<HTMLInputElement, IProps>(
  (
    { ariaLabel, checked, className, disabled, id, label, name, onChange },
    ref,
  ) => (
    <div className={className}>
      <StyledCheckboxInput
        aria-label={ariaLabel}
        checked={checked}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        ref={ref} // Assign the forwarded ref to the input element
        tabIndex={disabled ? undefined : 0}
        type="checkbox"
      />
      <CheckBoxLabel htmlFor={id}>
        <StyledCheckboxIcon />
        {label}
      </CheckBoxLabel>
    </div>
  ),
);

export const FormCheckBox = forwardRef<HTMLInputElement, IProps>(
  ({ checked, className, disabled, id, label, name, onChange }, ref) => (
    <div className={className}>
      <StyledCheckboxInput
        checked={checked}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        ref={ref} // Assign the forwarded ref to the input element
        tabIndex={disabled ? undefined : 0}
        type="checkbox"
      />
      <FormCheckBoxLabel htmlFor={id}>
        <StyledCheckboxIcon />
        {label}
      </FormCheckBoxLabel>
    </div>
  ),
);

export default CheckBox;
