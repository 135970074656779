import Loading from "creators/components/Loading";
import TextInput from "creators/components/TextInput";
import styled from "styled-components";

export const Container = styled.div`
  display: inline-block;
  position: relative;
`;

export const StyledTextInput = styled(TextInput)`
  width: 100%;
`;

export const SuggestionsList = styled.ul`
  background-color: var(--v-color-white);
  box-shadow: 0 0.25rem 1.5rem rgba(var(--v-color-black-rgb), 0.84);
  border-radius: 0.5rem;
  margin: var(--v-spacing-2) 0;
  padding: var(--v-spacing-1) 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
`;

export const Error = styled.li`
  padding: var(--v-spacing-1) 0;
  text-align: center;
`;

export const Suggestion = styled.button`
  appearance: none;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: block;
  font: inherit;
  margin: 0;
  padding: var(--v-spacing-1) var(--v-spacing-2);
  text-align: left;
  width: 100%;

  &:hover,
  &.active {
    background-color: var(--v-color-grey-light);
  }
`;

export const StyledLoading = styled(Loading)`
  position: absolute;
  right: 1rem;
  top: 1.125rem;
`;
