import { baseButtonStyles } from "creators/components/Button/Button";
import DIMENSIONS from "creators/constants/dimensions";
import { h3Styles } from "creators/styling/Heading/Heading";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled.aside`
  align-items: center;
  background: var(--v-color-white);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: var(--v-spacing-12);
  padding: var(--v-spacing-4);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    flex-direction: row;
  }
`;

export const Message = styled.div`
  ${h3Styles}
`;

const linkStyles = css`
  ${baseButtonStyles};
  flex: 0 0 auto;
  text-decoration: none;
`;

export const StyledAnchor = styled.a`
  ${linkStyles}
`;

export const StyledLink = styled(Link)`
  ${linkStyles}
`;
