import { css } from "styled-components";

const icons = css`
  --v-icon-size-base: 1rem; /* 16px */
  --v-icon-size-md: 1.25rem; /* 20px */
  --v-icon-size-lg: 1.5rem; /* 24px */
  --v-icon-size-xl: 2rem; /* 32px */

  --v-icon-avatar-md: 1.5rem; /* 24px */
  --v-icon-avatar-lg: 3rem; /* 48px */
  --v-icon-avatar-upload: 10rem; /* 160px */
  --v-icon-avatar-profile: 16rem; /* 256px */
`;

export default icons;
