import { css } from "styled-components";
import colors from "./colors";
import icons from "./icons";
import sizes from "./sizes";
import spacing from "./spacing";

const customProperties = css`
  ${spacing}
  ${colors}
  ${icons}
  ${sizes}
`;

export default customProperties;
