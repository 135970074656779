import { ReactNode } from "react";
import { HiddenSpan } from "shared/components/VisuallyHidden";
import styled from "styled-components";

interface IIcon {
  className?: string;
  component: React.FC<React.PropsWithChildren>;
  id?: string;
  label?: string | ReactNode;
  tabIndex?: number;
}

const Icon = ({ className, component, id, label, tabIndex }: IIcon) => {
  const Component = component;
  return (
    <span className={className} id={id} tabIndex={tabIndex}>
      {label && <HiddenSpan>{label}</HiddenSpan>}
      <Component />
    </span>
  );
};

export const DefaultStyledIcon = styled(Icon)`
  display: inline-block;
  height: 1rem;
  width: 1rem;
`;

export default DefaultStyledIcon;
