import { useContext } from "react";
import { FeatureContext, IContext } from "shared/components/FeatureProvider";
import Environment from "shared/types/environment";
import getEnvironment from "shared/utils/getEnvironment";

function useFeature<T = boolean>(key: string): T | undefined {
  const { features } = useContext<IContext>(FeatureContext);

  const environment = getEnvironment();

  const value = features[key];

  if (value === undefined && environment !== Environment.Live) {
    // eslint-disable-next-line no-console
    console.warn(`Feature: "${key}" is not defined`);
  }

  return value;
}

export default useFeature;
