import { BLOCK_TYPE } from "shared/BlockBuilder/global";

export const generateBlockID = (
  prefix: string,
  sectionIndex: number,
  blockType?: BLOCK_TYPE,
  blockIndex?: number,
) => {
  return [prefix, sectionIndex, blockType, blockIndex]
    .filter((value) => value !== undefined)
    .join("_");
};

export default generateBlockID;
