import { baseButtonStyles } from "creators/components/Button/Button";
import OnboardingVideoPlayer from "creators/components/OnboardingVideoPlayer";
import DIMENSIONS from "creators/constants/dimensions";
import IDS from "creators/constants/ids";
import VIDEOS from "creators/constants/videos";
import textualize from "creators/utils/textualize";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface IProps {
  linkToOnboarding: boolean;
}

const Heading = styled.h2`
  margin-bottom: var(--v-spacing-12);
`;

const LetsGetStarted = styled(Link)`
  ${baseButtonStyles}
  text-decoration: none;
  display: block;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    display: inline;
  }
`;

const Message = styled.p`
  margin-bottom: var(--v-spacing-12);
`;

const VideoContainer = styled.div`
  align-self: center;
  flex: 1;
  margin: 0 0 var(--v-spacing-12) 0;
  max-height: 19.4375rem;
  max-width: 19.4375rem;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    max-height: 31.75rem;
    max-width: 31.75rem;
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    align-self: auto;
    margin: auto 0 auto var(--v-spacing-16);
  }
`;

const ResponsiveLayout = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    flex-direction: row;
  }

  > div {
    flex: 1;
  }
`;

const EmailVerificationSuccess: React.FC<React.PropsWithChildren<IProps>> = ({
  linkToOnboarding,
}) => (
  <ResponsiveLayout>
    <div>
      <Helmet
        title={textualize("onboarding.verifyEmail.success.title") as string}
      />

      <Heading>{textualize("onboarding.verifyEmail.success.heading")}</Heading>
      <Message>{textualize("onboarding.verifyEmail.success.message")}</Message>
      <LetsGetStarted
        id={IDS.VERIFY_EMAIL.SUCCESS.START}
        to={linkToOnboarding ? "/onboarding" : "/"}
      >
        {textualize("onboarding.verifyEmail.success.start")}
      </LetsGetStarted>
    </div>
    <VideoContainer>
      <OnboardingVideoPlayer
        captions={VIDEOS.EMAIL_VERIFIED.CAPTIONS}
        id={IDS.VERIFY_EMAIL.SUCCESS.VIDEO}
        src={VIDEOS.EMAIL_VERIFIED.SOURCE}
      />
    </VideoContainer>
  </ResponsiveLayout>
);

export default EmailVerificationSuccess;
