import { baseButtonStyles } from "creators/components/Button/Button";
import PageContainer from "creators/components/PageContainer";
import ANIMATIONS from "creators/constants/animations";
import DIMENSIONS from "creators/constants/dimensions";
import { h2Styles } from "creators/styling/Heading/Heading";
import textualize from "creators/utils/textualize";
import { ReactNode, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { VisuallyHiddenHeading } from "shared/components/VisuallyHidden";
import useLottieAnimation, {
  IAnimation,
} from "shared/hooks/useLottieAnimation";
import useReduceMotion from "shared/hooks/useReduceMotion";
import styled from "styled-components";

export interface IProps {
  animation?: IAnimation;
  description?: ReactNode | string;
  id?: string;
  message?: ReactNode | string;
  title?: ReactNode | string;
}

const StyledPageContainer = styled(PageContainer)`
  align-items: center;
  background: var(--v-color-black);
  color: var(--v-color-white);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
`;

export const Animation = styled.div`
  max-height: 35rem;
  max-width: 35rem;
  width: 100%;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    width: 50vw;
  }
`;

const Description = styled.div`
  ${h2Styles};
  text-align: center;
`;

const Message = styled.div`
  text-align: center;
`;

const ApplyToMoreBriefsLink = styled(Link)`
  ${baseButtonStyles}
  display: block;
  margin: 0 auto;
  text-decoration: none;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    display: inline;
  }
`;

const NoResults: React.FC<React.PropsWithChildren<IProps>> = ({
  animation,
  description,
  id,
  message,
  title,
}) => {
  const prefersReducedMotion = useReduceMotion();

  const memoedAnimation = useMemo(
    () => ({
      ...ANIMATIONS.NO_RESULTS_FALLBACK,
      ...animation,
    }),
    [animation],
  );

  const { AnimationComponent } = useLottieAnimation({
    animation: memoedAnimation,
    disabled: prefersReducedMotion,
  });

  return (
    <StyledPageContainer id={id}>
      {title && <Helmet title={title as string} />}
      {title && <VisuallyHiddenHeading>{title}</VisuallyHiddenHeading>}
      <Animation>
        {prefersReducedMotion ? (
          <Description>{description}</Description>
        ) : (
          <div aria-label={description as string} role="img">
            {AnimationComponent}
          </div>
        )}
      </Animation>

      {message && <Message>{message}</Message>}

      <ApplyToMoreBriefsLink to="/">
        {textualize("common.applyToBriefs")}
      </ApplyToMoreBriefsLink>
    </StyledPageContainer>
  );
};

export default NoResults;
