export const snakeToCamel = (s: string) =>
  // Replace any underscore followed by a character with the uppercase version of that character
  s.replace(/(_\w)/g, (matches) => matches[1].toUpperCase());

export const camelCaseKeys = (params?: Record<string, unknown>) =>
  params
    ? Object.fromEntries(
        Object.entries(params).map(([k, v]) => [snakeToCamel(k), v]),
      )
    : undefined;

export default camelCaseKeys;
