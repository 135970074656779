import ModalWrapper from "creators/components/Modal/ModalWrapper";
import textualize from "creators/utils/textualize";
import { ButtonLink, Heading, Message, StyledStandardModal } from "./styles";

export const SessionExpiredModal = () => {
  return (
    <ModalWrapper>
      <StyledStandardModal>
        <Heading>{textualize("modals.sessionExpired.heading")}</Heading>

        <Message>{textualize("modals.sessionExpired.message")}</Message>

        <div>
          <ButtonLink href="/login">
            {textualize("modals.sessionExpired.login")}
          </ButtonLink>
        </div>
      </StyledStandardModal>
    </ModalWrapper>
  );
};

export default SessionExpiredModal;
