import DIMENSIONS from "creators/constants/dimensions";
import styled from "styled-components";

export interface IProps {
  className?: string;
  id?: string;
}

export const Footer = styled.div`
  background: var(--v-color-white);
  border-top: 1px solid var(--v-color-grey-dark);
  bottom: 0;
  padding: var(--v-spacing-4);
  position: sticky;
  width: 100vw;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding: var(--v-spacing-6) var(--v-spacing-16);
    width: 100%;
  }
`;

export const FixedFooter: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  className,
  id,
}) => {
  return (
    <Footer className={className} id={id}>
      {children}
    </Footer>
  );
};

export default FixedFooter;
