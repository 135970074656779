import { gql } from "@apollo/client";
import { ActiveBriefFields } from "creators/fragments/brief";

export const ActiveBriefSubmissionFields = gql`
  fragment ActiveBriefSubmissionFields on Submission {
    id
    brief {
      ...ActiveBriefFields
    }
    state
    submittedAt
  }
  ${ActiveBriefFields}
`;

export const ActiveBriefSubmissionEdgeFields = gql`
  fragment ActiveBriefSubmissionEdgeFields on SubmissionEdge {
    node {
      ...ActiveBriefSubmissionFields
    }
  }
  ${ActiveBriefSubmissionFields}
`;

export const ActiveBriefSubmissionsResponseFields = gql`
  fragment ActiveBriefSubmissionsResponseFields on SubmissionsResponse {
    edges {
      ...ActiveBriefSubmissionEdgeFields
    }
  }
  ${ActiveBriefSubmissionEdgeFields}
`;

export const GET_ACTIVE_BRIEF = gql`
  query GetActiveBrief {
    viewerSubmissions(first: 1, states: [INVITED, INVITE_ACCEPTED]) {
      ...ActiveBriefSubmissionsResponseFields
    }
  }
  ${ActiveBriefSubmissionsResponseFields}
`;

export default {
  GET_ACTIVE_BRIEF,
};
