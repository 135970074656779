import styled, { css } from "styled-components";

export const HiddenLabel = styled.label`
  left: -9999rem;
  opacity: 0;
  position: absolute;
  top: -9999rem;
`;

export const visuallyHiddenStyles = css`
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
`;

export const HiddenSpan = styled.span`
  ${visuallyHiddenStyles}
`;

export const VisuallyHiddenHeading = styled.h1`
  ${visuallyHiddenStyles}
`;

export default {
  HiddenLabel,
  HiddenSpan,
  VisuallyHiddenHeading,
  visuallyHiddenStyles,
};
