import { useQuery } from "@apollo/client";
import { GET_CREATOR_TAXONOMIES } from "creators/queries/GetCreatorTaxonomies";
import {
  CurrencyTaxonFiledsFragment as ICurrencyTaxon,
  GetCreatorTaxonomiesQuery as IGetCreatorTaxonomies,
} from "creators/types/graphql";
import Taxonomy from "creators/types/taxonomy";
import get from "lodash.get";

const useTaxonomy = <T extends Record<string, any> = ICurrencyTaxon>(
  taxonomy: Taxonomy,
): T[] => {
  const { data, loading, error } = useQuery<IGetCreatorTaxonomies>(
    GET_CREATOR_TAXONOMIES,
  );

  if (loading || error) {
    return [];
  }

  return get(data, ["taxonomies", taxonomy, "values"], []) as T[];
};

export default useTaxonomy;
