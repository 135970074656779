import { useQuery } from "@apollo/client";
import ANALYTICS from "creators/constants/analytics";
import { GET_VIEWER } from "creators/queries/GetViewer";
import Session from "creators/services/Session";
import {
  CreatorSkill,
  EmailVerificationStage,
  GetViewerQuery as IGetViewer,
} from "creators/types/graphql";
import onboardingIncomplete from "creators/utils/onboardingIncomplete";
import { useMemo } from "react";
import useAnalytics, { IUser } from "shared/hooks/useAnalytics/useAnalytics";
import getEnvironment from "shared/utils/getEnvironment";
import snakeKeys from "shared/utils/snakeKeys";

enum SkillType {
  CGC = "cgc",
  Remix = "remix",
}

const skillMap = {
  [CreatorSkill.LiveAction]: SkillType.CGC,
  [CreatorSkill.MotionGraphics]: SkillType.Remix,
};

export type CreatorTraits = {
  avatar: string;
  creatorCurrency: string;
  creatorOnboarded: boolean;
  creatorSkill: Array<SkillType>;
  creatorVerified: boolean;
  email: string;
  website: string;
};

export const Analytics = () => {
  const environment = getEnvironment();

  const { data } = useQuery<IGetViewer>(GET_VIEWER);
  const viewer = data?.viewer;

  const user: IUser | undefined = useMemo(() => {
    if (!viewer) {
      return undefined;
    }

    const traits: CreatorTraits = {
      avatar: viewer.avatarURI || "-",
      creatorCurrency: viewer.currency || "-",
      creatorOnboarded: !onboardingIncomplete(viewer),
      creatorSkill: (viewer.skills || []).map((skill) => skillMap[skill]),
      creatorVerified:
        viewer.emailVerificationStage === EmailVerificationStage.Verified,
      email: viewer.email,
      website: viewer.portfolio || "-",
    };

    return {
      disableAnalytics: viewer.disableAnalytics,
      id: viewer.id,
      name: `${viewer.firstName} ${viewer.lastName}`,
      traits: snakeKeys(traits),
    };
  }, [viewer]);

  useAnalytics(ANALYTICS.SEGMENT[environment], Session.isAuthenticated, user);

  return null;
};

export default Analytics;
