import useTaxonomy from "creators/hooks/useTaxonomy";
import Taxonomy from "creators/types/taxonomy";
import getCurrencyIcon from "creators/utils/getCurrencyIcon";

export interface ICurrency {
  id: string;
  label: string;
  symbolCodePoints: number[];
}

const preferredCurrencies = ["GBP", "EUR", "USD"];

export const useCurrencyOptions = () => {
  // Sort currencies
  const currencies = useTaxonomy<ICurrency>(Taxonomy.Currency);

  const sortedPreferredCurrencies = currencies
    .filter((currency) => preferredCurrencies.includes(currency.id))
    .sort(
      (a, b) =>
        preferredCurrencies.indexOf(a.id) - preferredCurrencies.indexOf(b.id),
    );

  const sortedOtherCurrencies = currencies
    .filter((currency) => !preferredCurrencies.includes(currency.id))
    .sort((a, b) => a.id.localeCompare(b.id));

  const sortedCurrencies = [
    ...sortedPreferredCurrencies,
    ...sortedOtherCurrencies,
  ];

  // Convert to dropdown options
  const currencyLabels = sortedCurrencies.map((currency) => ({
    icon: getCurrencyIcon(currency.id),
    label: `${currency.id} - ${currency.label}`,
    value: currency.id,
  }));

  return currencyLabels;
};

export default useCurrencyOptions;
