import { HTMLAttributes, ReactNode } from "react";
import COLORS from "shared/constants/colors";
import styled from "styled-components";

export interface IProps extends HTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  label?: ReactNode | string;
  name: string;
  value: string;
}

export const Icon = styled.span`
  border: 1px solid ${COLORS.WHITE};
  border-radius: 100%;
  display: inline-block;
  height: 1.5rem;
  margin-right: 0.5rem;
  vertical-align: bottom;
  width: 1.5rem;
  transition: all 0.3s ease;

  ::before {
    border-radius: 100%;
    background-color: transparent;
    content: "";
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    margin: 0.125rem;
    transition: all 0.3s ease;
  }
`;

export const Container = styled.label`
  cursor: pointer;
  display: inline-block;
  position: relative;
`;

export const StyledInput = styled.input`
  appearance: none;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;

  :checked + ${Icon} {
    border-color: ${COLORS.GREEN};
  }

  :focus + ${Icon}, :hover + ${Icon} {
    border-color: ${COLORS.WHITE};
    box-shadow: 0 0 0 0.125rem ${COLORS.BLUE};
  }

  :checked + ${Icon}::before {
    background-color: ${COLORS.GREEN};
  }
`;

export const RadioButton = ({
  checked,
  className,
  id,
  label,
  name,
  onChange,
  value,
}: IProps) => (
  <Container className={className}>
    <StyledInput
      checked={checked}
      id={id}
      name={name}
      onChange={onChange}
      type="radio"
      value={value}
    />
    <Icon />
    {label}
  </Container>
);

export default RadioButton;
