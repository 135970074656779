function calculateHasMore(container: Element, child: Element) {
  const outerHeight =
    parseInt(window.getComputedStyle(container).height, 10) + 1; // Add one to account for subpixel values of height
  const innerHeight =
    parseInt(window.getComputedStyle(child).height, 10) - container.scrollTop;

  return outerHeight < innerHeight;
}

export default calculateHasMore;
