import DIMENSIONS from "creators/constants/dimensions";
import Button from "shared/components/Button";
import Icon from "shared/components/Icon";
import styled, { css } from "styled-components";

export const baseContainer = css<{ editable?: boolean }>`
  font-size: 1rem;
  line-height: 150%;
  padding: ${({ editable }) => (editable ? "var(--v-spacing-4)" : "0")};

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    margin: 0 auto;
    max-width: ${({ editable }) => (editable ? "35.75rem" : "33.75rem")};
  }
`;

export const wideContainer = css`
  ${baseContainer}

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    max-width: 45.75rem;
  }
`;

export const SubmitColumns = styled.div`
  align-items: start;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    gap: 2rem;
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    gap: 4rem;
    grid-template-columns: minmax(auto, 21rem) 1fr 1fr;
  }
`;

export const SubmitHeading = styled.h2`
  margin-bottom: var(--v-spacing-4);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    margin-bottom: var(--v-spacing-8);
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    margin-bottom: var(--v-spacing-12);
  }
`;

export const ConfirmButton = styled(Button)`
  background: ${({ theme }) => theme.bg};
  border-color: ${({ theme }) => theme.fg};
  color: ${({ theme }) => theme.fg};
  line-height: 1.5;

  :disabled {
    background: ${({ theme }) => theme.bg};
    color: ${({ theme }) => theme.fg};
    opacity: 0.5;
  }
`;

export const AddButton = styled(ConfirmButton)`
  background: ${({ theme }) => theme.bg};
  display: block;
  margin: var(--v-spacing-4) auto;
`;

export const RemoveButton = styled(AddButton)`
  border-radius: 100%;
  left: auto;
  margin: 0;
  padding: var(--v-spacing-1);
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  transform: none;
`;

export const ButtonIcon = styled(Icon)`
  color: currentColor;
  display: block;
  height: var(--v-icon-size-md);
  width: var(--v-icon-size-md);
`;

export const DragHandleButton = styled(RemoveButton)`
  right: 3rem;
`;

export const MediaGridContainer = styled.div`
  align-items: center;
  display: grid;
  grid-row-gap: 3rem;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    grid-gap: 1rem;
    grid-row-gap: 2rem;
    grid-template-columns: repeat(2, 1fr);

    & > :only-child,
    & > :nth-child(2n-1):last-child {
      grid-column: 1 / span 2;
    }
  }
`;
