const ASPECT_RATIOS = {
  FOUR_BY_FIVE: "4:5",
  FOUR_BY_THREE: "4:3",
  NINE_BY_SIXTEEN: "9:16",
  ONE_BY_ONE: "1:1",
  SIXTEEN_BY_NINE: "16:9",
  TWO_BY_THREE: "2:3",
};

export default ASPECT_RATIOS;
