import Environment from "shared/types/environment";
import getEnvironment from "shared/utils/getEnvironment";

export interface IFeatureOverrides {
  [featureName: string]: boolean;
}

export const SESSION_KEY = "features";

export const getFeatureSessionOverrides = (): IFeatureOverrides => {
  if (getEnvironment() === Environment.Live) {
    // Don't allow overrides on live
    return {};
  }

  const currentOverrides = window.sessionStorage.getItem(SESSION_KEY);

  if (currentOverrides) {
    try {
      return JSON.parse(currentOverrides);
    } catch {
      // Treat as empty
    }
  }

  return {};
};

export default getFeatureSessionOverrides;
