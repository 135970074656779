import Environment from "shared/types/environment";

const INTERCOM = {
  [Environment.Development]: "",
  [Environment.Live]: "nksk6fby",
  [Environment.Staging]: "sl5m24sx",
};

INTERCOM[Environment.Development] = INTERCOM[Environment.Staging];

export default INTERCOM;
