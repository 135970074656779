import { InMemoryCache, InMemoryCacheConfig } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import BRAND_IMAGE_PLACEHOLDER from "shared/constants/brandImagePlaceholder";

export const config: InMemoryCacheConfig = {
  typePolicies: {
    Brand: {
      fields: {
        imageURI: {
          read: (imageURI) => imageURI || BRAND_IMAGE_PLACEHOLDER,
        },
      },
    },
    Query: {
      fields: {
        latestBriefs: relayStylePagination(["type"]),
        liveBriefsV2: relayStylePagination(["type"]),
        viewerPayments: relayStylePagination(),
        viewerSubmissions: relayStylePagination(["states"]),
      },
    },
  },
};

const cache = new InMemoryCache(config);

export default cache;
