import { css } from "styled-components";

const state = css`
  --vui-state-focus-indicator-outer-offset: 0.125rem;
  --vui-state-focus-indicator-thickness: 0.1875rem;

  --vui-state-layer-hover: 8%;
  --vui-state-layer-focus: 12%;
  --vui-state-layer-pressed: 12%;
  --vui-state-layer-dragged: 16%;
`;

export default state;
