import NoResults, {
  IProps as INoResultsProps,
} from "creators/components/NoResults/NoResults";
import ANIMATIONS from "creators/constants/animations";
import textualize from "creators/utils/textualize";

export type IProps = Omit<INoResultsProps, "animation" | "message">;

export const NotFoundError: React.FC<React.PropsWithChildren<IProps>> = (
  props,
) => (
  <NoResults
    animation={ANIMATIONS.NOT_FOUND}
    message={textualize("notFound.body")}
    {...props}
  />
);

export default NotFoundError;
