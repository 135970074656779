import { ReactNode } from "react";
import MODAL from "shared/constants/actions/modal";

interface IPayloadObj {
  payload: {
    component?: ReactNode | null;
    id?: string;
    priority?: boolean;
  };
}

export const addModalAction = (payloadObj: IPayloadObj) => ({
  ...payloadObj,
  type: MODAL.ADD,
});
export const removeModalAction = (payloadObj: IPayloadObj) => ({
  ...payloadObj,
  type: MODAL.REMOVE,
});
export const removeAllModalsAction = () => ({ type: MODAL.REMOVE_ALL });
