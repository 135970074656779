import FONTS from "creators/constants/fonts";
import styled from "styled-components";

export interface IProps {
  htmlFor?: string;
}

export const FormLabel = styled.label<IProps>`
  display: block;
  font-weight: ${FONTS.WEIGHT.BOLD};
`;

export default FormLabel;
