import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  ButtonIcon,
  DragHandleButton,
  RemoveButton,
} from "creators/components/Brief/styles";
import { useMemo } from "react";
import { textualize } from "shared/BlockBuilder/utils";
import BinIcon from "shared/images/icons/blocks/bin.svg";
import DragIcon from "shared/images/icons/blocks/drag.svg";
import { Container, HoverBlocker, OverlayWrapper } from "./styles";

export interface IProps {
  draggableID: string;
  editable: boolean;
  id: string;
  ratio: string;
  removeVideo: () => void;
  uri: string;
}

export const VIMEO_REGEX =
  /(?:(?:ht|f)tps?:)\/\/(?:www.)?vimeo\.com\/(?:((album\/[a-zA-Z0-9]+\/video|(channels|ondemand)\/[a-zA-Z0-9]+|groups\/[a-zA-Z0-9]+\/videos)\/)?)([a-zA-Z0-9]+)/;
export const YOUTUBE_REGEX =
  // eslint-disable-next-line no-useless-escape
  /(?:(?:ht|f)tps?:)\/\/(?:www.)?(?:(?:\youtu.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|vi?\/|watch\?v=|watch\?.&vi?=))((\w|-){11})|youtube\.com\/playlist\?list=)/;

const getPlaformVideoElement = (uri: string) => {
  if (VIMEO_REGEX.test(uri)) {
    const regexMatches = VIMEO_REGEX.exec(uri);
    const id = regexMatches && regexMatches[4];

    return (
      <iframe
        data-videoorigin={uri}
        src={`https://player.vimeo.com/video/${id}`}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        title={uri}
      />
    );
  } else if (YOUTUBE_REGEX.test(uri)) {
    const regexMatches = YOUTUBE_REGEX.exec(uri);
    const originalLink = regexMatches && regexMatches[0];
    const id = regexMatches && regexMatches[1];

    return (
      <iframe
        data-videoorigin={uri}
        src={`https://www.youtube.com/embed/${id}?origin=${originalLink}`}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        title={uri}
      />
    );
  } else {
    return (
      <video
        controls={true}
        loop={true}
        playsInline={true}
        tabIndex={0}
        title={uri}
      >
        <source src={uri} type="video/mp4" />
        {textualize("briefVideo.noVideo")}
      </video>
    );
  }
};

export const VideoWrapper = ({
  draggableID,
  editable,
  id,
  ratio,
  removeVideo,
  uri,
}: IProps) => {
  const { active, attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ disabled: !editable, id: draggableID });

  const dragStyle = useMemo(
    () => ({
      transform: CSS.Translate.toString(transform),
      transition,
    }),
    [transform, transition],
  );

  return (
    <Container aspectRatio={ratio} id={id}>
      {editable ? (
        <OverlayWrapper ref={setNodeRef} style={dragStyle}>
          {getPlaformVideoElement(uri)}

          {/* Make sure hover actions don't leak into any iframes */}
          {active && <HoverBlocker />}

          <DragHandleButton
            {...attributes}
            {...listeners}
            id={`${id}Drag`}
            type="button"
          >
            <ButtonIcon component={DragIcon} label={textualize("ui.drag")} />
          </DragHandleButton>

          <RemoveButton id={`${id}Remove`} onClick={removeVideo} type="button">
            <ButtonIcon component={BinIcon} label={textualize("ui.remove")} />
          </RemoveButton>
        </OverlayWrapper>
      ) : (
        getPlaformVideoElement(uri)
      )}
    </Container>
  );
};

export default VideoWrapper;
