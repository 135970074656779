export default {
  BLACK: "#17171A",
  BLUE: "#3561DC",
  GREEN: "#048446",
  GREEN_ACTIVE: "#14F0A7",
  GREEN_LIGHT: "#C2FFDE", // Only to be used for RTE sections
  GREY: "#C4C4C5",
  GREY_DARK: "#737378",
  GREY_DARKEST: "#2F2F31",
  GREY_DARK_EDITOR: "#29292D",
  GREY_LIGHT: "#EEEEEE",
  PINK: "#F893E8",
  PINK_LIGHT: "#FFE3F5",
  RED: "#E3274E",
  RED_ACTIVE: "#CA0E35",
  RED_LIGHT: "#FFE3F5", // Only to be used for RTE sections
  TAGS: {
    BLUE: {
      FOREGROUND: "#3561DC",
      BACKGROUND: "#EEEBFC",
    },
    GREEN: {
      FOREGROUND: "#048446",
      BACKGROUND: "#E8FDF3",
    },
    RED: {
      FOREGROUND: "#D01F49",
      BACKGROUND: "#F8EAEC",
    },
  },
  WHITE: "#FFFFFF",
  YELLOW: "#F8CA00",
  YELLOW_LIGHT: "#FFFFAC", // Only to be used for RTE sections
};
