import { gql } from "@apollo/client";

export const ViewerFields = gql`
  fragment ViewerFields on Creator {
    avatarURI
    currency
    disableAnalytics
    email
    emailVerificationStage
    externalID
    firstName
    id
    lastName
    location
    payeeName
    portfolio
    skills
    termsAndConditionsAccepted
  }
`;

export const GET_VIEWER = gql`
  query GetViewer {
    viewer {
      ...ViewerFields
    }
  }
  ${ViewerFields}
`;

export default {
  GET_VIEWER,
};
