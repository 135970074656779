import formatUrl from "normalize-url";

export const normalizeUrl = (e: any) => {
  if (!e || !e.target) {
    return e;
  }

  const value = e.target.value;

  if (value === "") {
    return value;
  }

  let result = "";

  try {
    result = formatUrl(value, { stripHash: false, stripWWW: false });
  } catch {
    result = value;
  }

  return result;
};

export default normalizeUrl;
