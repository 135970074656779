import { ReactNode } from "react";
import styled from "styled-components";
import VisuallyHidden from "vui/components/VisuallyHidden";

interface IIcon {
  className?: string;
  component: React.FC<React.PropsWithChildren>;
  id?: string;
  label?: ReactNode;
  tabIndex?: number;
}

const Icon = ({ className, component, id, label, tabIndex }: IIcon) => {
  const Component = component;
  return (
    <span className={className} id={id} tabIndex={tabIndex}>
      {label && <VisuallyHidden as="span">{label}</VisuallyHidden>}
      <Component />
    </span>
  );
};

export const DefaultStyledIcon = styled(Icon)`
  display: inline-block;
  height: 1rem;
  width: 1rem;
`;

export default DefaultStyledIcon;
