import Authenticated from "creators/layouts/Authenticated";
import Unauthenticated from "creators/layouts/Unauthenticated";
import { ViewerContext } from "creators/providers/ViewerProvider";
import Auth from "creators/services/Auth";
import Session from "creators/services/Session";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import Application from "shared/types/application";
import getOrigin from "shared/utils/getOrigin";

function Routes() {
  const { loggedIn } = useContext(ViewerContext);
  const location = useLocation();

  if (loggedIn && location.pathname === "/login/change-password") {
    // Manually log them out to show reset password flow
    Auth.logout(
      `${getOrigin(Application.Creators)}${location.pathname}${
        location.search
      }`,
    );
    Session.clear();

    return null;
  }

  return (
    <>
      {/* Show different routes and layouts depending on whether or not creator is logged in */}
      {loggedIn ? <Authenticated /> : <Unauthenticated />}
    </>
  );
}

export default Routes;
