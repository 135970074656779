import { useQuery } from "@apollo/client";
import BriefsSection from "creators/components/BriefsSection";
import DataError from "creators/components/DataError";
import LiveBriefsPrompt from "creators/components/LiveBriefsPrompt";
import LoadingDelayed from "creators/components/LoadingDelayed";
import NotFoundError from "creators/components/NotFoundError";
import PageContainer from "creators/components/PageContainer";
import DIMENSIONS from "creators/constants/dimensions";
import IDS from "creators/constants/ids";
import useTeaserExistenceCheck from "creators/hooks/useTeaserExistenceCheck";
import BriefTeaserContainer from "creators/pages/BriefTeaserContainer";
import BriefAnalyticsProvider from "creators/providers/BriefAnalyticsProvider";
import { GET_VIEWER } from "creators/queries/GetViewer";
import {
  BriefType,
  CreatorSkill,
  GetViewerQuery as IGetViewer,
} from "creators/types/graphql";
import textualize from "creators/utils/textualize";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const MAX_PER_PAGE = 8;

const Heading = styled.h1`
  margin-bottom: var(--v-spacing-12);
`;

const RemixSection = styled(BriefsSection)`
  margin-bottom: var(--v-spacing-16);
  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    margin-bottom: var(--v-spacing-24);
  }
`;

const StyledPageContainer = styled(PageContainer)`
  background: var(--v-color-grey-light);
`;

function LiveBriefs() {
  const { exists: briefTeaserExists, loading: briefTeaserLoading } =
    useTeaserExistenceCheck();

  const { data, loading, error } = useQuery<IGetViewer>(GET_VIEWER);

  if (briefTeaserLoading || loading) {
    return <LoadingDelayed />;
  }

  if (!briefTeaserExists) {
    return <NotFoundError />;
  }

  if (error) {
    return <DataError error={error} />;
  }

  if (!data?.viewer) {
    return null;
  }

  const { firstName, skills } = data.viewer;

  return (
    <StyledPageContainer id={IDS.LIVE_BRIEFS.PAGE}>
      <Helmet title={textualize("liveBriefs.title") as string} />
      <Heading>
        {textualize("liveBriefs.heading", {
          firstName,
        })}
      </Heading>

      <LiveBriefsPrompt />

      {skills?.includes(CreatorSkill.MotionGraphics) && (
        <RemixSection
          heading={textualize("common.briefType.remix") as string}
          id={IDS.LIVE_BRIEFS.REMIX}
          maxPerPage={MAX_PER_PAGE}
          noResults={textualize("liveBriefs.noResults.remix") as string}
          type={BriefType.MotionGraphics}
        />
      )}

      {skills?.includes(CreatorSkill.LiveAction) && (
        <BriefsSection
          heading={textualize("common.briefType.cgc") as string}
          id={IDS.LIVE_BRIEFS.CGC}
          maxPerPage={MAX_PER_PAGE}
          noResults={textualize("liveBriefs.noResults.cgc") as string}
          type={BriefType.LiveAction}
        />
      )}

      <BriefAnalyticsProvider>
        <BriefTeaserContainer />
      </BriefAnalyticsProvider>
    </StyledPageContainer>
  );
}

export default LiveBriefs;
