import EXTERNAL_LINKS from "creators/constants/externalLinks";
import FONTS from "creators/constants/fonts";
import IDS from "creators/constants/ids";
import DropdownArrow from "creators/images/icons/dropdown-arrow.svg";
import {
  ActiveBriefFieldsFragment as IBrief,
  BriefType,
  SubmissionState,
} from "creators/types/graphql";
import generateActiveBriefSubNav from "creators/utils/generateActiveBriefSubNav";
import scrollToAnchor from "creators/utils/scrollToAnchor";
import textualize from "creators/utils/textualize";
import { MouseEventHandler, useCallback, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Icon from "shared/components/Icon";
import useFeature from "shared/hooks/useFeature";
import styled, { css } from "styled-components";

export interface IProps {
  brief: IBrief;
  previewHash?: string;
  submissionState?: SubmissionState;
}

const linkStyles = css`
  text-decoration: none;

  &.active {
    font-weight: ${FONTS.WEIGHT.BOLD};
  }
`;

const ListHeading = styled.span<{ expanded: boolean }>`
  cursor: pointer;
  ${(props) => props.expanded && `font-weight: ${FONTS.WEIGHT.BOLD}`}
`;

const MainLink = styled(NavLink)`
  ${linkStyles};
`;

const SubLink = styled.a`
  ${linkStyles};
`;

const OuterList = styled.ul`
  font-size: 0.75rem;
  padding: var(--v-spacing-2);

  li {
    margin: var(--v-spacing-4) 0;
  }
`;

const InnerList = styled.ul`
  margin-left: var(--v-spacing-5);
`;

const DropdownIcon = styled(Icon)<{ open: boolean }>`
  height: 0.5rem;
  margin-right: var(--v-spacing-2);
  transform: rotate(${(props) => (props.open ? "0" : "-90deg")});
  width: 0.5rem;

  & svg {
    display: block;
  }
`;

export const ActiveBriefSubNav: React.FC<React.PropsWithChildren<IProps>> = ({
  brief,
  previewHash,
  submissionState,
}) => {
  const releaseFormsEnabled = useFeature("releaseForms");

  const [guidesExpanded, setGuidesExpanded] = useState(false);
  const location = useLocation();

  const links = generateActiveBriefSubNav(brief, {
    previewHash,
    releaseFormsEnabled,
    submissionState,
  });

  const scrollToTop: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      if (location.pathname === e.currentTarget.pathname) {
        window.scrollTo({
          behavior: "smooth",
          top: 0,
        });
      }
    },
    [location.pathname],
  );

  return (
    <OuterList id={IDS.ACTIVE_BRIEF.NAV}>
      {links.map(({ headings, to, name }) => {
        const isExpanded = location.pathname === to && !!headings;

        return (
          <li key={to}>
            <MainLink exact={true} onClick={scrollToTop} to={to}>
              <DropdownIcon component={DropdownArrow} open={isExpanded} />
              {name}
            </MainLink>

            {isExpanded && (
              <InnerList>
                {headings.map(({ hash, name }) => (
                  <li key={hash}>
                    <SubLink href={`#${hash}`} onClick={scrollToAnchor}>
                      {name}
                    </SubLink>
                  </li>
                ))}
              </InnerList>
            )}
          </li>
        );
      })}
      <li>
        <ListHeading
          expanded={guidesExpanded}
          id={IDS.BRIEF.HELPFUL_GUIDES.ID}
          onClick={() => setGuidesExpanded(!guidesExpanded)}
        >
          <DropdownIcon component={DropdownArrow} open={guidesExpanded} />
          {textualize("brief.helpfulGuides.heading")}
        </ListHeading>

        {guidesExpanded && (
          <InnerList>
            {brief.type === BriefType.MotionGraphics && (
              <li>
                <SubLink
                  href={EXTERNAL_LINKS.AE_PROJECT_SETUP}
                  id={IDS.BRIEF.HELPFUL_GUIDES.AE_PROJECT_SETUP}
                  rel="noopener"
                  target="_blank"
                >
                  {textualize("brief.helpfulGuides.aeProjectSetup")}
                </SubLink>
              </li>
            )}
            <li>
              <SubLink
                href={EXTERNAL_LINKS.DELIVERY}
                id={IDS.BRIEF.HELPFUL_GUIDES.FINAL_DELIVERY}
                rel="noopener"
                target="_blank"
              >
                {textualize("brief.helpfulGuides.finalDelivery")}
              </SubLink>
            </li>
          </InnerList>
        )}
      </li>
    </OuterList>
  );
};

export default ActiveBriefSubNav;
