import LoadingIcon from "creators/images/icons/loading.svg";
import textualize from "creators/utils/textualize";
import Icon from "shared/components/Icon";
import styled, { keyframes } from "styled-components";

export interface IProps {
  className?: string;
}

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const SpinningIcon = styled(Icon)`
  animation: ${rotate} 1.5s linear infinite;
  line-height: 1;
`;

export const Loading: React.FC<React.PropsWithChildren<IProps>> = ({
  className,
}) => (
  <SpinningIcon
    className={className}
    component={LoadingIcon}
    label={textualize("common.loading")}
  />
);

export default Loading;
