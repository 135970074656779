import { HTMLAttributes } from "react";
import styled from "styled-components";

export type IProps = HTMLAttributes<HTMLImageElement>;

export const SquareImage = styled.img<IProps>`
  aspect-ratio: 1 / 1;
  border-radius: 0.25rem;
  object-fit: cover;
  width: 100%;
`;

export default SquareImage;
