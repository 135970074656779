import Loading from "creators/components/Loading";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

export const StyledLoading = styled(Loading)<{ visible: boolean }>`
  display: ${({ visible }) => !visible && "none"};
  height: 2rem;
  left: calc(50% - 1rem);
  position: absolute;
  top: calc(50% - 1rem);
  width: 2rem;
`;

export const LoadingDelayed = () => {
  const timer = useRef<number>();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    timer.current = window.setTimeout(() => setVisible(true), 500);

    return () => window.clearTimeout(timer.current);
  }, []);

  return <StyledLoading visible={visible} />;
};

export default LoadingDelayed;
