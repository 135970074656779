import OnboardingVideoPlayer from "creators/components/OnboardingVideoPlayer";
import SkillSelect from "creators/components/SkillSelect";
import ANALYTICS from "creators/constants/analytics";
import DIMENSIONS from "creators/constants/dimensions";
import IDS from "creators/constants/ids";
import { ONBOARDING_INDICES, TOTAL_STEPS } from "creators/constants/onboarding";
import VIDEOS from "creators/constants/videos";
import { getStepForCreator } from "creators/hooks/useOnboardingProgressAnalytics/useOnboardingProgressAnalytics";
import { CreatorSkill } from "creators/types/graphql";
import { IFormValues } from "creators/types/onboarding";
import textualize from "creators/utils/textualize";
import { Form, Formik, FormikErrors, FormikProps } from "formik";
import { useCallback, useState } from "react";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import styled from "styled-components";
import {
  Heading,
  Next,
  ResponsiveLayout,
  StyledSteppedProgress,
  VideoContainer,
} from "../styles";

export interface IProps {
  creatorExternalID: string;
  initialValues: IFormValues;
  onNext: () => void;
  onSubmit: (values: IFormValues) => Promise<void>;
}

const SelectAllThatApply = styled.h3`
  margin-bottom: var(--v-spacing-8);
`;

const StyledSkillSelect = styled(SkillSelect)`
  label {
    @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
      margin-bottom: var(--v-spacing-8);
    }

    &:last-child {
      margin-bottom: var(--v-spacing-12);
    }
  }
`;

export const validation = (values: IFormValues): FormikErrors<IFormValues> => {
  const errors: FormikErrors<IFormValues> = {};

  if (values.skills && values.skills.length < 1) {
    errors.skills = textualize("onboarding.skills.error") as string;
  }

  return errors;
};

const SkillsForm: React.FC<React.PropsWithChildren<IProps>> = ({
  creatorExternalID,
  initialValues,
  onNext,
  onSubmit,
}) => {
  const [videoInteraction, setVideoInteraction] = useState(false);

  const handleSubmit = useCallback(
    async (values: IFormValues) => {
      if (
        JSON.stringify(initialValues.skills) === JSON.stringify(values.skills)
      ) {
        onNext();
      } else {
        try {
          await onSubmit(values);

          if (getStepForCreator(creatorExternalID) === 0) {
            fireAnalyticsEvent(
              ANALYTICS.CATEGORIES.ONBOARDING,
              ANALYTICS.EVENT.ONBOARDING_SKILLS,
              {
                briefTypeCgc: values.skills?.includes(CreatorSkill.LiveAction),
                briefTypeRemix: values.skills?.includes(
                  CreatorSkill.MotionGraphics,
                ),
                videoInteraction,
              },
            );
          }
        } catch {
          // Handled in OnboardingSteps
        }
      }
    },
    [
      creatorExternalID,
      initialValues.skills,
      onNext,
      onSubmit,
      videoInteraction,
    ],
  );

  return (
    <ResponsiveLayout>
      <div>
        <StyledSteppedProgress
          aria-label={textualize("onboarding.progress") as string}
          id={IDS.ONBOARDING.PROGRESS}
          step={ONBOARDING_INDICES.SKILLS}
          totalSteps={TOTAL_STEPS}
        />

        <Heading>{textualize("onboarding.skills.heading")}</Heading>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validation}
        >
          {({ isSubmitting, isValid }: FormikProps<IFormValues>) => (
            <Form id={IDS.ONBOARDING.SKILLS.ID}>
              <SelectAllThatApply>
                {textualize("onboarding.skills.select")}
              </SelectAllThatApply>

              <StyledSkillSelect
                legend={textualize("onboarding.skills.select")}
              />

              <Next
                disabled={isSubmitting || !isValid}
                id={IDS.ONBOARDING.NEXT}
                isLoading={isSubmitting}
                type="submit"
              >
                {textualize("onboarding.next")}
              </Next>
            </Form>
          )}
        </Formik>
      </div>
      <VideoContainer>
        <OnboardingVideoPlayer
          captions={VIDEOS.SKILLS.CAPTIONS}
          id={IDS.ONBOARDING.SKILLS.VIDEO}
          onVideoInteraction={() => setVideoInteraction(true)}
          src={VIDEOS.SKILLS.SOURCE}
        />
      </VideoContainer>
    </ResponsiveLayout>
  );
};

export default SkillsForm;
