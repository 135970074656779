import Button from "creators/components/Button";
import SteppedProgress from "creators/components/SteppedProgress";
import DIMENSIONS from "creators/constants/dimensions";
import linkStyles from "creators/styling/Link";
import { visuallyHiddenStyles } from "shared/components/VisuallyHidden";
import styled from "styled-components";

export const Back = styled.button`
  ${linkStyles}
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-bottom: var(--v-spacing-4);
  padding: 0;
`;

export const Heading = styled.h2`
  margin-bottom: var(--v-spacing-8);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    margin-bottom: var(--v-spacing-12);
  }
`;

export const HiddenLabel = styled.label`
  ${visuallyHiddenStyles};
`;

export const Next = styled(Button)`
  &:disabled {
    cursor: auto;
  }
`;

export const ResponsiveLayout = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 3rem;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    flex-direction: row;
    gap: 4rem;
  }

  > div {
    flex: 1;
  }
`;

export const StyledSteppedProgress = styled(SteppedProgress)`
  margin-bottom: var(--v-spacing-4);
`;

export const VideoContainer = styled.div`
  align-self: center;
  max-height: 19.4375rem;
  max-width: 19.4375rem;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    max-height: 31.75rem;
    max-width: 31.75rem;
  }

  video {
    max-height: 100%;
    position: unset;

    @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
      position: inherit;
    }
  }
`;
