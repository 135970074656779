import Session from "creators/services/Session";
import { createContext, ReactNode, useEffect, useState } from "react";

interface IProps {
  children: ReactNode;
}

interface IContext {
  loggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
}

export const ViewerContext = createContext<IContext>({
  loggedIn: false,
  setLoggedIn: () => null,
});

function ViewerProvider({ children }: IProps) {
  const [loggedIn, setLoggedIn] = useState(Session.isAuthenticated());
  const [viewerValue, setViewerValue] = useState({
    loggedIn,
    setLoggedIn,
  });

  useEffect(() => {
    setViewerValue({ loggedIn, setLoggedIn });
  }, [loggedIn, setLoggedIn]);

  return (
    <ViewerContext.Provider value={viewerValue}>
      {children}
    </ViewerContext.Provider>
  );
}

export default ViewerProvider;
