import GOOGLE_MAPS from "creators/constants/googleMaps";
import getEnvironment from "shared/utils/getEnvironment";

export const loadGoogleMapsApi = () => {
  const API_KEY = GOOGLE_MAPS[getEnvironment()];

  return new Promise<void>((resolve, reject) => {
    if (window.google?.maps?.places) {
      resolve();
    } else {
      window.initGoogleMapsApi = () => {
        delete window.initGoogleMapsApi;
        resolve();
      };
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?callback=initGoogleMapsApi&key=${API_KEY}&language=en&libraries=places&loading=async`;
      script.async = true;
      script.onerror = () => {
        delete window.initGoogleMapsApi;
        reject();
      };

      document.body.appendChild(script);
    }
  });
};

export default loadGoogleMapsApi;
