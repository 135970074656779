import { Lottie } from "@crello/react-lottie";
import useReduceMotion from "shared/hooks/useReduceMotion";
import styled from "styled-components";

export interface IProps {
  className?: string;
  id?: string;
  path: string;
  playing: boolean;
}

export const AnimatedIcon: React.FC<React.PropsWithChildren<IProps>> = ({
  className,
  id,
  path,
  playing = false,
}) => {
  const prefersReducedMotion = useReduceMotion();

  return (
    <span className={className} id={id}>
      <Lottie
        config={{
          autoplay: false,
          loop: true,
          path,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid meet",
          },
        }}
        height="100%"
        playingState={!prefersReducedMotion && playing ? "playing" : "stopped"}
      />
    </span>
  );
};

export const DefaultStyledAnimatedIcon = styled(AnimatedIcon)`
  display: inline-block;
  height: 2rem;
  width: 2rem;
`;

export default DefaultStyledAnimatedIcon;
