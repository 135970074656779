import FormDropdown, {
  IProps as IFormDropdown,
} from "creators/components/forms/FormDropdown/FormDropdown";
import { useField } from "formik";

interface IProps {
  className?: string;
  inputProps?: Omit<IFormDropdown, "onBlur" | "onChange" | "value">;
  name: string;
}

function FormikDropdown({ className, inputProps, ...props }: IProps) {
  const [field, meta, { setTouched, setValue }] = useField<string>(props);

  let error;

  if (meta.touched && meta.error) {
    error = meta.error;
  }

  return (
    <FormDropdown
      {...field}
      {...inputProps}
      className={className}
      error={error}
      onBlur={() => {
        setTouched(true);
      }}
      onChange={(value: string) => {
        setValue(value);
      }}
    />
  );
}

export default FormikDropdown;
