import DIMENSIONS from "creators/constants/dimensions";
import WEIGHTS from "creators/constants/fonts/weight";
import AlarmClockSvg from "creators/images/icons/alarm-clock.svg";
import textualize from "creators/utils/textualize";
import Icon from "shared/components/Icon";
import styled from "styled-components";

const Banner = styled.div`
  align-items: center;
  background: var(--v-color-red-light);
  border-bottom: 1px solid var(--v-color-grey-dark);
  display: flex;
  font-weight: ${WEIGHTS.SEMI_BOLD};
  left: 0;
  margin-bottom: 1px; // fix for border disappearing on scroll in Safari
  padding: var(--v-spacing-2) var(--v-spacing-4) var(--v-spacing-3);
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding: var(--v-spacing-3) var(--v-spacing-16) var(--v-spacing-4);
    position: sticky;
  }
`;

const AlarmClockIcon = styled(Icon).attrs(() => ({
  component: AlarmClockSvg,
}))`
  height: var(--v-spacing-6);
  margin-right: var(--v-spacing-3);
  position: relative;
  width: var(--v-spacing-6);

  > svg {
    display: block;
  }
`;
interface IProps {
  numberOfCreatorApplications: number;
}

function TeaserBanner({ numberOfCreatorApplications }: IProps) {
  if (!numberOfCreatorApplications) {
    return null;
  }

  return (
    <Banner>
      <AlarmClockIcon component={AlarmClockIcon} />
      {textualize("liveBriefs.teaser.banner", {
        count: numberOfCreatorApplications,
      })}
    </Banner>
  );
}

export default TeaserBanner;
