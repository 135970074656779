import OnboardingVideoPlayer from "creators/components/OnboardingVideoPlayer";
import FormikInput from "creators/components/forms/FormikInput";
import EXTERNAL_LINKS from "creators/constants/externalLinks";
import IDS from "creators/constants/ids";
import { ONBOARDING_INDICES, TOTAL_STEPS } from "creators/constants/onboarding";
import VIDEOS from "creators/constants/videos";
import { IFormValues } from "creators/types/onboarding";
import textualize from "creators/utils/textualize";
import { Form, Formik, FormikErrors, FormikProps } from "formik";
import { useCallback, useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import {
  Back,
  Heading,
  HiddenLabel,
  Next as LatestBriefs,
  ResponsiveLayout,
  StyledSteppedProgress,
  VideoContainer,
} from "../styles";

export interface IProps {
  initialValues: IFormValues;
  onBack: () => void;
  onSubmit: (values: IFormValues) => Promise<void>;
}

const PayeeName = styled(FormikInput)`
  margin-bottom: var(--v-spacing-12);

  > div:last-child > p:not(:last-child) {
    margin-bottom: var(--v-spacing-3);
  }

  a {
    font-size: 0.75rem;
  }
`;

export const validation = (values: IFormValues): FormikErrors<IFormValues> => {
  const errors: FormikErrors<IFormValues> = {};

  if (!values.payeeName) {
    errors.payeeName = textualize("onboarding.payeeName.error") as string;
  }

  return errors;
};

const PayeeNameForm: React.FC<React.PropsWithChildren<IProps>> = ({
  initialValues,
  onBack,
  onSubmit,
}) => {
  const [submitted, setSubmitted] = useState(false);

  const navigateToLatestBriefs = useCallback(
    async (values: IFormValues) => {
      if (initialValues.payeeName === values.payeeName) {
        setSubmitted(true);
        return;
      }

      try {
        await onSubmit(values);
        setSubmitted(true);
      } catch {
        setSubmitted(false);
      }
    },
    [initialValues, onSubmit],
  );

  if (submitted) {
    return <Redirect to="/" />;
  }

  return (
    <ResponsiveLayout>
      <div>
        <StyledSteppedProgress
          aria-label={textualize("onboarding.progress") as string}
          id={IDS.ONBOARDING.PROGRESS}
          step={ONBOARDING_INDICES.PAYEE_NAME}
          totalSteps={TOTAL_STEPS}
        />
        <Back id={IDS.ONBOARDING.BACK} onClick={onBack}>
          {textualize("onboarding.back")}
        </Back>

        <Heading>{textualize("onboarding.payeeName.heading")}</Heading>

        <Formik
          initialValues={initialValues}
          onSubmit={navigateToLatestBriefs}
          validate={validation}
        >
          {({ isSubmitting, isValid }: FormikProps<IFormValues>) => (
            <Form id={IDS.ONBOARDING.PAYEE_NAME.ID}>
              <HiddenLabel htmlFor={IDS.ONBOARDING.PAYEE_NAME.INPUT}>
                {textualize("onboarding.payeeName.label")}
              </HiddenLabel>
              <PayeeName
                inputProps={{
                  help: (
                    <>
                      <p>{textualize("onboarding.payeeName.help.use")}</p>
                      <p>{textualize("onboarding.payeeName.help.email")}</p>
                      <a
                        href={EXTERNAL_LINKS.HOW_PAYMENTS_WORK}
                        id={IDS.ONBOARDING.PAYEE_NAME.LINK}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {textualize("onboarding.payeeName.help.link")}
                      </a>
                    </>
                  ),
                  id: IDS.ONBOARDING.PAYEE_NAME.INPUT,
                  required: true,
                }}
                name="payeeName"
              />

              <LatestBriefs
                disabled={isSubmitting || !isValid}
                id={IDS.ONBOARDING.LATEST_BRIEFS}
                isLoading={isSubmitting}
                type="submit"
              >
                {textualize("onboarding.payeeName.latestBriefs")}
              </LatestBriefs>
            </Form>
          )}
        </Formik>
      </div>
      <VideoContainer>
        <OnboardingVideoPlayer
          captions={VIDEOS.PAYEE_NAME.CAPTIONS}
          id={IDS.ONBOARDING.PAYEE_NAME.VIDEO}
          src={VIDEOS.PAYEE_NAME.SOURCE}
        />
      </VideoContainer>
    </ResponsiveLayout>
  );
};

export default PayeeNameForm;
