import { BriefType } from "creators/types/graphql";

export enum AnalyticsBriefType {
  CGC = "CGC",
  Remix = "Remix",
}

export const analyticsBriefTypeMap = {
  [BriefType.LiveAction]: AnalyticsBriefType.CGC,
  [BriefType.MotionGraphics]: AnalyticsBriefType.Remix,
};

export type AnalyticsProps = {
  brandID?: string;
  brandName?: string;
  briefAmount?: number;
  briefCurrency?: string;
  briefID?: string;
  briefName?: string;
  briefState?: string;
  briefType?: AnalyticsBriefType;
};

export interface ISubmissionAnalyticsProps extends AnalyticsProps {
  tosClick?: boolean;
  error?: boolean;
  errorMessage?: string;
}
