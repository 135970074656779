import Mode from "creators/types/mode";
import flattenDeep from "lodash/flattenDeep";
import {
  ChangeEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { SelectInstance } from "react-select";
import { Block, BlockProperties } from "shared/BlockBuilder/global";
import { textualize } from "shared/BlockBuilder/utils";
import { IOption } from "shared/types/components/Dropdown";
import { ThemeProvider } from "styled-components";
import {
  Container,
  Header,
  HeaderDropdown,
  HeaderInput,
  darkEditorTheme,
  darkTheme,
  lightTheme,
} from "./styles";

export interface IProps {
  block: Block;
  children?: React.ReactNode;
  className?: string;
  disableHeader?: boolean;
  handleChange?: (value: { properties: BlockProperties }) => void;
  id: string;
  mode?: Mode;
}

export const OPTIONS: IOption[] = [
  {
    label: textualize("briefSection.title.options.messaging") as string,
    value: textualize("briefSection.title.options.messaging") as string,
  },
  {
    label: textualize("briefSection.title.options.visualsInspo") as string,
    value: textualize("briefSection.title.options.visualsInspo") as string,
  },
  {
    label: textualize("briefSection.title.options.other") as string,
    value: textualize("briefSection.title.options.other") as string,
  },
];

const BriefSection = forwardRef(
  (
    {
      block,
      children,
      disableHeader = false,
      handleChange,
      mode,
      ...rest
    }: IProps,
    ref: React.MutableRefObject<HTMLDivElement>,
  ) => {
    const value: string[] = Array.isArray(block.properties.value)
      ? flattenDeep(block.properties.value) // TODO: Replace with Array.flat once lib compiler updated
      : [block.properties.value].filter((val) => val !== undefined);
    const title = value.join("\n\n");

    const editable = !!handleChange;

    const dropdownInputRef = useRef<SelectInstance<IOption, false>>(null);
    const otherInputRef = useRef<HTMLInputElement>(null);
    const [showOtherInput, setShowOtherInput] = useState(
      !OPTIONS.find(
        (option) =>
          title === option.value &&
          title !== (textualize("briefSection.title.options.other") as string),
      ),
    );
    const prevOtherInputVisibility = useRef(showOtherInput);

    let theme = lightTheme;

    if (mode === Mode.Dark) {
      if (editable) {
        theme = darkEditorTheme;
      } else {
        theme = darkTheme;
      }
    }

    useEffect(() => {
      if (
        showOtherInput === true &&
        prevOtherInputVisibility.current === false
      ) {
        // Only autofocus inputs when coming from dropdown

        otherInputRef.current!.focus();
        prevOtherInputVisibility.current = true;
      } else if (
        showOtherInput === false &&
        prevOtherInputVisibility.current === true
      ) {
        // Only autofocus inputs when coming from input

        dropdownInputRef.current!.focus();
        prevOtherInputVisibility.current = false;
      }
    }, [showOtherInput]);

    const onChange = useCallback(
      (option: IOption) => {
        if (
          option.value ===
          (textualize("briefSection.title.options.other") as string)
        ) {
          // Trigger text input

          handleChange!({
            properties: {
              value: [[""]],
            },
          });

          setShowOtherInput(true);
        } else {
          handleChange!({
            properties: {
              value: [[option.value]],
            },
          });
        }
      },
      [handleChange],
    );

    const onOtherInputChange: ChangeEventHandler<HTMLInputElement> =
      useCallback(
        (e) => {
          const value = e.currentTarget.value;
          handleChange!({
            properties: {
              value: [[value]],
            },
          });

          if (value === "") {
            setShowOtherInput(false);
          }
        },
        [handleChange],
      );

    return (
      <ThemeProvider theme={theme}>
        <Container {...rest} ref={ref}>
          {!disableHeader ? (
            editable ? (
              showOtherInput ? (
                <HeaderInput
                  aria-label={textualize("briefSection.title.label") as string}
                  onChange={onOtherInputChange}
                  placeholder={textualize("briefSection.addTitle") as string}
                  ref={otherInputRef}
                  value={title}
                />
              ) : (
                <HeaderDropdown
                  aria-label={textualize("briefSection.title.label") as string}
                  defaultValue={OPTIONS.find(
                    (option) => option.value === title,
                  )}
                  isSearchable={false}
                  onChange={onChange}
                  options={OPTIONS}
                  placeholder={textualize("briefSection.addTitle")}
                  ref={dropdownInputRef}
                />
              )
            ) : (
              title && <Header>{title}</Header>
            )
          ) : null}
          {children}
        </Container>
      </ThemeProvider>
    );
  },
);

export default BriefSection;
