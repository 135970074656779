import IDS from "creators/constants/ids";
import { CreatorSkill } from "creators/types/graphql";
import textualize from "creators/utils/textualize";
import { useField } from "formik";
import { ReactNode } from "react";
import { Container, Skill, StyledFormError } from "./styles";

interface IProps {
  className?: string;
  legend: ReactNode | string;
}

export const SkillSelect: React.FC<React.PropsWithChildren<IProps>> = ({
  className,
  legend,
}) => {
  // Display validation errors as a group, rather than per-checkbox
  const [, meta] = useField<CreatorSkill[]>("skills");

  let error;

  if (meta.touched && meta.error) {
    error = meta.error;
  }

  return (
    <Container className={className}>
      <legend>{legend}</legend>

      {error && <StyledFormError>{error}</StyledFormError>}

      <Skill
        inputProps={{
          id: IDS.SKILLS.REMIX,
          label: (
            <>
              <strong>{textualize("skills.remix.label")}. </strong>
              {textualize("skills.remix.description")}
            </>
          ),
        }}
        name="skills"
        value={CreatorSkill.MotionGraphics}
      />

      <Skill
        inputProps={{
          id: IDS.SKILLS.CGC,
          label: (
            <>
              <strong>{textualize("skills.cgc.label")}. </strong>
              {textualize("skills.cgc.description")}
            </>
          ),
        }}
        name="skills"
        value={CreatorSkill.LiveAction}
      />
    </Container>
  );
};

export default SkillSelect;
