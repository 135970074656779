import SquareImage from "creators/components/SquareImage";
import DIMENSIONS from "creators/constants/dimensions";
import styled from "styled-components";

export const BriefName = styled.h2`
  font-size: inherit;
  display: inline;
`;

export const Container = styled.header`
  display: grid;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    gap: 1rem;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
  }

  ${SquareImage} {
    margin-bottom: var(--v-spacing-4);
    width: 8rem;

    @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
      margin-bottom: 0;
      width: 7rem;
    }

    @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
      width: 12rem;
    }
  }
`;

export const Header = styled.h1`
  margin-bottom: var(--v-spacing-4);
  word-break: break-word;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    margin-bottom: var(--v-spacing-3);
  }
`;

export const Text = styled.div`
  font-size: 1rem;
  line-height: 150%;
  margin-bottom: var(--v-spacing-4);
  word-break: break-word;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    font-size: 1.5rem;
  }
`;
