import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Analytics, { ITraits } from "shared/services/Analytics/Analytics";
import loadSegment from "shared/utils/loadSegment";

export interface IUser {
  id: string;
  disableAnalytics: boolean;
  name: string;
  traits?: ITraits;
}

export const QUERY_PARAM = "disable_analytics";
export const SESSION_KEY = "disable-analytics";

const isEnabled = () => window.sessionStorage.getItem(SESSION_KEY) !== "true";

const useAnalytics = (
  segmentID: string,
  isAuthenticated: () => boolean,
  user?: IUser,
) => {
  const [initialPageViewSent, setInitialPageViewSent] = useState(false);
  const [forcedDisabled] = useState(
    new URLSearchParams(window.location.search).get(QUERY_PARAM) === "true",
  );

  const history = useHistory<{ silent?: boolean }>();

  // Initialise Segment
  useEffect(() => {
    // Query param will kill analytics for the entire session
    if (!forcedDisabled) {
      loadSegment(segmentID);
    }
  }, [forcedDisabled, segmentID]);

  // Reflect force disable in session key
  useEffect(() => {
    if (forcedDisabled && isEnabled()) {
      window.sessionStorage.setItem(SESSION_KEY, "true");
    }
  }, [forcedDisabled]);

  // Track changes in user disable analytics preference
  useEffect(() => {
    if (user?.disableAnalytics === true) {
      window.sessionStorage.setItem(SESSION_KEY, "true");
    } else {
      if (!forcedDisabled) {
        window.sessionStorage.removeItem(SESSION_KEY);
      }
    }
  }, [forcedDisabled, user]);

  // Identify user
  useEffect(() => {
    if (isEnabled() && user) {
      Analytics.identify(user.id, user.name, user.traits);
    }
  }, [user]);

  // Track initial page view
  useEffect(() => {
    if (
      isEnabled() &&
      (!isAuthenticated() || user?.disableAnalytics === false) &&
      !initialPageViewSent
    ) {
      Analytics.virtualPageView();
    }

    // Only track first page view once
    // Either if they are unauthed, or if they are authed wait until we know they have not disabled analytics
    // Cannot just use `isEnabled` here as session may not be set in time for initial page view
    if (!isAuthenticated() || !!user) {
      setInitialPageViewSent(true);
    }
  }, [initialPageViewSent, isAuthenticated, user]);

  // Listen for further page changes
  useEffect(() => {
    const unlisten = history.listen((location) => {
      const isSilentState = location.state?.silent;

      if (isEnabled() && !isSilentState) {
        Analytics.virtualPageView();
      }
    });
    return unlisten;
  }, [history]);
};

export default useAnalytics;
