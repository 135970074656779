export default {
  CATEGORIES: {
    BRIEFING_FORM: "BriefingForm",
    DASHBOARD: "Dashboard",
    FULL_BRIEF: "FullBrief",
    SHOWREEL: "Showreel",
    TASK_VIEW: "TaskView",
    VIDEO_PLAYER: "VideoPlayer",
    WIZARD: "Wizard",
  },
  EVENTS: {
    NAVIGATED_TO_DASHBOARD: "NavigatedToDashboard",
    LINK_COPIED: "LinkCopied",
    MESSAGING_COPIED: "MessagingCopied",
    MESSAGING_HIGHLIGHTED: "MessagingHighlighted",
    RECENTLY_VIEWED_CLICKED: "RecentlyViewedClicked",
    SLACK_TILE_CLICKED: "SlackTileClicked",
    VIDEO_APPEARANCE_SELECTED: "VideoAppearanceSelected",
    VIDEO_MUTE: "VideoMute",
    VIDEO_PAUSE: "VideoPause",
    VIDEO_PLAY: "VideoPlay",
    VIDEO_UNMUTE: "VideoMute",
    WIZARD_CLOSED: "WizardClosed",
    WIZARD_OPENED: "WizardOpened",
  },
};
