export const rem = 16;

export const PX = {
  BREAKPOINT_LG: 1248,
  BREAKPOINT_MD: 500,
};

export const REM = {
  BREAKPOINT_LG: `${PX.BREAKPOINT_LG / rem}rem`,
  BREAKPOINT_MD: `${PX.BREAKPOINT_MD / rem}rem`,
};

export default REM;
