import { gql } from "@apollo/client";

export const VideoTextBlockFields = gql`
  fragment VideoTextBlockFields on VideoTextBlock {
    description
    length
    lengthPrefix
    platform
    ratio
    summary
    title
  }
`;

export default {
  VideoTextBlockFields,
};
