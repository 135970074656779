import LabelledToggleSwitch, {
  IProps as ILabelledToggleSwitch,
} from "creators/components/LabelledToggleSwitch/LabelledToggleSwitch";
import { useField } from "formik";

interface IProps {
  className?: string;
  inputProps?: Omit<ILabelledToggleSwitch, "onChange" | "value">;
  name: string;
  value?: string;
}

function FormikToggleSwitch({ className, inputProps, ...props }: IProps) {
  const [field] = useField({ ...props, type: "checkbox" });

  return (
    <LabelledToggleSwitch
      {...field}
      {...inputProps}
      checked={field.checked}
      className={className}
    />
  );
}

export default FormikToggleSwitch;
