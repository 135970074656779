import { baseButtonStyles } from "creators/components/Button/Button";
import DIMENSIONS from "creators/constants/dimensions";
import textualize from "creators/utils/textualize";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import FONTS from "shared/constants/fonts";
import VidsyLogo from "shared/images/vidsy-logo.svg";
import styled from "styled-components";

interface IProps {
  children: ReactNode;
}

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  > span {
    font-size: 0.75rem;
    margin: var(--v-spacing-4) 0;
    white-space: break-spaces;

    @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
      font-size: 1.5rem;
      font-weight: ${FONTS.WEIGHT.BOLD};
      margin: var(--v-spacing-12) 0;
    }
  }

  > {
    h1 {
      font-size: 1.5rem;
      line-height: 1.5;

      @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
        font-size: 3rem;
        line-height: 1.25;
      }
    }
  }
`;

const StyledLink = styled(Link)`
  ${baseButtonStyles}
  text-decoration: none;
`;

export const StyledVidsyLogo = styled(VidsyLogo)`
  height: 2.875rem;

  path {
    fill: var(--v-color-white);
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  background: var(--v-color-black);
  color: var(--v-color-white);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  padding: 2.375rem;
`;

const Error = ({ children }: IProps) => (
  <Wrapper>
    <StyledVidsyLogo />
    <Content>{children}</Content>
    <StyledLink to="/">{textualize("error.homepage")}</StyledLink>
  </Wrapper>
);

export default Error;
