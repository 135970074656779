import { History } from "history";
import { Component, ComponentType, ReactNode } from "react";

export interface CaughtErrorInfo {
  componentStack: string;
}

export interface BoundaryProps {
  children: ReactNode;
  FallbackComponent: ComponentType<any>;
  history?: History;
  onError?: (error: Error, info: CaughtErrorInfo, props: PassedProps) => void;
}

export interface PassedProps {
  [p: string]: any;
}

export interface Props extends BoundaryProps, PassedProps {}

export interface State {
  hasErrored: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  state = {
    hasErrored: false,
  };

  public componentDidMount() {
    // Remount the page when navigating away from the one that errored
    const { history } = this.props;

    if (history) {
      history.listen(() => {
        if (this.state.hasErrored) {
          this.setState({
            hasErrored: false,
          });
        }
      });
    }
  }

  componentDidCatch(error: Error, info: CaughtErrorInfo) {
    // TODO: Convert state update to use getDerivedStateFromError
    this.setState({ hasErrored: true });

    if (this.props.onError) {
      const { FallbackComponent, onError, children, ...props } = this.props;

      this.props.onError(error, info, props);
    }
  }

  render() {
    if (this.state.hasErrored) {
      const { FallbackComponent } = this.props;
      return <FallbackComponent />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
