import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { ReactNode, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

export interface IItem {
  id: string;
  uri: string;
}

export interface IProps {
  children: (items: IItem[]) => ReactNode;
  items: IItem[];
  reorderItems: (originalIndex: number, newIndex: number) => void;
}

const DragPlaceholder = styled.div`
  background-color: rgba(var(--v-color-grey-rgb), 0.75);
  height: 100%;
  width: 100%;
`;

export const SortableWrapper = ({
  children,
  items: editorItems,
  reorderItems,
}: IProps) => {
  const [items, setItems] = useState(editorItems);

  const [activeDrag, setActiveDrag] = useState(false);

  useEffect(() => {
    // Keep sorted state in sync with the editor
    setItems(editorItems);
  }, [editorItems]);

  const onDragStart = useCallback(() => {
    setActiveDrag(true);
  }, []);

  const onDragOver = useCallback(({ active, over }: DragOverEvent) => {
    if (over && active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }, []);

  const onDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      setActiveDrag(false);

      if (!over) {
        // No repositioning
        return;
      }

      const originalPosition = editorItems.findIndex(
        (editorItem) => editorItem.id === active.id,
      );
      const newPosition = items.findIndex((item) => item.id === over.id);

      if (originalPosition === newPosition) {
        // No repositioning
        return;
      }

      reorderItems(originalPosition, newPosition);
    },
    [editorItems, items, reorderItems],
  );

  const onDragCancel = useCallback(() => {
    setActiveDrag(false);
  }, []);

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragCancel={onDragCancel}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      onDragStart={onDragStart}
    >
      <SortableContext
        items={items.map((item) => item.id)}
        strategy={() => null}
      >
        {children(items)}
      </SortableContext>

      <DragOverlay dropAnimation={null}>
        {activeDrag && <DragPlaceholder role="none" />}
      </DragOverlay>
    </DndContext>
  );
};

export default SortableWrapper;
