import { RemoveButton } from "creators/components/Brief/styles";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  min-height: 3rem;

  img ~ ${RemoveButton} {
    opacity: 0;
  }

  :hover,
  :focus-within {
    img {
      & ~ ${RemoveButton} {
        opacity: 1;
      }
    }
  }
`;

export const OverlayWrapper = styled.div`
  position: relative;
`;

export const Image = styled.img`
  border-radius: 0.5rem;
  display: block;
  max-height: 50vh;
  max-width: 100%;
`;
