const MARKDOWN = {
  BOLD: "bold",
  DANGER: "danger",
  FEEDBACK: "feedback",
  HEADING: "heading",
  IMAGE: "image",
  ITALIC: "italic",
  LINK: "link",
  LIST_ITEM: "list-item",
  LIST_ITEM_CHILD: "list-item-child",
  MESSAGING: "messaging",
  ORDERED_LIST: "ordered-list",
  PARAGRAPH: "paragraph",
  STRIKETHROUGH: "strikethrough",
  SUCCESS: "success",
  TASK_LIST: "task-list",
  TIMESTAMP: "timestamp",
  UNDERLINE: "underline",
  UNORDERED_LIST: "unordered-list",
  VIDEO: "video",
  WARNING: "warning",
};

export default MARKDOWN;
