import useCountdown from "creators/hooks/useCountdown";
import formatTimeBetween from "creators/utils/formatTimeBetween";
import { ReactNode, useEffect } from "react";

export interface IProps {
  className?: string;
  complete?: ReactNode;
  date: Date;
  id?: string;
  largestOnly?: boolean;
  onComplete?: () => void;
}

export interface ITimeRemaining {
  days: number;
  hours: number;
  minutes: number;
}

export const Countdown: React.FC<React.PropsWithChildren<IProps>> = ({
  className,
  complete: completeComponent,
  date,
  id,
  largestOnly,
  onComplete,
}) => {
  const { complete: complete, timeRemaining } = useCountdown(date);

  useEffect(() => {
    if (complete && onComplete) {
      onComplete();
    }
  }, [complete, onComplete]);

  if (complete && completeComponent) {
    return (
      <span className={className} id={id}>
        {completeComponent}
      </span>
    );
  }

  return (
    <time className={className} dateTime={date.toISOString()} id={id}>
      {formatTimeBetween(timeRemaining!, largestOnly)}
    </time>
  );
};

export default Countdown;
