const processEnvWarning = (variables: string[], prefix?: string) => {
  variables.forEach((variable) => {
    if (process.env[`${prefix ? `${prefix}_` : ""}${variable}`]) {
      // eslint-disable-next-line no-console
      console.warn(
        `${prefix} ${variable} overridden at build time by env var ${prefix ? `${prefix}_` : ""}${variable}`,
      );
    }
  });
};

export default processEnvWarning;
