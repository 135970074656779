import { useQuery } from "@apollo/client";
import ActiveBriefDesktopNav from "creators/components/ActiveBriefDesktopNav";
import GlobalNavItem from "creators/components/GlobalNavItem";
import GlobalNavProfile from "creators/components/GlobalNavProfile";
import ANALYTICS from "creators/constants/analytics";
import EXTERNAL_LINKS from "creators/constants/externalLinks";
import IDS from "creators/constants/ids";
import useCountdown from "creators/hooks/useCountdown";
import AchievementsIcon from "creators/images/animatedIcons/achievements.json";
import ActiveBriefIcon from "creators/images/animatedIcons/active-brief.json";
import CreatorHubIcon from "creators/images/animatedIcons/creator-hub.json";
import HelpIcon from "creators/images/animatedIcons/help.json";
import HomeIcon from "creators/images/animatedIcons/home.json";
import PaymentsIcon from "creators/images/animatedIcons/payments.json";
import PreviousBriefsIcon from "creators/images/animatedIcons/previous-briefs.json";
import SettingsIcon from "creators/images/animatedIcons/settings.json";
import SignOutIcon from "creators/images/animatedIcons/sign-out.json";
import { routes as homeRoutes } from "creators/pages/Home";
import {
  ScreenSize,
  ViewportContext,
} from "creators/providers/ViewportProvider";
import { GET_ACTIVE_BRIEF } from "creators/queries/GetActiveBrief";
import { GET_ACTIVE_BRIEF_PREVIEW } from "creators/queries/GetActiveBriefPreview";
import { GET_VIEWER } from "creators/queries/GetViewer";
import Auth from "creators/services/Auth";
import Session from "creators/services/Session";
import baseScrollbarStyles from "creators/styling/BaseScrollbar";
import {
  EmailVerificationStage,
  GetActiveBriefQuery as IGetActiveBrief,
  GetActiveBriefPreviewQuery as IGetActiveBriefPreview,
  GetViewerQuery as IGetViewer,
  SubmissionState,
} from "creators/types/graphql";
import onboardingIncomplete from "creators/utils/onboardingIncomplete";
import textualize from "creators/utils/textualize";
import flatten from "lodash/flatten";
import { useCallback, useContext, useMemo } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import Scrollable, {
  Mask,
  ScrollableContainer,
} from "shared/components/Scrollable/Scrollable";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import styled from "styled-components";

export interface IProps {
  className?: string;
  id?: string;
}

export interface IPreviewBriefParams {
  id: string;
  previewHash: string;
}

const StyledScrollable = styled(Scrollable)`
  flex: 1 1 auto;

  ${ScrollableContainer} {
    ${baseScrollbarStyles};
    padding-right: 0;
  }

  ${Mask} {
    &::before,
    &::after {
      height: 2rem;
    }

    &::before {
      background-image: linear-gradient(
        to top,
        rgba(var(--v-color-white-rgb), 0),
        rgba(var(--v-color-white-rgb), 1)
      );
    }

    &::after {
      background-image: linear-gradient(
        to bottom,
        rgba(var(--v-color-white-rgb), 0),
        rgba(var(--v-color-white-rgb), 1)
      );
    }
  }
`;

const UserList = styled.ul`
  flex: 0 0 auto;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${StyledScrollable} ul, ${UserList} {
    > li:not(:last-child) {
      margin-bottom: var(--v-spacing-2);
    }
  }
`;

export const GlobalNav: React.FC<React.PropsWithChildren<IProps>> = ({
  className,
  id,
}) => {
  const activeBriefRoute = useRouteMatch("/active-brief");
  const previewBriefRoute = useRouteMatch<IPreviewBriefParams>(
    "/brief/:id(\\d+)/full/:previewHash",
  );

  const { data: viewerData } = useQuery<IGetViewer>(GET_VIEWER);
  const { data: submissionData } = useQuery<IGetActiveBrief>(GET_ACTIVE_BRIEF, {
    skip: !!previewBriefRoute,
  });
  const { data: previewData } = useQuery<IGetActiveBriefPreview>(
    GET_ACTIVE_BRIEF_PREVIEW,
    {
      skip: !previewBriefRoute,
      variables: {
        id: previewBriefRoute?.params.id,
        previewHash: previewBriefRoute?.params.previewHash,
      },
    },
  );

  const size = useContext(ViewportContext);

  const location = useLocation();

  const verifiedAndOnboarded =
    !!viewerData?.viewer &&
    viewerData.viewer.emailVerificationStage ===
      EmailVerificationStage.Verified &&
    !!viewerData.viewer.termsAndConditionsAccepted &&
    !onboardingIncomplete(viewerData.viewer);

  const submission = submissionData?.viewerSubmissions.edges?.[0]?.node;
  const previewBrief = previewData?.brief;
  const hasActiveBrief = !!submission;
  const isActiveBriefPage = !!activeBriefRoute || !!previewBriefRoute;
  const submissionDeadline = useMemo(
    () =>
      submission?.brief.submissionDate
        ? new Date(submission?.brief.submissionDate)
        : undefined,
    [submission?.brief.submissionDate],
  );
  const { complete: deadlinePassed } = useCountdown(submissionDeadline);
  const showBriefNavigation =
    submission?.state !== SubmissionState.InviteDeclined &&
    !submission?.submittedAt &&
    !deadlinePassed;

  const onCreatorHubClick = useCallback(() => {
    fireAnalyticsEvent(
      ANALYTICS.CATEGORIES.NAVIGATION,
      ANALYTICS.EVENT.CREATOR_HUB_VISITED,
    );
  }, []);

  const signOut = useCallback(() => {
    Auth.logout();
    Session.clear();

    fireAnalyticsEvent(
      ANALYTICS.CATEGORIES.CREATOR_AUTHENTICATION,
      ANALYTICS.EVENT.LOGOUT,
      { pageName: location.pathname },
    );
  }, [location]);

  return (
    <List className={className} id={id}>
      <StyledScrollable>
        <ul>
          <li>
            <GlobalNavItem
              icon={HomeIcon}
              id={IDS.NAV.HOME}
              isActive={(match, location) =>
                !!location &&
                flatten(homeRoutes.map((route) => route.path)).includes(
                  location?.pathname,
                )
              }
              label={textualize("nav.home")}
              to="/"
            />
          </li>
          <li>
            <GlobalNavItem
              icon={ActiveBriefIcon}
              id={IDS.NAV.ACTIVE_BRIEF}
              isActive={() => isActiveBriefPage}
              label={textualize("nav.activeBrief")}
              showActiveIcon={hasActiveBrief}
              to={verifiedAndOnboarded ? "/active-brief" : undefined}
            />

            {size === ScreenSize.Desktop &&
              showBriefNavigation &&
              isActiveBriefPage &&
              (previewBrief || submission?.brief) && (
                <ActiveBriefDesktopNav
                  brief={previewBrief || submission!.brief}
                  previewHash={previewBriefRoute?.params.previewHash}
                  submissionState={submission?.state}
                />
              )}
          </li>
          <li>
            <GlobalNavItem
              icon={PreviousBriefsIcon}
              id={IDS.NAV.PREVIOUS_BRIEFS}
              isActive={(match, location) =>
                !!location && location.pathname.includes("/previous-briefs")
              }
              label={textualize("nav.previousBriefs")}
              to={verifiedAndOnboarded ? "/previous-briefs" : undefined}
            />
          </li>
          <li>
            <GlobalNavItem
              icon={PaymentsIcon}
              id={IDS.NAV.PAYMENTS}
              label={textualize("nav.payments")}
              to={verifiedAndOnboarded ? "/payments" : undefined}
            />
          </li>
          <li>
            <GlobalNavItem
              icon={AchievementsIcon}
              id={IDS.NAV.ACHIEVEMENTS}
              label={textualize("nav.achievements")}
              to={verifiedAndOnboarded ? "/achievements" : undefined}
            />
          </li>
          <li>
            <GlobalNavItem
              href={EXTERNAL_LINKS.CREATOR_HUB}
              icon={CreatorHubIcon}
              id={IDS.NAV.CREATOR_HUB}
              label={textualize("nav.creatorHub")}
              onClick={onCreatorHubClick}
              rel="noopener"
              target="_blank"
            />
          </li>
          <li>
            <GlobalNavItem
              href={EXTERNAL_LINKS.HELP}
              icon={HelpIcon}
              id={IDS.NAV.HELP}
              label={textualize("nav.help")}
              rel="noopener"
              target="_blank"
            />
          </li>
        </ul>
      </StyledScrollable>

      {viewerData?.viewer && (
        <UserList>
          <li>
            <GlobalNavProfile
              creatorExternalID={viewerData.viewer.externalID}
              creatorID={viewerData.viewer.id}
              creatorImage={viewerData.viewer.avatarURI || undefined}
              creatorName={viewerData.viewer.firstName}
              verifiedAndOnboarded={verifiedAndOnboarded}
            />
          </li>
          <li>
            <GlobalNavItem
              icon={SettingsIcon}
              id={IDS.NAV.SETTINGS}
              isActive={(match, location) =>
                !!location && location.pathname.includes("/settings")
              }
              label={textualize("nav.settings")}
              to={verifiedAndOnboarded ? "/settings" : undefined}
            />
          </li>
          <li>
            <GlobalNavItem
              icon={SignOutIcon}
              id={IDS.NAV.SIGN_OUT}
              label={textualize("nav.signOut")}
              onClick={signOut}
            />
          </li>
        </UserList>
      )}
    </List>
  );
};

export default GlobalNav;
