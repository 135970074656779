import { gql } from "@apollo/client";

export const SubmissionV2Fields = gql`
  fragment SubmissionV2Fields on SubmissionV2 {
    id
    state
    submittedAt
  }
`;

export default {
  SubmissionV2Fields,
};
