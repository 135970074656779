import { ApolloError } from "@apollo/client";
import { useEffect } from "react";
import Button from "shared/components/Button";
import useLottieAnimation, {
  IAnimation,
} from "shared/hooks/useLottieAnimation";
import useReduceMotion from "shared/hooks/useReduceMotion";
import textualize from "shared/utils/textualize";
import styled from "styled-components";

interface IDataProps {
  error: ApolloError;
}

interface INoResultsProps {
  animation?: IAnimation;
  children?: React.ReactNode;
  customCopy?: string;
  dataType?: string;
  id?: string;
}

const ModuleError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Button} {
    margin-top: 2rem;
  }
`;

const ErrorThrower = ({ error }: IDataProps) => {
  useEffect(() => {
    throw new Error(error.message);
  }, [error.message]);
  return null;
};

const NO_RESULTS_ANIMATION = {
  filename: "internal/empty-state/v1-no-results.json",
  height: "300px",
};

const NoResultsError = ({
  animation = NO_RESULTS_ANIMATION,
  children,
  customCopy,
  dataType = "items",
  id,
}: INoResultsProps) => {
  const prefersReducedMotion = useReduceMotion();
  const { AnimationComponent } = useLottieAnimation({
    animation,
    playingState: prefersReducedMotion ? "stopped" : "playing",
  });

  return (
    <ModuleError id={id}>
      {AnimationComponent}
      {customCopy
        ? customCopy
        : textualize("error.module.noData", {
            dataType,
          })}

      {children}
    </ModuleError>
  );
};

export { ErrorThrower, ModuleError, NoResultsError };
