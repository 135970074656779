import AddVideoForm from "creators/components/Brief/BriefVideo/components/AddVideoForm";
import VideoWrapper from "creators/components/Brief/BriefVideo/components/VideoWrapper";
import {
  Container as FormWrapper,
  OverlayWrapper,
} from "creators/components/Brief/BriefVideo/components/VideoWrapper/styles";
import SortableWrapper, {
  IItem,
} from "creators/components/Brief/SortableWrapper/SortableWrapper";
import {
  AddButton,
  MediaGridContainer,
} from "creators/components/Brief/styles";
import { useCallback, useEffect, useState } from "react";
import { textualize } from "shared/BlockBuilder/utils";
import { v4 as uuidv4 } from "uuid";

export interface IProps {
  addVideo: (path: string, ratio: string) => void;
  editable: boolean;
  editing: boolean;
  id: string;
  ratios: string[];
  removeVideo: (uriIndex: number) => void;
  reorderVideos: (originalIndex: number, newIndex: number) => void;
  setEditing: (editing: boolean) => void;
  uris: string[];
}

export interface IVideoItem extends IItem {
  uri: string;
  ratio: string;
}

const generateItem = (uri: string, ratio: string) => ({
  id: uuidv4(),
  uri,
  ratio,
});

export const VideoGroup = ({
  addVideo,
  editable,
  editing,
  id,
  ratios,
  reorderVideos,
  removeVideo,
  setEditing,
  uris,
}: IProps) => {
  const [items, setItems] = useState(
    uris.map((uri, index) => generateItem(uri, ratios[index])),
  );

  useEffect(() => {
    // Keep a static ID for items across renders
    setItems((oldItems) => {
      const newItems: IVideoItem[] = [];

      const uncheckedItems = [...oldItems];

      for (let i = 0; i < uris.length; i++) {
        const uri = uris[i];

        // Check for a match
        const existingItemIndex = uncheckedItems.findIndex(
          (uncheckedItem) =>
            uncheckedItem.uri === uri && uncheckedItem.ratio === ratios[i],
        );

        if (existingItemIndex !== -1) {
          // Move over existing item
          newItems.push(uncheckedItems[existingItemIndex]);

          // Remove item from further checks (in case of duplicate URIs)
          uncheckedItems.splice(existingItemIndex, 1);
        } else {
          // New item added, update state
          newItems.push(generateItem(uri, ratios[i]));
        }
      }

      return newItems;
    });
  }, [ratios, uris]);

  const handleAddVideoClick = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const handleSubmit = useCallback(
    (path: string, ratio: string) => {
      addVideo(path, ratio);
      setEditing(false);
    },
    [addVideo, setEditing],
  );

  return (
    <>
      <SortableWrapper items={items} reorderItems={reorderVideos}>
        {(items: IVideoItem[]) => (
          <MediaGridContainer>
            {items.map((item, itemIndex) => (
              <VideoWrapper
                draggableID={item.id}
                editable={editable}
                id={`${id}_${itemIndex}`}
                key={item.id}
                ratio={item.ratio}
                removeVideo={() => removeVideo(itemIndex)}
                uri={item.uri}
              />
            ))}

            {editable && editing && (
              <FormWrapper>
                <OverlayWrapper>
                  <AddVideoForm id={id} onSubmit={handleSubmit} />
                </OverlayWrapper>
              </FormWrapper>
            )}
          </MediaGridContainer>
        )}
      </SortableWrapper>

      {editable && !editing && (
        <AddButton
          id={`${id}AddVideo`}
          onClick={handleAddVideoClick}
          type="button"
        >
          {textualize("briefVideo.add")}
        </AddButton>
      )}
    </>
  );
};

export default VideoGroup;
