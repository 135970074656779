import { forwardRef, ReactNode, useEffect, VideoHTMLAttributes } from "react";
import VideoHDRStatus from "shared/types/components/VideoHDRStatus";
import textualize from "shared/utils/textualize";

export interface IProps extends VideoHTMLAttributes<HTMLVideoElement> {
  allowMultipleSources?: boolean;
  autoPlay?: boolean;
  children?: ReactNode;
  className?: string;
  controls?: boolean;
  crossOrigin?: "" | "anonymous" | "use-credentials" | undefined;
  HDRStatus?: VideoHDRStatus;
  id?: string;
  loop?: boolean;
  muted?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  onLoadedData?: (e: React.SyntheticEvent) => void;
  onPlay?: (e: React.SyntheticEvent) => void;
  playsInline?: boolean;
  setHDRStatus?: (status: VideoHDRStatus) => void;
  source: string;
  sourceHDR?: string | null;
}

export const VIDEO_FILE_TYPE = "video/mp4";
export const VIDEO_CODECS = "hvc1.2.4.H120.B0";
export const VIDEO_TYPE = `${VIDEO_FILE_TYPE}; codecs=${VIDEO_CODECS}`;

const Video = forwardRef(
  (props: IProps, ref: React.MutableRefObject<HTMLVideoElement>) => {
    const {
      allowMultipleSources,
      children,
      HDRStatus,
      setHDRStatus,
      source,
      sourceHDR,
      ...rest
    } = props;
    const windowCanPlayHDR = window.matchMedia("(dynamic-range: high)").matches;

    useEffect(() => {
      if (!ref?.current || !setHDRStatus || !HDRStatus) {
        return;
      }

      const isHDRSupported = ref.current.canPlayType(VIDEO_TYPE) === "probably";

      if (HDRStatus !== VideoHDRStatus.HDR) {
        const canPlayHDR = sourceHDR && windowCanPlayHDR && isHDRSupported;
        setHDRStatus(canPlayHDR ? VideoHDRStatus.SDR : VideoHDRStatus.Disabled);
      } else if (!sourceHDR) {
        setHDRStatus(VideoHDRStatus.Disabled);
      }
    }, [ref, setHDRStatus, sourceHDR, windowCanPlayHDR, HDRStatus]);

    useEffect(() => {
      ref?.current.load();
    }, [HDRStatus, ref]);

    return (
      <video {...rest} ref={ref}>
        {allowMultipleSources && sourceHDR && windowCanPlayHDR ? ( // Check if multiple sources are allowed and HDR source is available
          <>
            <source src={sourceHDR} type={VIDEO_TYPE} />
            <source src={source} type={VIDEO_FILE_TYPE} />
          </>
        ) : (
          <source
            // Determine which source to use: HDR if conditions are met, otherwise regular source
            src={
              sourceHDR && HDRStatus === VideoHDRStatus.HDR ? sourceHDR : source
            }
            // Set appropriate type based on source selected
            type={
              sourceHDR && HDRStatus === VideoHDRStatus.HDR
                ? VIDEO_TYPE
                : VIDEO_FILE_TYPE
            }
          />
        )}
        {children}
        {textualize("error.noVideo.body")}
      </video>
    );
  },
);

export default Video;
