import { BlockContainer } from "creators/components/Brief/BlockContainer/BlockContainer";
import styled, { css } from "styled-components";

interface EditableProps {
  editing?: boolean;
  editable?: boolean;
}

export const textStyles = css`
  p,
  ol,
  ul {
    margin-bottom: var(--v-spacing-6);

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol {
    list-style: decimal;
    margin-left: var(--v-spacing-4);
  }

  ul {
    list-style: disc;
    margin-left: var(--v-spacing-4);

    ul {
      list-style: circle;
    }
  }

  li {
    p {
      margin-bottom: var(--v-spacing-2);
    }
  }
`;

export const Container = styled(BlockContainer)<EditableProps>`
  div.ProseMirror {
    background: ${({ theme }) => theme.bg};
    border: none;
    color: ${({ theme }) => theme.fg};
    display: block;
    resize: none;
    width: 100%;
    outline: none;
  }

  ${textStyles}
`;
