import { gql } from "@apollo/client";

export const BrandFields = gql`
  fragment BrandFields on Brand {
    id
    imageURI
    name
  }
`;

export default {
  BrandFields,
};
