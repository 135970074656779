import { useCallback, useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router-dom";

interface IPathTransition {
  from?: string;
  to: string;
}

export interface IProps {
  ignorePathTransitions?: IPathTransition[];
}

export default function ScrollToTop({ ignorePathTransitions = [] }: IProps) {
  const { hash, pathname } = useLocation();

  const [previousPath, setPreviousPath] = useState(pathname);

  const shouldIgnore = useCallback(
    () =>
      ignorePathTransitions.some(
        ({ from, to }) =>
          !!matchPath(previousPath, { path: from, exact: true }) &&
          !!matchPath(pathname, { path: to, exact: true }),
      ),
    [ignorePathTransitions, pathname, previousPath],
  );

  useEffect(() => {
    if (hash === "" && pathname !== previousPath) {
      setPreviousPath(pathname);

      if (!shouldIgnore()) {
        window.scrollTo(0, 0);
      }
    }
  }, [hash, pathname, previousPath, shouldIgnore]);

  return null;
}
