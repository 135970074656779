import { LDClient, LDFlagChangeset } from "launchdarkly-js-client-sdk";
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IFeatures } from "shared/types/flags";
import getFeatureSessionOverrides from "shared/utils/getFeatureSessionOverrides";

export interface IProps {
  children?: ReactNode;
  client: LDClient;
  loadingComponent?: ReactNode;
}

export interface IContext {
  features: IFeatures;
}

export const FeatureContext = createContext<IContext>({
  features: {},
});

export const FeatureProvider = ({
  children,
  client,
  loadingComponent = null,
}: IProps) => {
  const [initialFlagsFetched, setInitialFlagsFetched] = useState(false);
  const [features, setFeatures] = useState<IFeatures>({});

  const subscribeToChanges = useCallback(() => {
    client.on("change", (changes: LDFlagChangeset) => {
      const newFeatures: IFeatures = {};

      for (const featureName in changes) {
        newFeatures[featureName] = changes[featureName].current;
      }

      setFeatures((oldFeatures) => ({
        ...oldFeatures,
        ...newFeatures,
        ...getFeatureSessionOverrides(),
      }));
    });
  }, [client]);

  useEffect(() => {
    const initClient = async () => {
      await client.waitUntilReady();

      const features = client.allFlags();

      setFeatures({
        ...features,
        ...getFeatureSessionOverrides(),
      });

      setInitialFlagsFetched(true);

      subscribeToChanges();
    };

    initClient();
  }, [client, subscribeToChanges]);

  return (
    <FeatureContext.Provider
      value={{
        features,
      }}
    >
      {initialFlagsFetched ? children : loadingComponent}
    </FeatureContext.Provider>
  );
};

export default FeatureProvider;
