import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  ButtonIcon,
  DragHandleButton,
  RemoveButton,
} from "creators/components/Brief/styles";
import { useMemo } from "react";
import { textualize } from "shared/BlockBuilder/utils";
import BinIcon from "shared/images/icons/blocks/bin.svg";
import DragIcon from "shared/images/icons/blocks/drag.svg";
import { Container, Image, OverlayWrapper } from "./styles";

export interface IProps {
  draggableID: string;
  editable: boolean;
  id: string;
  removeImage: () => void;
  uri: string;
}

export const ImageWrapper = ({
  draggableID,
  editable,
  id,
  removeImage,
  uri,
}: IProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ disabled: !editable, id: draggableID });

  const dragStyle = useMemo(
    () => ({
      transform: CSS.Translate.toString(transform),
      transition,
    }),
    [transform, transition],
  );

  return (
    <Container id={id}>
      {editable ? (
        <OverlayWrapper ref={setNodeRef} style={dragStyle}>
          <Image alt="" src={uri} />

          <DragHandleButton
            {...attributes}
            {...listeners}
            id={`${id}Drag`}
            type="button"
          >
            <ButtonIcon component={DragIcon} label={textualize("ui.drag")} />
          </DragHandleButton>

          <RemoveButton id={`${id}Remove`} onClick={removeImage} type="button">
            <ButtonIcon component={BinIcon} label={textualize("ui.remove")} />
          </RemoveButton>
        </OverlayWrapper>
      ) : (
        <Image alt="" src={uri} />
      )}
    </Container>
  );
};

export default ImageWrapper;
