import DIMENSIONS from "creators/constants/dimensions";
import FONTS from "creators/constants/fonts";
import styled, { css } from "styled-components";

export interface IProps {
  className?: string;
  error?: string;
  editable?: boolean;
  editing?: boolean;
  id?: string;
}

const editActive = css`
  border-color: currentColor;
`;

export const errorStyling = css`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: var(--v-color-red);
`;

export const ErrorText = styled.div`
  background: var(--v-color-red);
  border-radius: 0 0 0.25rem 0.25rem;
  color: var(--v-color-white);
  font-size: 0.75rem;
  font-weight: ${FONTS.WEIGHT.BOLD};
  padding: var(--v-spacing-2);
  text-transform: uppercase;
`;

const OuterContainer = styled.div<{ editable?: boolean }>`
  margin: 0 auto;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    max-width: ${({ editable }) => (editable ? "35.75rem" : "33.75rem")};
  }
`;

export const InnerContainer = styled.div<IProps>`
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 150%;
  padding: ${({ editable }) => (editable ? "var(--v-spacing-4)" : "0")};

  ${({ editing }) => (editing ? editActive : "")}

  ${({ error }) => (error ? errorStyling : "")}

  &:hover {
    ${({ editable }) => (editable ? editActive : "")}
  }
`;

export const BlockContainer: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  className,
  ...props
}) => {
  const { error, id } = props;

  return (
    <OuterContainer className={className}>
      <InnerContainer {...props}>{children}</InnerContainer>

      {error && <ErrorText id={`${id}Error`}>{error}</ErrorText>}
    </OuterContainer>
  );
};

export const WideBlockContainer = styled(BlockContainer)`
  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    max-width: 45.75rem;
  }
`;

export default BlockContainer;
