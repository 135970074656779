import debounce from "lodash.debounce";
import { transparentize } from "polished";
import {
  forwardRef,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  WheelEvent,
} from "react";
import COLORS from "shared/constants/colors";
import baseScrollbarStyles from "shared/styling/BaseScrollbar";
import calculateHasMore from "shared/utils/calculateHasMore";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
`;

export const ScrollableContainer = styled.div`
  ${baseScrollbarStyles}
  overflow-y: auto;
  padding-right: 0.5rem;
  width: 100%;
`;

export const Mask = styled.div<{
  hasMore?: boolean;
  isScrolled?: boolean;
}>`
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;

  &::before {
    background-image: linear-gradient(
      ${transparentize(0, COLORS.MID_GREY)},
      ${transparentize(1, COLORS.MID_GREY)}
    );
    content: "";
    display: block;
    height: 4rem;
    left: 0;
    opacity: ${({ isScrolled }) => (isScrolled ? 1 : 0)};
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  &::after {
    background-image: linear-gradient(
      ${transparentize(1, COLORS.MID_GREY)},
      ${transparentize(0, COLORS.MID_GREY)}
    );
    bottom: 0;
    content: "";
    display: block;
    height: 4rem;
    left: 0;
    opacity: ${({ hasMore }) => (hasMore ? 1 : 0)};
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
  }
`;

interface IProps {
  children: ReactNode;
  className?: string;
  onWheel?: (e: WheelEvent) => void;
}

const Scrollable = forwardRef(
  ({ children, className, onWheel }: IProps, externalRef: any) => {
    const internalRef = useRef<HTMLDivElement | null>(null);
    const [isScrolled, setIsScrolled] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    // Use external ref if provided
    const ref = externalRef || internalRef;

    const onScroll = useCallback(() => {
      const container = ref.current;
      if (!container) {
        return;
      }
      const child = container.firstElementChild;
      const hasMore = calculateHasMore(container, child!);
      setHasMore(hasMore);

      if (container?.scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }, [ref]);

    useLayoutEffect(() => {
      onScroll();
    }, [children, onScroll]);

    useLayoutEffect(() => {
      const container = ref.current;
      if (!container) {
        return;
      }

      const debouncedOnScroll = debounce(onScroll, 10);
      container.addEventListener("scroll", debouncedOnScroll);
      window.addEventListener("resize", debouncedOnScroll);

      return () => {
        container.removeEventListener("scroll", debouncedOnScroll);
        window.removeEventListener("resize", debouncedOnScroll);
      };
    }, [onScroll, ref]);

    return (
      <Wrapper className={className}>
        <ScrollableContainer onWheel={onWheel} ref={ref}>
          {children}
        </ScrollableContainer>
        <Mask data-testid="mask" hasMore={hasMore} isScrolled={isScrolled} />
      </Wrapper>
    );
  },
);

export default Scrollable;
