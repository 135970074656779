import IDS from "creators/constants/ids";
import { Disabled, StyledAvatar, StyledNavLink } from "./styles";

export interface IProps {
  creatorExternalID: string;
  creatorID: string;
  creatorImage?: string;
  creatorName: string;
  verifiedAndOnboarded: boolean;
}

export const GlobalNavProfile: React.FC<React.PropsWithChildren<IProps>> = ({
  creatorExternalID,
  creatorID,
  creatorImage,
  creatorName,
  verifiedAndOnboarded,
}) => {
  const content = (
    <>
      <StyledAvatar alt="" avatarID={creatorID} src={creatorImage} />
      <span>{creatorName}</span>
    </>
  );

  if (verifiedAndOnboarded) {
    return (
      <StyledNavLink
        exact={true}
        id={IDS.NAV.PROFILE}
        to={`/profile/${creatorExternalID}`}
      >
        {content}
      </StyledNavLink>
    );
  }

  return <Disabled id={IDS.NAV.PROFILE}>{content}</Disabled>;
};

export default GlobalNavProfile;
