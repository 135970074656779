import { gql } from "@apollo/client";

export const WithdrawBriefApplicationFields = gql`
  fragment WithdrawBriefApplicationFields on SubmissionV2 {
    id
    state
  }
`;

const WITHDRAW_BRIEF_APPLICATION = gql`
  mutation withdrawBriefApplication($input: WithdrawBriefApplicationInput!) {
    withdrawBriefApplication(input: $input) {
      ...WithdrawBriefApplicationFields
    }
  }
  ${WithdrawBriefApplicationFields}
`;

export default WITHDRAW_BRIEF_APPLICATION;
