import Toast from "creators/components/Toast";
import { useContext } from "react";
import ReactDom from "react-dom";
import { animated, useTransition } from "react-spring";
import { ToastContext } from "shared/components/ToastProvider";
import styled from "styled-components";

export const StyledContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  position: fixed;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 100;

  > ${Toast} {
    pointer-events: auto;
  }
`;

export const AnimatedToast = animated(Toast);

const transitionValues = {
  enter: {
    height: "auto",
    marginBottom: "1rem",
    opacity: 1,
    padding: "0.75rem",
    transform: "translateY(0px)",
  },
  from: {
    height: 0,
    marginBottom: "0rem",
    opacity: 0,
    padding: "0.75rem",
    transform: "translateY(-100%)",
  },
  leave: [
    { opacity: 0, transform: "translateY(25%)" },
    { height: 0, marginBottom: "0rem", padding: "0rem" },
  ],
};

export const ToastContainer = () => {
  const { removeToast = () => null, toasts } = useContext(ToastContext);

  const transitions = useTransition(toasts, transitionValues);

  const node = (
    <StyledContainer>
      {transitions((props, item, { key }) => (
        <AnimatedToast
          isWarning={item.isWarning}
          key={key}
          onClick={() => removeToast(item.id)}
          style={{ ...props }}
        >
          {item.message}
        </AnimatedToast>
      ))}
    </StyledContainer>
  );

  return ReactDom.createPortal(
    node,
    document.getElementById("toast-root") as Element,
  );
};

export default ToastContainer;
