import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { addToastAction, removeToastAction } from "shared/actions/toasts";
import TOAST from "shared/constants/actions/toast";
import { IAction } from "shared/types/actions";

export const DEFAULT_TOAST_EXPIRY = 3000;

export type IToastState = IToast[];

export type AddToast = (message: string, isWarning?: boolean) => void;
export type RemoveToast = (id: string) => void;

interface IProps {
  children?: ReactNode;
}

export interface IToast {
  id: string;
  isWarning?: boolean;
  message: string;
}

interface IContext {
  addToast: AddToast;
  toasts: IToast[];
  removeToast?: RemoveToast;
}

export const ToastContext = createContext<IContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addToast: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeToast: () => {},
  toasts: [],
});

export const toastReducer = (state: IToastState, action: IAction) => {
  switch (action.type) {
    case TOAST.ADD: {
      return [
        ...state,
        {
          id: action.payload.id,
          isWarning: action.payload.isWarning,
          message: action.payload.message,
        },
      ];
    }
    case TOAST.REMOVE: {
      return state.filter((toast) => toast.id !== action.payload);
    }
    default:
      break;
  }
  return state;
};

export const ToastProvider = ({ children }: IProps) => {
  const [toastState, dispatch] = useReducer(toastReducer, []);

  const addToast = useCallback(
    (message: string, failure?: boolean) => {
      const id = new Date().toISOString();

      dispatch(addToastAction(id, message, failure));

      setTimeout(() => {
        dispatch(removeToastAction(id));
      }, DEFAULT_TOAST_EXPIRY);
    },
    [dispatch],
  );

  const removeToast = useCallback(
    (id: string) => {
      dispatch(removeToastAction(id));
    },
    [dispatch],
  );

  const [toastContextValue, setToastContextValue] = useState({
    addToast,
    removeToast,
    toasts: toastState,
  });

  useEffect(() => {
    setToastContextValue({
      addToast,
      removeToast,
      toasts: toastState,
    });
  }, [addToast, removeToast, toastState]);

  return (
    <ToastContext.Provider value={toastContextValue}>
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
