import ToggleSwitch, {
  IProps as IToggleSwitchProps,
} from "creators/components/ToggleSwitch/ToggleSwitch";
import { ReactNode } from "react";
import styled from "styled-components";

export interface IProps extends IToggleSwitchProps {
  label?: ReactNode | string;
}

export const Label = styled.label`
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
`;

export const LabelledToggleSwitch: React.FC<
  React.PropsWithChildren<IProps>
> = ({ className, label, ...checkBoxProps }) => (
  <Label className={className}>
    <ToggleSwitch {...checkBoxProps} />
    <div>{label}</div>
  </Label>
);

export default LabelledToggleSwitch;
