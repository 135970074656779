import textualize from "creators/utils/textualize";
import { useState } from "react";
import useLottieAnimation from "shared/hooks/useLottieAnimation";
import styled from "styled-components";
import loggedOutAnimations from "./animations";

interface IProps {
  className?: string;
}

export interface AsideBackground {
  background: string;
}

export const Aside = styled.aside<AsideBackground>`
  background: ${(props) => props.background};
  position: relative;
`;

export const Credit = styled.span`
  background: var(--v-color-white);
  border-radius: 0.5rem;
  bottom: 1rem;
  cursor: pointer;
  padding: var(--v-spacing-1) var(--v-spacing-2);
  position: absolute;
  left: 1rem;
`;

export const LoggedOutAnimation: React.FC<React.PropsWithChildren<IProps>> = ({
  className,
}) => {
  const [animationIndex, setAnimationIndex] = useState(
    Math.floor(Math.random() * loggedOutAnimations.length),
  );
  const {
    animation: { author },
    AnimationComponent: LoginAnimation,
  } = useLottieAnimation({
    animation: loggedOutAnimations[animationIndex],
  });
  const background = loggedOutAnimations[animationIndex].background;

  return (
    <Aside background={background} className={className}>
      {LoginAnimation}

      {author && (
        <Credit
          onClick={() =>
            setAnimationIndex((i) =>
              i === loggedOutAnimations.length - 1 ? 0 : i + 1,
            )
          }
        >
          {textualize("components.animation.credit", { author })}
        </Credit>
      )}
    </Aside>
  );
};

export default LoggedOutAnimation;
