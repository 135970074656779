import Environment from "shared/types/environment";
import processEnvWarning from "shared/utils/processEnvWarning";

processEnvWarning(["CLIENT_SIDE_ID"], "LD");

export const LAUNCH_DARKLY = {
  [Environment.Development]:
    process.env.LD_CLIENT_SIDE_ID || "6228700a056ff4151bd3e364",
  [Environment.Live]: "62287026e6b4e5152ae4abd4",
  [Environment.Staging]:
    process.env.LD_CLIENT_SIDE_ID || "6228701b3d8a811562f8ce32",
};

export default LAUNCH_DARKLY;
