import { useQuery } from "@apollo/client";
import { Auth0UserProfile } from "auth0-js";
import { ViewerContext } from "creators/providers/ViewerProvider";
import { GET_VIEWER } from "creators/queries/GetViewer";
import Session from "creators/services/Session";
import {
  CreatorSkill,
  GetViewerQuery as IGetViewer,
} from "creators/types/graphql";
import textualize from "creators/utils/textualize";
import { jwtDecode } from "jwt-decode";
import { useContext, useEffect } from "react";
import { useIntercom } from "react-use-intercom";

export const Intercom = () => {
  const { loggedIn } = useContext(ViewerContext);

  const { data } = useQuery<IGetViewer>(GET_VIEWER, {
    skip: !loggedIn,
  });

  const SKILLS_MAP = {
    [CreatorSkill.MotionGraphics]: textualize("common.briefType.remix"),
    [CreatorSkill.LiveAction]: textualize("common.briefType.cgc"),
  };

  const email = data?.viewer?.email;
  const name =
    data?.viewer?.firstName &&
    `${data?.viewer.firstName} ${data?.viewer.lastName}`;

  const skills =
    data?.viewer?.skills &&
    data.viewer.skills
      .map((skill) => {
        return SKILLS_MAP[skill];
      })
      .join(", ");

  let userID: string | undefined = undefined;
  const session = Session.getSession();

  if (session) {
    const decoded = jwtDecode<Auth0UserProfile>(session?.idToken as string);
    userID = decoded.sub.split("|")[1];
  }

  const { update } = useIntercom();

  useEffect(() => {
    if (email && name && userID) {
      update({
        customAttributes: {
          Skills: skills,
        },
        email,
        name,
        userId: userID,
      });
    }
  }, [email, name, update, userID, skills]);

  return null;
};

export default Intercom;
