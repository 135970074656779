import ReactSelect, {
  GroupBase,
  Props as ReactSelectProps,
} from "react-select";
import { IOption } from "shared/types/components/Dropdown";
import isOptionDisabled from "shared/utils/isOptionDisabled";
import styled from "styled-components";
import { DropdownIndicator, Option, SingleValue } from "./components";
import {
  controlStyling,
  dropdownStyling,
  indicatorStyling,
  inputStyling,
  optionStyling,
  singleValueStyling,
} from "./styles";

export type IProps = ReactSelectProps<IOption, false, GroupBase<IOption>> & {
  hasError?: boolean;
};

export const UnstyledDropdown: React.FC<React.PropsWithChildren<IProps>> = (
  props,
) => {
  const customComponents = {
    DropdownIndicator,
    Option,
    SingleValue,
  };

  return (
    <ReactSelect
      classNamePrefix="dropdown" // Required in order to style by class
      components={customComponents}
      isOptionDisabled={isOptionDisabled}
      isSearchable={true}
      {...props}
    />
  );
};

export const Dropdown = styled(UnstyledDropdown)<IProps>`
  ${controlStyling}
  ${dropdownStyling}
  ${indicatorStyling}
  ${inputStyling}
  ${optionStyling}
  ${singleValueStyling}
`;

export default Dropdown;
