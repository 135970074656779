import { Redirect, Route, RouteProps } from "react-router-dom";

interface IProps extends RouteProps {
  emailVerified: boolean;
  onboarded: boolean;
  termsAndConditionsAccepted: boolean;
  verificationRequired?: boolean;
}

const GuardedRoute: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  emailVerified,
  exact,
  onboarded,
  path,
  termsAndConditionsAccepted,
  verificationRequired,
}) => {
  let redirectURI = "/onboarding";
  if (!termsAndConditionsAccepted) {
    redirectURI = "/terms-and-conditions";
  } else if (!emailVerified) {
    redirectURI = "/verify-email";
  }

  return (
    <Route exact={exact} path={path}>
      {verificationRequired &&
      (!emailVerified || !termsAndConditionsAccepted || !onboarded) ? (
        <Redirect to={redirectURI} />
      ) : (
        children
      )}
    </Route>
  );
};

export default GuardedRoute;
