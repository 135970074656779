import { IAnimation } from "shared/hooks/useLottieAnimation";

export interface IAnimations {
  [name: string]: IAnimation;
}

export const ANIMATIONS: IAnimations = {
  BRIEF_COMPLETE: {
    author: "Luke O'Brien",
    filename: "creator/v1-brief-complete.json",
  },
  BRIEF_DECLINED: {
    author: "Luke O'Brien",
    filename: "creator/v1-brief-declined.json",
  },
  DEADLINE_PASSED: {
    author: "Luke O'Brien",
    filename: "creator/v1-deadline-passed.json",
  },
  NO_ACTIVE_BRIEF: {
    author: "Luke O'Brien",
    filename: "creator/empty-state/v1-no-active-brief.json",
  },
  NO_RESULTS_FALLBACK: {
    author: "Fabio Scaglione",
    filename: "creator/login/v1-fabio-scaglione.json",
  },
  NO_CGC_BRIEFS: {
    author: "Luke O'Brien",
    filename: "creator/empty-state/v1-no-cgc-briefs.json",
  },
  NO_PAYMENTS: {
    author: "Luke O'Brien",
    filename: "creator/empty-state/v1-no-payments.json",
  },
  NO_PREVIOUS_BRIEFS: {
    author: "Luke O'Brien",
    filename: "creator/empty-state/v1-no-previous-briefs.json",
  },
  NO_REMIX_BRIEFS: {
    author: "Luke O'Brien",
    filename: "creator/empty-state/v1-no-remix-briefs.json",
  },
  NOT_FOUND: {
    author: "Luke O'Brien",
    filename: "creator/error/v1-404.json",
  },
};

export default ANIMATIONS;
