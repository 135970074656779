import { gql } from "@apollo/client";

export const ApplyToBriefFields = gql`
  fragment ApplyToBriefFields on Brief {
    id
    state
    submission {
      id
      state
    }
  }
`;

const APPLY_TO_BRIEF = gql`
  mutation applyToBrief($input: ApplyToBriefInput!) {
    applyToBrief(input: $input) {
      ...ApplyToBriefFields
    }
  }
  ${ApplyToBriefFields}
`;

export default APPLY_TO_BRIEF;
