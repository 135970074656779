import AddImageForm from "creators/components/Brief/BriefImage/components/AddImageForm";
import ImageWrapper from "creators/components/Brief/BriefImage/components/ImageWrapper";
import {
  Container as FormWrapper,
  OverlayWrapper,
} from "creators/components/Brief/BriefImage/components/ImageWrapper/styles";
import SortableWrapper, {
  IItem,
} from "creators/components/Brief/SortableWrapper/SortableWrapper";
import {
  AddButton,
  MediaGridContainer,
} from "creators/components/Brief/styles";
import { useCallback, useEffect, useState } from "react";
import { textualize } from "shared/BlockBuilder/utils";
import { v4 as uuidv4 } from "uuid";

export interface IProps {
  addImage: (path: string) => void;
  editable: boolean;
  editing: boolean;
  id: string;
  removeImage: (uriIndex: number) => void;
  reorderImages: (originalIndex: number, newIndex: number) => void;
  setEditing: (editing: boolean) => void;
  uris: string[];
}

const generateItem = (uri: string) => ({
  id: uuidv4(),
  uri,
});

export const ImageGroup = ({
  addImage,
  editable,
  editing,
  id,
  removeImage,
  reorderImages,
  setEditing,
  uris,
}: IProps) => {
  const [items, setItems] = useState(uris.map((uri) => generateItem(uri)));

  useEffect(() => {
    // Keep a static ID for items across renders
    setItems((oldItems) => {
      const newItems: IItem[] = [];

      const uncheckedItems = [...oldItems];

      for (let i = 0; i < uris.length; i++) {
        const uri = uris[i];
        // Check for a match
        const existingItemIndex = uncheckedItems.findIndex(
          (uncheckedItem) => uncheckedItem.uri === uri,
        );

        if (existingItemIndex !== -1) {
          // Move over existing item
          newItems.push(uncheckedItems[existingItemIndex]);

          // Remove item from further checks (in case of duplicate URIs)
          uncheckedItems.splice(existingItemIndex, 1);
        } else {
          // New item added, update state
          newItems.push(generateItem(uri));
        }
      }

      return newItems;
    });
  }, [uris]);

  const handleAddImageClick = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const handleSubmit = useCallback(
    (path: string) => {
      addImage(path);
      setEditing(false);
    },
    [addImage, setEditing],
  );

  return (
    <>
      <SortableWrapper items={items} reorderItems={reorderImages}>
        {(items: IItem[]) => (
          <MediaGridContainer>
            {items.map((item, itemIndex) => (
              <ImageWrapper
                draggableID={item.id}
                editable={editable}
                id={`${id}_${itemIndex}`}
                key={item.id}
                removeImage={() => removeImage(itemIndex)}
                uri={item.uri}
              />
            ))}

            {editable && editing && (
              <FormWrapper>
                <OverlayWrapper>
                  <AddImageForm id={id} onSubmit={handleSubmit} />
                </OverlayWrapper>
              </FormWrapper>
            )}
          </MediaGridContainer>
        )}
      </SortableWrapper>

      {editable && !editing && (
        <AddButton
          id={`${id}AddImage`}
          onClick={handleAddImageClick}
          type="button"
        >
          {textualize("briefImage.add")}
        </AddButton>
      )}
    </>
  );
};

export default ImageGroup;
