import { Lottie } from "@crello/react-lottie";
import { useEffect, useState } from "react";
import textualize from "shared/utils/textualize";
import styled from "styled-components";

export interface IAnimation {
  author?: string;
  background?: string;
  filename: string;
  height?: string;
}

export interface ILottieAnimation {
  animation: IAnimation;
  disabled?: boolean;
  loop?: boolean;
  playingState?: "playing" | "paused" | "stopped";
}

const LoadError = styled.div`
  display: block;
  margin-bottom: 1rem;
`;

export const ANIMATIONS_LOCATION = "https://client-assets.vidsy.co/animations/";

const useLottieAnimation = (options: ILottieAnimation) => {
  const { animation, disabled, loop, playingState } = options;

  const [animationData, setAnimationData] = useState<string | null>(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (disabled) {
      return;
    }
    const handleError = () => {
      setError(true);
    };

    const fetchAnimation = async () => {
      const path = `${ANIMATIONS_LOCATION}${animation.filename}`;
      const response = await fetch(path, {
        mode: "cors",
      });
      if (!response.ok) {
        throw new Error();
      }
      const data = await response.json();

      setAnimationData(data);
    };

    fetchAnimation().catch(handleError);
  }, [animation, disabled]);

  const AnimationComponent = (
    <>
      {error && <LoadError>{textualize("error.animation.load")}</LoadError>}
      {animation && animationData && (
        <Lottie
          config={{
            animationData,
            autoplay: true,
            loop: loop === undefined ? true : loop,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid meet",
            },
          }}
          height={animation.height || "100%"}
          playingState={playingState || "playing"}
        />
      )}
    </>
  );

  return { animation, AnimationComponent, error };
};

export default useLottieAnimation;
