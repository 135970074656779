import FormLocationInput, {
  IProps as IFormLocationInput,
} from "creators/components/forms/FormLocationInput/FormLocationInput";
import { useField } from "formik";

interface IProps {
  className?: string;
  inputProps?: Omit<IFormLocationInput, "onChange" | "value">;
  name: string;
}

function FormikLocationInput({ className, inputProps, ...props }: IProps) {
  const [field, meta, { setValue }] = useField<string>(props);

  let error;

  if (meta.touched && meta.error) {
    error = meta.error;
  }

  return (
    <FormLocationInput
      {...field}
      {...inputProps}
      className={className}
      error={error}
      onChange={(value: string) => setValue(value)}
    />
  );
}

export default FormikLocationInput;
