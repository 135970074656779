import API from "shared/constants/api";
import { default as DATADOG_DEFAULTS } from "shared/constants/datadog";
import getEnvironment from "shared/utils/getEnvironment";

export const CONFIG = {
  ...DATADOG_DEFAULTS.CONFIG,
  ALLOWED_TRACING_URLS: [
    {
      match: API.CREATORS[getEnvironment()].replace("/query", ""),
      propagatorTypes: ["datadog" as const],
    },
  ],
  APPLICATION_ID: "c1000f05-329e-4130-abb9-b7802cb480c6",
  CLIENT_TOKEN: "pub98d6e25667a7fd57cf1605c27e922fd2",
  SERVICE: "creator.vidsy.co",
};

export const IGNORE_ERRORS = [
  ...DATADOG_DEFAULTS.IGNORE_ERRORS,
  "Object.fromEntries is not a function",
];

export default {
  CONFIG,
  IGNORE_ERRORS,
};
