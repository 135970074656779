import CheckIcon from "creators/images/icons/check.svg";
import { HTMLProps } from "react";
import Icon from "shared/components/Icon";
import styled from "styled-components";

export type IProps = Omit<HTMLProps<HTMLInputElement>, "as" | "ref">;

export const Container = styled.div`
  display: inline-block;
  position: relative;
`;

export const StyledCheckboxIcon = styled(Icon)`
  color: transparent;
  height: var(--v-icon-size-md);
  left: 0.125rem;
  pointer-events: none;
  position: absolute;
  top: 0.125rem;
  width: var(--v-icon-size-md);
  z-index: 1;
`;

export const StyledCheckboxInput = styled.input`
  appearance: none;
  border: 0.125rem solid var(--v-color-black);
  border-radius: 0.5rem;
  cursor: pointer;
  display: inline-block;
  height: 1.5rem;
  margin: 0;
  opacity: 1;
  padding: 0;
  width: 1.5rem;

  :focus,
  :hover {
    box-shadow: inset 0 0 0 0.125rem var(--v-color-black);
    outline: none;
  }

  :checked {
    & {
      background: var(--v-color-green-active);
      border: 0;
    }

    + ${StyledCheckboxIcon} {
      color: var(--v-color-white);
    }
  }

  :disabled {
    border-color: var(--v-color-grey);
    background: var(--v-color-grey-light);
    cursor: default;

    :focus,
    :hover {
      box-shadow: none;
    }

    :checked {
      background: var(--v-color-grey);
    }
  }
`;

export const Checkbox: React.FC<React.PropsWithChildren<IProps>> = ({
  className,
  ...inputProps
}) => (
  <Container className={className}>
    <StyledCheckboxInput {...inputProps} type="checkbox" />
    <StyledCheckboxIcon component={CheckIcon} />
  </Container>
);

export default Checkbox;
