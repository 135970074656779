import FormError from "creators/components/forms/FormError";
import FormikToggleSwitch from "creators/components/forms/FormikToggleSwitch";
import { visuallyHiddenStyles } from "shared/components/VisuallyHidden";
import styled from "styled-components";

export const Container = styled.fieldset`
  > legend {
    ${visuallyHiddenStyles}
  }
`;

export const Skill = styled(FormikToggleSwitch)`
  margin-bottom: var(--v-spacing-4);

  input {
    flex: 0 0 4rem;
  }
`;

export const StyledFormError = styled(FormError)`
  margin-bottom: var(--v-spacing-4);
`;
