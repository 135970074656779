export default {
  LIGHTER_GREY: "#C4C4C4", // TODO: Replace with existing grey
  GREY: "#808080", // Wizard mix-colour-blend: hard-light only
  // Design standardisation colour replacements
  BLACK: "#000000",
  BLUE: "#3561DC",
  DARK_GREY: "#17171A",
  DARKER_GREY: "#0C0C0E",
  GREEN_DARK: "#15A677",
  GREEN: "#14F0A7",
  GREY_2: "#58585A", // Feedback filter, edit and delete buttons bg on hover
  LIGHT_BLUE: "#E3FDFF", // Brief image label only
  LIGHT_GREY: "#303036",
  LIGHT_YELLOW: "#FFFDB6", // Brief image label only
  LIGHTER_BLUE: "#92CBFF", // Quality report bar only
  MID_BLUE: "#42A6FF", // Quality report bar only
  MID_GREY: "#242429",
  OFF_WHITE: "#F7F6F8",
  PINK_DARK: "#F893E8", // Placeholder avatar only
  PINK: "#FFE3f5", // Homepage BG colour only
  PURPLE: "#8738DC",
  RED: "#E3274E",
  WHITE: "#FFFFFF",
  YELLOW: "#F8CA00",
};
