const prefixEuroCountries = (countryCode: string) =>
  ["AT", "CY", "GB", "IE", "MT", "NL"].includes(countryCode);

export const formatNumberToCurrency = (
  num: number,
  countryCode: string,
  currency: string,
) =>
  new Intl.NumberFormat(
    `${
      currency === "EUR" && !prefixEuroCountries(countryCode) ? "de" : "en"
    }-${countryCode.toUpperCase()}`,
    {
      currency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: "currency",
    },
  ).format(Math.round(num));

export default formatNumberToCurrency;
