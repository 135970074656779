import { IProps as ITextInputProps } from "creators/components/TextInput";
import loadGoogleMapsApi from "creators/utils/loadGoogleMapsApi";
import textualize from "creators/utils/textualize";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  Container,
  Error,
  StyledLoading,
  StyledTextInput,
  Suggestion,
  SuggestionsList,
} from "./styles";

export interface IProps extends Omit<ITextInputProps, "onChange"> {
  disabled?: boolean;
  name?: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
  required?: boolean;
}

// onChange from getInputProps does not match
export interface IInputProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

export const getError = (error: string) => {
  if (error === "ZERO_RESULTS") {
    return textualize("components.locationInput.error.noResults");
  }

  return textualize("components.locationInput.error.generic");
};

export const LocationInput = ({
  className,
  value,
  onChange,
  onBlur,
  ...rest
}: IProps) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [googleMapsApiError, setGoogleMapsApiError] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState("");

  useEffect(() => {
    loadGoogleMapsApi()
      .then(() => setLoaded(true))
      .catch(() => {
        setGoogleMapsApiError(true);
      });
  }, []);

  // Keep internal/external state in sync
  useEffect(() => {
    setAutocompleteValue(value);
  }, [value]);

  const handleBlur = useCallback(
    (e: any) => {
      setAutocompleteValue(value);
      setError(undefined);

      onBlur?.(e);
    },
    [onBlur, value],
  );

  const onAutocompleteChange = useCallback(
    (newValue: string) => {
      setAutocompleteValue(newValue);
      setError(undefined);

      if (newValue === "") {
        // Clear field
        onChange("");
      }
    },
    [onChange],
  );

  const onAutocompleteError = useCallback(
    (status: string, clearSuggestions: () => void) => {
      setError(status);

      clearSuggestions();
    },
    [],
  );

  const onAutocompleteSelect = useCallback(
    (address: string, placeID: string) => {
      if (placeID !== null) {
        // Place in dropdown selected, rather than pressing enter in input
        onChange(address);
        setAutocompleteValue(address);
      }
    },
    [onChange],
  );

  if (!loaded || googleMapsApiError) {
    // Text input field to avoid shifting layout when loaded & allow the user to enter location manually
    return (
      <Container className={className}>
        <StyledTextInput
          data-testid="simple-text-input"
          onBlur={onBlur}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value)
          }
          type="text"
          value={value}
          {...rest}
        />
      </Container>
    );
  }
  return (
    <PlacesAutocomplete
      onChange={onAutocompleteChange}
      onError={onAutocompleteError}
      onSelect={onAutocompleteSelect}
      searchOptions={{
        types: ["(cities)"],
      }}
      value={autocompleteValue}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Container className={className}>
          <StyledTextInput
            {...(getInputProps({
              ...rest,
              autoComplete: "off",
              onBlur: handleBlur,
            }) as IInputProps)}
          />

          {loading && <StyledLoading />}

          {(error || suggestions.length > 0) && (
            <SuggestionsList>
              {error ? (
                <Error>{getError(error)}</Error>
              ) : (
                suggestions.map((suggestion) => (
                  <li key={suggestion.placeId}>
                    <Suggestion
                      {...getSuggestionItemProps(suggestion, {
                        className: suggestion.active ? "active" : undefined,
                      })}
                      type="button"
                    >
                      {suggestion.description}
                    </Suggestion>
                  </li>
                ))
              )}
            </SuggestionsList>
          )}
        </Container>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationInput;
