import { SecondaryButton } from "creators/components/Button";
import IncorrectEmailForm from "creators/components/IncorrectEmailForm";
import ResendEmail from "creators/components/ResendEmail";
import IDS from "creators/constants/ids";
import textualize from "creators/utils/textualize";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import {
  Heading,
  Message,
  Subheading,
  UnsuccessfulVerification,
} from "./styles";

interface IProps {
  email: string;
}

interface IVerificationResult {
  verified?: boolean;
}

function EmailUnverified({ email }: IProps) {
  const [showForm, setShowForm] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const location = useLocation<IVerificationResult>();
  const verificationResult = location.state?.verified;

  return (
    <>
      <Helmet
        title={textualize("onboarding.verifyEmail.unverified.title") as string}
      />

      {verificationResult === false && (
        <UnsuccessfulVerification>
          {textualize("onboarding.verifyEmail.unsuccessful")}
        </UnsuccessfulVerification>
      )}

      <Heading>
        {textualize("onboarding.verifyEmail.unverified.heading")}
      </Heading>
      <Message>
        {textualize("onboarding.verifyEmail.unverified.message", {
          email: <strong>{email}</strong>,
        })}
      </Message>

      {!emailSent && (
        <>
          <Subheading>
            {textualize(
              "onboarding.verifyEmail.unverified.resendEmail.heading",
            )}
          </Subheading>
          <ResendEmail email={email} />
        </>
      )}

      <Subheading>
        {textualize("onboarding.verifyEmail.unverified.incorrectEmail.heading")}
      </Subheading>
      {showForm ? (
        <IncorrectEmailForm
          closeForm={() => setShowForm(false)}
          onEmailSent={() => setEmailSent(true)}
        />
      ) : (
        <SecondaryButton
          id={IDS.VERIFY_EMAIL.UNVERIFIED.CHANGE_EMAIL}
          onClick={() => setShowForm(true)}
        >
          {textualize(
            "onboarding.verifyEmail.unverified.incorrectEmail.change",
          )}
        </SecondaryButton>
      )}
    </>
  );
}

export default EmailUnverified;
