import { useQuery } from "@apollo/client";
import DataError from "creators/components/DataError";
import LoadingDelayed from "creators/components/LoadingDelayed";
import OnboardingSteps from "creators/components/OnboardingSteps";
import WindowLayout from "creators/components/WindowLayout";
import IDS from "creators/constants/ids";
import { GET_VIEWER } from "creators/queries/GetViewer";
import {
  EmailVerificationStage,
  GetViewerQuery as IGetViewer,
} from "creators/types/graphql";
import onboardingIncomplete from "creators/utils/onboardingIncomplete";
import textualize from "creators/utils/textualize";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";

const Onboarding = () => {
  const [verificationChecked, setVerificationChecked] = useState(false);

  const { data, loading, error } = useQuery<IGetViewer>(GET_VIEWER);

  if (loading) {
    return <LoadingDelayed />;
  } else if (error) {
    return <DataError error={error} />;
  } else if (!data?.viewer) {
    return null;
  }

  if (data?.viewer && !verificationChecked) {
    if (!data.viewer.termsAndConditionsAccepted) {
      return <Redirect to="/terms-and-conditions" />;
    } else if (
      data.viewer.emailVerificationStage !== EmailVerificationStage.Verified
    ) {
      return <Redirect to="/verify-email" />;
    } else if (!onboardingIncomplete(data.viewer)) {
      // Handle direct urls to the onboarding change
      return <Redirect to="/" />;
    } else {
      setVerificationChecked(true);
    }
  }

  const { currency, firstName, location, payeeName, portfolio, skills } =
    data.viewer;

  return (
    <WindowLayout firstName={firstName} id={IDS.ONBOARDING.PAGE}>
      <Helmet
        title={textualize("onboarding.welcome", { firstName }) as string}
      />
      <OnboardingSteps
        creatorExternalID={data.viewer.externalID}
        currency={currency || ""}
        location={location || ""}
        payeeName={payeeName || ""}
        portfolio={portfolio || ""}
        skills={skills || []}
      />
    </WindowLayout>
  );
};

export default Onboarding;
