import { ApolloClient } from "@apollo/client";
import { RumEvent } from "@datadog/browser-rum";
import isErrorIgnored from "shared/utils/isErrorIgnored";

const getDatadogBeforeSend =
  (client: ApolloClient<unknown>, settings: any) => (event: RumEvent) => {
    if (event.type === "error") {
      if (isErrorIgnored(event.error, settings.IGNORE_ERRORS)) {
        return false;
      }

      event.context = {
        ...event.context,
        apolloCache: client.extract(),
      };
    }

    event.view.url = event.view.url.replace(
      /access_token=[^&]*/,
      "access_token=REDACTED",
    );

    event.view.url = event.view.url.replace(
      /id_token=[^&]*/,
      "id_token=REDACTED",
    );

    return true;
  };

export default getDatadogBeforeSend;
