import brief from "./brief.yml";
import enterPin from "./enterPin.yml";
import error from "./error.yml";
import feedback from "./feedback.yml";
import general from "./general.yml";
import nav from "./nav.yml";
import player from "./player.yml";
import preventNavigation from "./preventNavigation.yml";
import textEditor from "./textEditor.yml";
import videos from "./videos.yml";
import wizard from "./wizard.yml";

export const copy = {
  brief,
  enterPin,
  error,
  feedback,
  general,
  nav,
  player,
  preventNavigation,
  textEditor,
  videos,
  wizard,
};

export default copy;
