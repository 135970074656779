import TOAST from "shared/constants/actions/toast";

export const addToastAction = (
  id: string,
  message: string,
  isWarning?: boolean,
) => ({
  payload: { id, isWarning, message },
  type: TOAST.ADD,
});

export const removeToastAction = (id: string) => ({
  payload: id,
  type: TOAST.REMOVE,
});
