import textualize from "creators/utils/textualize";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import VidsyLogo from "shared/images/vidsy-logo.svg";
import styled from "styled-components";

interface IProps {
  children: ReactNode;
}

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  > span {
    font-size: var(--vui-typescale-title-large-font);
    margin: var(--vui-spacing-4) 0;
    white-space: break-spaces;
  }
`;

export const StyledVidsyLogo = styled(VidsyLogo)`
  align-self: start;
  height: 2.875rem;
  justify-self: center;

  path {
    fill: rgb(var(--vui-color-on-background-dark));
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  background: rgb(var(--vui-color-background-dark));
  color: rgb(var(--vui-color-on-background-dark));
  display: grid;
  grid-template-rows: auto 1fr auto;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: var(--vui-spacing-11);
  text-align: center;
`;

const Error = ({ children }: IProps) => (
  <Wrapper>
    <StyledVidsyLogo />
    <Content>{children}</Content>
    <Link to="/">{textualize("vuiError.homepage")}</Link>
  </Wrapper>
);

export default Error;
