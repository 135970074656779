import Input from "shared/components/Input";
import styled from "styled-components";

export const FieldContainer = styled.div`
  margin-bottom: var(--v-spacing-4);
`;

export const ImagePathLabel = styled.label`
  display: block;
  margin-bottom: var(--v-spacing-2);
`;

export const ImagePathInput = styled(Input)`
  border: 1px solid var(--v-color-black);
  display: block;
  margin-bottom: var(--v-spacing-4);
  width: 100%;
`;
