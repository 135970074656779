import OnboardingVideoPlayer from "creators/components/OnboardingVideoPlayer";
import CurrencyDropdown from "creators/components/forms/CurrencyDropdown";
import ANALYTICS from "creators/constants/analytics";
import EXTERNAL_LINKS from "creators/constants/externalLinks";
import IDS from "creators/constants/ids";
import { ONBOARDING_INDICES, TOTAL_STEPS } from "creators/constants/onboarding";
import VIDEOS from "creators/constants/videos";
import { getStepForCreator } from "creators/hooks/useOnboardingProgressAnalytics";
import { IFormValues } from "creators/types/onboarding";
import textualize from "creators/utils/textualize";
import { Form, Formik, FormikErrors, FormikProps } from "formik";
import { useCallback, useState } from "react";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import styled from "styled-components";
import {
  Back,
  Heading,
  HiddenLabel,
  Next,
  ResponsiveLayout,
  StyledSteppedProgress,
  VideoContainer,
} from "../styles";

export interface IProps {
  creatorExternalID: string;
  initialValues: IFormValues;
  onBack: () => void;
  onNext: () => void;
  onSubmit: (values: IFormValues) => Promise<void>;
}

const StyledCurrencyDropdown = styled(CurrencyDropdown)`
  margin-bottom: var(--v-spacing-12);

  a {
    font-size: 0.75rem;
  }
`;

export const validation = (values: IFormValues): FormikErrors<IFormValues> => {
  const errors: FormikErrors<IFormValues> = {};

  if (!values.currency) {
    errors.currency = textualize("onboarding.currency.error") as string;
  }

  return errors;
};

const CurrencyForm: React.FC<React.PropsWithChildren<IProps>> = ({
  creatorExternalID,
  initialValues,
  onBack,
  onNext,
  onSubmit,
}) => {
  const [videoInteraction, setVideoInteraction] = useState(false);
  const [currencyArticleClick, setCurrencyArticleClick] = useState(false);

  const handleSubmit = useCallback(
    async (values: IFormValues) => {
      if (initialValues.currency === values.currency) {
        onNext();
      } else {
        try {
          await onSubmit(values);

          if (getStepForCreator(creatorExternalID) === 3) {
            fireAnalyticsEvent(
              ANALYTICS.CATEGORIES.ONBOARDING,
              ANALYTICS.EVENT.ONBOARDING_CURRENCY,
              {
                currencyArticleClick,
                videoInteraction,
              },
            );
          }
        } catch {
          // Handled in OnboardingSteps
        }
      }
    },
    [
      creatorExternalID,
      currencyArticleClick,
      initialValues.currency,
      onNext,
      onSubmit,
      videoInteraction,
    ],
  );

  return (
    <ResponsiveLayout>
      <div>
        <StyledSteppedProgress
          aria-label={textualize("onboarding.progress") as string}
          id={IDS.ONBOARDING.PROGRESS}
          step={ONBOARDING_INDICES.CURRENCY}
          totalSteps={TOTAL_STEPS}
        />
        <Back id={IDS.ONBOARDING.BACK} onClick={onBack}>
          {textualize("onboarding.back")}
        </Back>

        <Heading>{textualize("onboarding.currency.heading")}</Heading>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validation}
        >
          {({ isSubmitting, isValid }: FormikProps<IFormValues>) => (
            <Form id={IDS.ONBOARDING.CURRENCY.ID}>
              <HiddenLabel htmlFor={IDS.ONBOARDING.CURRENCY.INPUT}>
                {textualize("onboarding.currency.label")}
              </HiddenLabel>
              <StyledCurrencyDropdown
                help={
                  textualize("onboarding.currency.help", {
                    link: (
                      <a
                        href={EXTERNAL_LINKS.PAYMENT_CURRENCY}
                        id={IDS.ONBOARDING.CURRENCY.LINK}
                        onClick={() => setCurrencyArticleClick(true)}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {textualize("onboarding.currency.link")}
                      </a>
                    ),
                  }) as string
                }
                id={IDS.ONBOARDING.CURRENCY.INPUT}
              />

              <Next
                disabled={isSubmitting || !isValid}
                id={IDS.ONBOARDING.NEXT}
                isLoading={isSubmitting}
                type="submit"
              >
                {textualize("onboarding.next")}
              </Next>
            </Form>
          )}
        </Formik>
      </div>
      <VideoContainer>
        <OnboardingVideoPlayer
          captions={VIDEOS.CURRENCY.CAPTIONS}
          id={IDS.ONBOARDING.CURRENCY.VIDEO}
          onVideoInteraction={() => setVideoInteraction(true)}
          src={VIDEOS.CURRENCY.SOURCE}
        />
      </VideoContainer>
    </ResponsiveLayout>
  );
};

export default CurrencyForm;
