const KEYBOARD_SHORTCUTS = {
  ARROW_DOWN: "ArrowDown",
  ARROW_LEFT: "ArrowLeft",
  ARROW_RIGHT: "ArrowRight",
  ARROW_UP: "ArrowUp",
  BACKSPACE: "Backspace",
  ENTER: "Enter",
  ESCAPE: "Escape",
  KEY_J: "j",
  KEY_K: "k",
  KEY_M: "m",
  TAB: "Tab",
  SHIFT_ARROW_LEFT: "Shift+ArrowLeft",
  SHIFT_ARROW_RIGHT: "Shift+ArrowRight",
  SHIFT_SPACE: "Shift+ ",
  SPACE: " ",
};

export default KEYBOARD_SHORTCUTS;
