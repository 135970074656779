import COLORS from "creators/constants/colors";

export default [
  {
    author: "Fabio Scaglione",
    background: COLORS.BLACK,
    filename: "creator/login/v1-fabio-scaglione.json",
  },
  {
    author: "Ronnie Brivinskas",
    background: COLORS.BLACK,
    filename: "creator/login/v1-ronnie-brivinskas.json",
  },
  {
    author: "Dumarys Espaillat",
    background: COLORS.WHITE,
    filename: "creator/login/v1-dumarys-espaillat.json",
  },
];
