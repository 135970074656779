export const ONBOARDING_INDICES = {
  SKILLS: 1,
  PORTFOLIO: 2,
  LOCATION: 3,
  CURRENCY: 4,
  PAYEE_NAME: 5,
};

export const TOTAL_STEPS = Object.keys(ONBOARDING_INDICES).length;

export default {
  ONBOARDING_INDICES,
  TOTAL_STEPS,
};
