import { Widget } from "@typeform/embed-react";
import BriefHeader from "creators/components/Brief/BriefHeader";
import BriefSection from "creators/components/Brief/BriefSection";
import NavigationButton from "creators/components/NavigationButton";
import { Container as StatContainer } from "creators/components/Stat/Stat";
import TeaserSummary from "creators/components/TeaserSummary";
import DIMENSIONS from "creators/constants/dimensions";
import FONTS from "creators/constants/fonts";
import { h2Styles } from "creators/styling/Heading/Heading";
import styled, { css } from "styled-components";

const richTextEditorStyles = css`
  display: block;

  aside {
    padding: var(--v-spacing-2);

    &.danger {
      background-color: var(--v-color-red-light);
    }
    &.success {
      background-color: var(--v-color-green-light);
    }
    &.warning {
      background-color: var(--v-color-yellow-light);
    }
  }

  h1 {
    ${h2Styles}
  }

  iframe,
  img {
    max-width: 100%;
  }

  p {
    font-size: 1rem;

    &.centered {
      text-align: center;
    }
  }

  ol {
    list-style: decimal;
    margin-left: var(--v-spacing-6);
  }

  ul {
    list-style: disc;
    margin-left: var(--v-spacing-6);
  }
`;

export const Introduction = styled.div`
  ${richTextEditorStyles};
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: var(--v-spacing-2);
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    width: 50%;
  }
`;

export const CGCIntroduction = styled.div`
  ${richTextEditorStyles};
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: var(--v-spacing-2);
  }
`;

export const ResponsiveLayout = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    flex-direction: row;
    gap: 4rem;
  }
`;

export const CGCResponsiveLayout = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    flex-direction: row;
    gap: 4rem;
  }
`;

export const StyledBriefHeader = styled(BriefHeader)`
  margin-bottom: var(--v-spacing-12);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    margin-bottom: var(--v-spacing-24);
  }
`;

export const StyledCGCTeaserSummary = styled(TeaserSummary)`
  border: 1px solid var(--v-color-pink-light);
  border-radius: 0.5rem;
  box-sizing: border-box;
  columns: 1;
  column-gap: 1.25rem;
  padding: var(--v-spacing-4);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    columns: 2;
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    columns: 3;
  }

  &:not(:last-child) {
    margin-bottom: var(--v-spacing-12);

    @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
      margin-bottom: var(--v-spacing-8);
    }
  }

  ${StatContainer} {
    font-size: 1rem;
    font-weight: ${FONTS.WEIGHT.SEMI_BOLD};
    margin-bottom: var(--v-spacing-4);
  }
`;

export const StyledTeaserSummary = styled(TeaserSummary)`
  margin-bottom: var(--v-spacing-12);
  width: 100%;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    margin-bottom: 0;
    width: 50%;
  }

  > div:not(:last-child) {
    margin-bottom: var(--v-spacing-8);

    @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
      margin-bottom: var(--v-spacing-12);
    }
  }
`;

export const Teaser = styled.article`
  background: var(--v-color-white);
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  overflow-y: auto;
  width: 100vw;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    grid-template-rows: auto 1fr auto;
    width: 66vw;
  }
`;

export const TeaserSection = styled(BriefSection)`
  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding: 0;
  }
`;

export const CGCTeaserSection = styled(BriefSection)`
  width: 100%;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding: 0;
    width: 50%;
  }
`;

export const BriefInfo = styled.div`
  overflow-y: auto;
  padding: var(--v-spacing-16) var(--v-spacing-4) var(--v-spacing-4);
  position: relative;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding: var(--v-spacing-3) var(--v-spacing-16) var(--v-spacing-16);
    position: static;
  }
`;

export const StyledWidget = styled(Widget)`
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    width: 66vw;
  }

  & iframe {
    border-radius: 0 !important;
  }
`;

export const StyledBackButton = styled(NavigationButton)`
  align-self: flex-start;
  top: 0.3125rem;
  left: 0rem;
  position: absolute;
  z-index: 10;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    top: 1rem;
  }
`;
