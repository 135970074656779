import ASPECT_RATIOS from "shared/constants/aspectRatios";
import { AspectRatio } from "shared/types/video";

export const ASPECT_RATIO_ORDER = [
  ASPECT_RATIOS.NINE_BY_SIXTEEN,
  ASPECT_RATIOS.TWO_BY_THREE,
  ASPECT_RATIOS.FOUR_BY_THREE,
  ASPECT_RATIOS.FOUR_BY_FIVE,
  ASPECT_RATIOS.ONE_BY_ONE,
  ASPECT_RATIOS.SIXTEEN_BY_NINE,
];

export const sortAspectRatios = (a: AspectRatio, b: AspectRatio) => {
  return ASPECT_RATIO_ORDER.indexOf(a) - ASPECT_RATIO_ORDER.indexOf(b);
};

export default sortAspectRatios;
