import DropdownArrow from "creators/images/icons/dropdown-arrow.svg";
import { components } from "react-select";
import Icon from "shared/components/Icon";
import styled from "styled-components";

export interface IDropdownIndicatorIconProps {
  open: boolean;
}

export const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <DropdownIndicatorIcon
          component={DropdownArrow}
          open={props.selectProps.menuIsOpen}
        />
      </components.DropdownIndicator>
    )
  );
};

export const DropdownIndicatorIcon = styled(Icon)<IDropdownIndicatorIconProps>`
  height: 100%;
  width: 100%;
  transform: rotate(${(props) => (props.open ? "180deg" : 0)});

  & svg {
    display: block;
  }
`;

export const Ellipsis = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ImageIcon = styled.img`
  background: var(--v-color-grey);
  border-radius: 100%;
  height: var(--v-icon-size-base);
  width: var(--v-icon-size-base);
`;

export const Middle = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`;

export const Option = (props: any) => {
  const optionValue = props?.value;
  const className = optionValue ? `option-${optionValue}` : "";

  return (
    <components.Option {...props} className={className}>
      <Middle>
        {props.data.icon && (
          <div>
            {typeof props.data.icon === "string" ? (
              <ImageIcon alt="" src={props.data.icon} />
            ) : (
              <Icon component={props.data.icon} />
            )}
          </div>
        )}
        <span>{props.data.label}</span>
      </Middle>
    </components.Option>
  );
};

export const SingleValue = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <Middle>
        {props.data.icon && (
          <div>
            {typeof props.data.icon === "string" ? (
              <ImageIcon alt="" src={props.data.icon} />
            ) : (
              <Icon component={props.data.icon} />
            )}
          </div>
        )}
        <Ellipsis>{props.data.label}</Ellipsis>
      </Middle>
    </components.SingleValue>
  );
};

export default {
  Option,
  SingleValue,
};
