import COLORS from "creators/constants/colors";
import DIMENSIONS from "creators/constants/dimensions";
import { h2Styles } from "creators/styling/Heading/Heading";
import { darken, lighten, transparentize } from "polished";
import Dropdown from "shared/components/Dropdowns/Dropdown";
import styled, { css } from "styled-components";

export const darkTheme = {
  bg: COLORS.BLACK,
  fg: COLORS.WHITE,
  msg: lighten(0.1, COLORS.BLACK),
};

export const darkEditorTheme = {
  bg: COLORS.GREY_DARK_EDITOR,
  fg: COLORS.WHITE,
  msg: lighten(0.1, COLORS.GREY_DARK_EDITOR),
};

export const lightTheme = {
  bg: COLORS.WHITE,
  fg: COLORS.BLACK,
  msg: darken(0.1, COLORS.WHITE),
};

const headingStyles = css`
  margin-bottom: var(--v-spacing-8);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    margin: 0 auto;
    margin-bottom: var(--v-spacing-12);
    max-width: 33.75rem;
  }
`;

export const Header = styled.h2`
  ${headingStyles}
`;

export const HeaderDropdown = styled(Dropdown)`
  ${headingStyles}

  & .dropdown__control {
    background-color: ${({ theme }) => theme.bg};
    height: auto;
    min-height: auto;
  }

  & .dropdown__dropdown-indicator {
    background: ${({ theme }) => theme.bg};
    color: ${({ theme }) => theme.fg};

    &:hover {
      color: ${({ theme }) => theme.fg};
    }
  }

  & .dropdown__input-container {
    padding-bottom: 0;
    padding-top: 0;
  }

  & .dropdown__menu {
    background: ${({ theme }) => theme.bg};
  }

  & .dropdown__option {
    &,
    &:hover,
    &.dropdown__option--is-focused {
      color: ${({ theme }) => theme.fg};
    }

    &:hover,
    &.dropdown__option--is-focused {
      background: ${({ theme }) => transparentize(0.75, theme.fg)};
    }
  }

  & .dropdown__placeholder {
    margin-left: 0;
    margin-right: 0;
  }

  & .dropdown__value-container {
    ${h2Styles}
    padding: 0;
  }

  & .dropdown__single-value {
    ${h2Styles}
    color: ${({ theme }) => theme.fg};
    margin-left: 0;
    margin-right: 0;
  }
`;

export const HeaderInput = styled.input`
  font: inherit;
  ${h2Styles}
  ${headingStyles}
  background: transparent;
  border: 0;
  color: inherit;
  display: block;
  width: 100%;
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.bg};
  color: ${({ theme }) => theme.fg};
  padding: var(--v-spacing-8) var(--v-spacing-4);
  scroll-margin: 1rem 0;

  span.messaging {
    background-color: ${({ theme }) => theme.msg};
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding: var(--v-spacing-24) 0;
  }

  > *:not(${Header}, ${HeaderDropdown}, ${HeaderInput}, :last-child) {
    margin-bottom: var(--v-spacing-8);
  }
`;
