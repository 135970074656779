import { RemoveButton } from "creators/components/Brief/styles";
import ASPECT_RATIOS from "shared/constants/aspectRatios";
import styled from "styled-components";

const getCSSAspectRatio = (ratio?: string) =>
  ratio !== undefined ? ratio.replace(":", " / ") : "auto";

export const Container = styled.div<{ aspectRatio?: string }>`
  display: flex;
  justify-content: center;
  min-height: 3rem;

  iframe,
  video {
    aspect-ratio: ${({ aspectRatio }) => getCSSAspectRatio(aspectRatio)};
    border-radius: 0.5rem;
    display: block;
    max-height: 37.5rem;
    max-width: 100%;
    overflow: hidden;
    width: ${({ aspectRatio }) =>
      aspectRatio === ASPECT_RATIOS.SIXTEEN_BY_NINE ? "100%" : "18.75rem"};

    & ~ ${RemoveButton} {
      opacity: 0;
    }
  }

  :hover,
  :focus-within {
    iframe,
    video {
      & ~ ${RemoveButton} {
        opacity: 1;
      }
    }
  }
`;

export const OverlayWrapper = styled.div`
  position: relative;
`;

export const HoverBlocker = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;
