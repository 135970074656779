import CheckBox, {
  IProps as ICheckBoxProps,
} from "creators/components/CheckBox/CheckBox";
import styled from "styled-components";

export interface IProps extends ICheckBoxProps {
  label?: string;
}

export const Label = styled.label`
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
`;

export const LabelledCheckbox: React.FC<React.PropsWithChildren<IProps>> = ({
  className,
  label,
  ...checkBoxProps
}) => (
  <Label className={className}>
    <CheckBox {...checkBoxProps} />
    <div>{label}</div>
  </Label>
);

export default LabelledCheckbox;
