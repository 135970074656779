import { lighten, transparentize } from "polished";
import COLORS from "shared/constants/colors";
import FONTS from "shared/constants/fonts";
import { css } from "styled-components";

export const controlStyling = css`
  & .dropdown__control {
    background-color: ${COLORS.WHITE};
    border: 0;
    border-radius: 1px;
    cursor: pointer;
    height: 2rem;
    min-height: 2rem;
    overflow: hidden;
  }

  & .dropdown__control--is-focused {
    box-shadow: none;
  }

  & .dropdown__control.dropdown__control--is-disabled {
    background-color: ${COLORS.LIGHT_GREY};

    & .dropdown__dropdown-indicator {
      background-color: ${COLORS.LIGHT_GREY};
    }
  }

  & .dropdown__placeholder {
    color: ${lighten(0.35, COLORS.DARK_GREY)};
  }
`;

export const dropdownStyling = css`
  cursor: pointer;
`;

export const groupStyling = css`
  & .dropdown__group {
    padding: 0;
  }

  & .dropdown__group::after {
    border-top: 1px solid ${COLORS.DARK_GREY};
    content: "";
    display: block;
    margin: 0.25rem 0.5rem;
  }

  & .dropdown__group:last-child::after {
    border-top: 0;
    margin: 0;
  }

  & .dropdown__group-heading:empty {
    display: none;
  }
`;

export const indicatorStyling = css`
  & .dropdown__dropdown-indicator {
    background: inherit;
    color: inherit;
    height: 2rem;
    padding: 0.75rem;
    width: 2rem;

    &:hover {
      color: inherit;
    }
  }

  & .dropdown__indicator-separator {
    display: none;
  }
`;

export const inlineStyling = css`
  display: inline-block;
  font-size: 0.75rem;
  font-weight: ${FONTS.WEIGHT.BOLD};
  min-width: 10rem;

  & .dropdown__control {
    background: transparent;
    border: 1px solid ${COLORS.WHITE};
    border-radius: 1rem;
    font-size: 0.75rem;
    padding: 0 0.25rem 0 1rem;
    transition: none;

    &.dropdown__control--is-focused,
    &.dropdown__control--menu-is-open,
    &:hover {
      border: 1px solid ${COLORS.WHITE};
      box-shadow: 0 0 0 2px ${COLORS.BLUE};
    }

    &.dropdown__control--is-disabled {
      background-color: ${COLORS.DARK_GREY};
      border: 1px solid ${COLORS.LIGHT_GREY};
      color: ${COLORS.LIGHT_GREY};

      & .dropdown__dropdown-indicator {
        background-color: ${COLORS.DARK_GREY};
        color: ${COLORS.LIGHT_GREY};
      }

      & .dropdown__placeholder {
        color: ${COLORS.LIGHT_GREY};
      }
    }
  }

  .dropdown__control--menu-is-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  & .dropdown__dropdown-indicator {
    color: ${COLORS.WHITE};
  }

  & .dropdown__input {
    color: ${COLORS.WHITE};
  }

  & .dropdown__menu {
    background: ${COLORS.DARK_GREY};
    border: 1px solid ${COLORS.WHITE};
    border-radius: 0;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    box-shadow:
      0 2px 0 0 ${COLORS.BLUE},
      2px 2px 0 0 ${COLORS.BLUE},
      -2px 2px 0 ${COLORS.BLUE};
    color: ${COLORS.WHITE};
    margin-top: -3px;
    overflow: hidden;
  }

  & .dropdown__menu-list {
    min-height: fit-content;
    padding: 0.5rem 0;
  }

  & .dropdown__option {
    color: ${COLORS.WHITE};
    padding: 0.25rem 1rem;

    &:hover,
    &.dropdown__option--is-focused {
      background: ${transparentize(0.75, COLORS.WHITE)};
      color: ${COLORS.WHITE};
    }

    &.dropdown__option--is-disabled {
      background: unset;
      color: ${transparentize(0.5, COLORS.WHITE)};
    }
  }

  & .dropdown__placeholder {
    color: ${transparentize(0.25, COLORS.WHITE)};
  }

  & .dropdown__single-value {
    color: inherit;
  }

  & .dropdown__value-container {
    padding: 0;
    top: -0.125rem;
  }

  & .dropdown__group::after {
    border-color: ${COLORS.WHITE};
    margin: 0.25rem 0;
  }

  & .dropdown__dropdown-indicator {
    padding: 0.7rem 0.75rem 0.8rem;
  }
`;

export const optionStyling = css`
  & .dropdown__option {
    background-color: inherit;
    color: ${COLORS.DARK_GREY};
    cursor: pointer;
    padding: 0.25rem 0.5rem;
  }

  & .dropdown__option--is-disabled {
    background-color: ${lighten(0.85, COLORS.DARK_GREY)};
    color: ${COLORS.LIGHT_GREY};
  }

  & .dropdown__option--is-focused {
    background-color: ${lighten(0.85, COLORS.DARK_GREY)};
  }
`;

export const valueStyling = css`
  & .dropdown__single-value--is-disabled {
    color: ${COLORS.LIGHTER_GREY};
  }

  & .dropdown__multi-value {
    background: transparent;
    margin: 0 0.5rem 0 0;

    &:first-child {
      margin-left: 0.125rem;
    }
  }

  & .dropdown__multi-value__label {
    font-size: inherit;
    padding: 0;
  }

  & .dropdown__multi-value__remove {
    color: ${COLORS.DARK_GREY};
    cursor: pointer;
    line-height: 1;
    margin: 0.25rem;
    padding: 0;

    > span {
      height: 0.75rem;
      width: 0.75rem;
    }

    & svg {
      display: block;
    }

    &:hover {
      background-color: transparent;
      color: ${COLORS.DARK_GREY};
    }
  }
`;

export default {
  controlStyling,
  dropdownStyling,
  groupStyling,
  indicatorStyling,
  inlineStyling,
  optionStyling,
  valueStyling,
};
