import Environment from "shared/types/environment";

const ANALYTICS = {
  CATEGORIES: {
    ACTIVE_BRIEFS: "active_briefs",
    CREATOR_AUTHENTICATION: "creator_authentication",
    CREATOR_VERIFICATION: "creator_verification",
    LIVE_BRIEFS: "live_briefs",
    NAVIGATION: "navigation",
    ONBOARDING: "onboarding",
    PAYMENTS: "payments",
    PREVIOUS_BRIEFS: "previous_briefs",
  },
  EVENT: {
    APPLY_TO_BRIEF: "apply_to_brief",
    BRIEF_ASSET_PACK_DOWNLOAD: "brief_asset_pack_download",
    BRIEF_INVITE_ACCEPTED: "brief_invite_accepted",
    BRIEF_INVITE_DECLINED: "brief_invite_declined",
    BRIEF_WITHDRAW_APPLICATION: "brief_withdraw_application",
    CREATOR_CLICKED: "creator_clicked",
    CREATOR_HUB_VISITED: "creator_hub_visited",
    DOWNLOAD_CSV_FAILED: "payments_download_csv_failed",
    DOWNLOAD_CSV_SUCCESS: "payments_download_csv_success",
    FILES_SUBMITTED: "files_submitted",
    FORGOT_PASSWORD: "forgot_password",
    INCORRECT_EMAIL: "incorrect_email",
    LOGIN_FAILED: "login_failed",
    LOGIN_SUCCESS: "login_success",
    LOGOUT: "logout",
    ONBOARDING_COMPLETE: "onboarding_complete",
    ONBOARDING_CURRENCY: "onboarding_currency_step",
    ONBOARDING_LOCATION: "onboarding_location_step",
    ONBOARDING_PORTFOLIO: "onboarding_portfolio_step",
    ONBOARDING_SKILLS: "onboarding_skills_step",
    ONBOARDING_START: "onboarding_start",
    PREVIOUS_BRIEF_VIDEO_VIEWED: "previous_brief_video_viewed",
    PREVIOUS_BRIEF_CHANGED_RECOMMENDED_SLIDE:
      "previous_brief_changed_recommended_slide",
    PREVIOUS_BRIEF_RECOMMENDED_VIDEO_PROFILE_CLICK:
      "previous_brief_recommended_video_profile_click",
    PREVIOUS_BRIEF_RECOMMENDED_VIDEO_VIEWED:
      "previous_brief_recommended_video_viewed",
    PREVIOUS_BRIEF_OWN_VIDEO_VIEWED: "previous_brief_own_video_viewed",
    RESEND_VERIFICATION_EMAIL: "resend_verification_email",
    REGISTRATION_FAILED: "registration_failed",
    REGISTRATION_SUCCESS: "registration_success",
    SUBMITTED_VIDEO_VIEWED: "submitted_video_viewed",
    TEASER_VIEWED: "teaser_viewed",
    UPLOAD_FILE_FAILED: "upload_file_failed",
    UPLOAD_FILE_SUCCESS: "upload_file_success",
    VIDEO_DELETED: "video_deleted",
  },
  SEGMENT: {
    [Environment.Development]: "vsWGv4QVZYAeLljtUGFT3bpy0wo3W7oL",
    [Environment.Live]: "psxkGbAButtygQwe9iHpoN1CyVPmUAx2",
    [Environment.Staging]: "wywHOTLS32OgwLSYivlNcpNdwuIfL4yy",
  },
};

export default ANALYTICS;
