import COLORS from "shared/constants/colors";
import styled, { css } from "styled-components";

export const inputStyles = css`
  appearance: none;
  background: ${COLORS.WHITE};
  border: 0;
  border-radius: 1px;
  font: inherit;
  padding: 0.325rem 0.5rem;

  :focus {
    box-shadow: 0 0 0 2px ${COLORS.BLUE};
    outline: none;
  }
  :disabled {
    background-color: ${COLORS.MID_GREY};
    color: #999999;
  }
  &input[type="search"] {
    -webkit-appearance: textfield;
  }
  &[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  &[type="time"]::-webkit-clear-button {
    -webkit-appearance: none;
  }
`;

export const StyledInput = styled.input`
  ${inputStyles}
`;

export default StyledInput;
