import { Toggletip } from "creators/components/Tooltip";
import DIMENSIONS from "creators/constants/dimensions";
import FONTS from "creators/constants/fonts";
import styled from "styled-components";

export const StyledToggletip = styled(Toggletip)`
  display: inline-block;
  position: unset;

  > div {
    &:first-child {
      display: inline;
      vertical-align: middle;

      > span {
        color: var(--v-color-grey-dark);
        height: 1.5rem;
        margin-left: var(--v-spacing-1);
        width: 1.5rem;
      }
    }

    &:last-child {
      color: var(--v-color-black);
      font-size: 1rem;
      font-weight: ${FONTS.WEIGHT.REGULAR};
      top: 0;
      transform: translate(-50%, 0);
      width: 22rem;

      @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
        top: var(--v-spacing-3);
        transform: translate(-90%, 20%);
      }
    }
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    position: relative;
  }
`;

export const Summary = styled.dl`
  position: relative;
`;
