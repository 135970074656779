import { useMutation, useQuery } from "@apollo/client";
import Button from "creators/components/Button";
import DataError from "creators/components/DataError";
import FormikCheckbox from "creators/components/forms/FormikCheckbox";
import LoadingDelayed from "creators/components/LoadingDelayed";
import {
  PrivacyPolicyLink,
  TermsAndConditionsLink,
} from "creators/components/TermsAndConditionsLinks";
import WindowLayout from "creators/components/WindowLayout";
import IDS from "creators/constants/ids";
import { UPDATE_CREATOR } from "creators/mutations/updateCreator";
import { GET_VIEWER } from "creators/queries/GetViewer";
import {
  EmailVerificationStage,
  GetViewerQuery as IGetViewer,
  UpdateCreatorMutation as IUpdateCreator,
} from "creators/types/graphql";
import onboardingIncomplete from "creators/utils/onboardingIncomplete";
import textualize from "creators/utils/textualize";
import { Form, Formik, FormikProps } from "formik";
import { useCallback, useContext, useState } from "react";
import { Redirect } from "react-router";
import { ToastContext } from "shared/components/ToastProvider";
import styled from "styled-components";

const Heading = styled.h2`
  margin-bottom: var(--v-spacing-12);
`;

const StyledFormikCheckbox = styled(FormikCheckbox)`
  margin-bottom: var(--v-spacing-16);
`;

interface IFormValues {
  termsAndConditionsAccepted: boolean;
}

function TermsAndConditions() {
  const [submitted, setSubmitted] = useState(false);
  const { addToast } = useContext(ToastContext);

  const { data, loading, error } = useQuery<IGetViewer>(GET_VIEWER);
  const [updateCreator] = useMutation<IUpdateCreator>(UPDATE_CREATOR);

  const onSubmit = useCallback(async () => {
    const result = await updateCreator({
      variables: {
        input: {
          termsAndConditionsAccepted: true,
        },
      },
    }).catch(() =>
      addToast(
        textualize("onboarding.termsAndConditions.error") as string,
        true,
      ),
    );

    if (result?.data?.viewerUpdateCreator) {
      setSubmitted(true);
    }
  }, [addToast, updateCreator]);

  if (loading) {
    return <LoadingDelayed />;
  }

  if (error) {
    return <DataError error={error} />;
  }

  if (!data?.viewer) {
    return null;
  }

  const { emailVerificationStage, firstName, termsAndConditionsAccepted } =
    data.viewer;

  if (termsAndConditionsAccepted || submitted) {
    let redirectURI = "/";
    if (emailVerificationStage !== EmailVerificationStage.Verified) {
      redirectURI = "/verify-email";
    } else if (onboardingIncomplete(data.viewer)) {
      redirectURI = "/onboarding";
    }
    return <Redirect to={redirectURI} />;
  }

  return (
    <WindowLayout firstName={firstName} id={IDS.TERMS_AND_CONDITIONS.PAGE}>
      <Heading>{textualize("onboarding.termsAndConditions.heading")}</Heading>
      <Formik
        initialValues={{ termsAndConditionsAccepted: false }}
        onSubmit={onSubmit}
      >
        {({ dirty, isSubmitting, isValid }: FormikProps<IFormValues>) => (
          <Form>
            <StyledFormikCheckbox
              inputProps={{
                id: IDS.TERMS_AND_CONDITIONS.AGREE,
                label: textualize("common.termsAndConditions.agreement", {
                  privacyPolicy: (
                    <PrivacyPolicyLink
                      id={IDS.TERMS_AND_CONDITIONS.PRIVACY_POLICY}
                    />
                  ),
                  termsAndConditions: (
                    <TermsAndConditionsLink
                      id={IDS.TERMS_AND_CONDITIONS.LINK}
                    />
                  ),
                }) as string,
                required: true,
              }}
              name="termsAndConditionsAccepted"
            />
            <Button
              disabled={!dirty || isSubmitting || !isValid}
              id={IDS.TERMS_AND_CONDITIONS.SAVE}
              isLoading={isSubmitting}
              type="submit"
            >
              {textualize("onboarding.termsAndConditions.save")}
            </Button>
          </Form>
        )}
      </Formik>
    </WindowLayout>
  );
}

export default TermsAndConditions;
