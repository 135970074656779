import COLORS from "shared/constants/colors";
import { css } from "styled-components";

export const baseScrollbarStyles = css`
  scrollbar-color: ${COLORS.WHITE} ${COLORS.MID_GREY};
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    height: 1rem;
    width: 1rem;
  }
  &::-webkit-scrollbar-thumb {
    background: ${COLORS.WHITE};
    border-radius: 0.4375rem;
    border: 0.4375rem solid ${COLORS.MID_GREY};
  }
  &::-webkit-scrollbar-track {
    background: ${COLORS.MID_GREY};
  }
`;

export default baseScrollbarStyles;
