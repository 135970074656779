import briefImage from "./briefImage.yml";
import briefMustInclude from "./briefMustInclude.yml";
import briefSection from "./briefSection.yml";
import briefText from "./briefText.yml";
import briefVideo from "./briefVideo.yml";
import ui from "./ui.yml";

export const copy = {
  briefImage,
  briefMustInclude,
  briefSection,
  briefText,
  briefVideo,
  ui,
};

export default copy;
