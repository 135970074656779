import Avatar from "creators/components/Avatar";
import {
  hoverStyles,
  linkStyles,
} from "creators/components/GlobalNavItem/styles";
import FONTS from "creators/constants/fonts";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const StyledAvatar = styled(Avatar)`
  height: var(--v-icon-avatar-md);
  flex: 0 0 auto;
  margin-right: var(--v-spacing-3);
  width: var(--v-icon-avatar-md);
`;

export const Disabled = styled.div`
  ${linkStyles};
  font-size: 0.75rem;

  ${StyledAvatar}, span:last-child {
    opacity: 0.5;
  }
`;

export const StyledNavLink = styled(NavLink)`
  ${linkStyles};
  ${hoverStyles};
  font-size: 0.75rem;
  font-weight: ${FONTS.WEIGHT.SEMI_BOLD};
`;
