import Loading from "creators/components/Loading";
import DIMENSIONS from "creators/constants/dimensions";
import FONTS from "creators/constants/fonts";
import { ButtonHTMLAttributes } from "react";
import Icon from "shared/components/Icon";
import styled, { css } from "styled-components";

export interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  icon?: React.FunctionComponent;
  isLoading?: boolean;
}

const StyledIcon = styled(Icon)`
  margin-left: var(--v-spacing-4);
  height: var(--v-icon-size-base);
  vertical-align: text-bottom;
  width: var(--v-icon-size-base);
`;

export const baseButtonStyles = css`
  appearance: none;
  background: var(--v-color-red);
  border: 0.125rem solid var(--v-color-red);
  border-radius: 0.5rem;
  color: var(--v-color-white);
  cursor: pointer;
  font-family: ${FONTS.FAMILY.BODY};
  font-size: 0.875rem;
  font-weight: ${FONTS.WEIGHT.SEMI_BOLD};
  letter-spacing: 0.0625rem;
  padding: var(--v-spacing-3) 1.375rem;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    width: auto;
  }

  &:active {
    background: var(--v-color-red-active);
    border: 0.125rem solid var(--v-color-red-active);
  }

  &:hover,
  &:focus {
    transform: scale(1.04);

    &:disabled {
      transform: scale(1);
    }
  }

  &:disabled {
    background: var(--v-color-grey);
    border-color: var(--v-color-grey);
  }
`;

export const secondaryButtonStyles = css`
  ${baseButtonStyles};

  background: none;
  border: 0.125rem solid var(--v-color-black);
  color: var(--v-color-black);

  &:active {
    background: var(--v-color-grey-light);
    border-color: inherit;
  }

  &:disabled {
    color: var(--v-color-white);
    border-color: var(--v-color-grey);
  }
`;

const loadingStyles = css<IProps>`
  padding: var(--v-spacing-3)
    ${({ isLoading }) => (isLoading ? "var(--v-spacing-3)" : "1.375rem")};
`;

const BaseButton = styled.button<IProps>`
  ${baseButtonStyles}
  ${loadingStyles}
`;

export const SecondaryButton = styled(Button)`
  ${secondaryButtonStyles}
  ${loadingStyles}
`;

export function Button({ children, icon, isLoading, ...rest }: IProps) {
  return (
    <BaseButton isLoading={isLoading ? true : undefined} {...rest}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {/* Span guards against Google Translate causing app to crash */}
          <span>{children}</span>
          {icon && <StyledIcon component={icon} />}
        </>
      )}
    </BaseButton>
  );
}

export default Button;
