import { ReactNode, useCallback, useContext } from "react";
import {
  addModalAction,
  removeAllModalsAction,
  removeModalAction,
} from "shared/actions/modals";
import { ModalContext } from "shared/components/ModalProvider";

const useModal = (id: string) => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const openModal = useCallback(
    (component: ReactNode, priority?: boolean) => {
      modalDispatch(
        addModalAction({
          payload: {
            component,
            id,
            priority,
          },
        }),
      );
    },
    [id, modalDispatch],
  );

  const closeModal = useCallback(() => {
    modalDispatch(
      removeModalAction({
        payload: {
          id,
        },
      }),
    );
  }, [id, modalDispatch]);

  const closeAllModals = useCallback(() => {
    modalDispatch(removeAllModalsAction());
  }, [modalDispatch]);

  return {
    closeAllModals,
    closeModal,
    openModal,
  };
};

export default useModal;
