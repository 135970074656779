import calculateTimeBetween, {
  ITimeBetween,
  MINUTE,
} from "creators/utils/calculateTimeBetween/calculateTimeBetween";
import { useCallback, useEffect, useState } from "react";
import useInterval from "react-useinterval";

export interface ICountdown {
  complete: boolean;
  timeRemaining: ITimeBetween | null;
}

export const getTimeToUpdate = (date: Date): number | null => {
  const now = new Date();

  if (date <= now) {
    return null;
  }

  return (date.getTime() - now.getTime()) % MINUTE || MINUTE;
};

export const useCountdown = (date?: Date): ICountdown => {
  const [timeRemaining, setTimeRemaining] = useState<ITimeBetween | null>(
    date ? calculateTimeBetween(new Date(), date) : null,
  );

  const updateTimeRemaining = useCallback(() => {
    setTimeRemaining(date ? calculateTimeBetween(new Date(), date) : null);
  }, [date]);

  useEffect(() => {
    updateTimeRemaining();
  }, [updateTimeRemaining]);

  useInterval(updateTimeRemaining, date ? getTimeToUpdate(date) : null);

  return {
    complete:
      !!timeRemaining &&
      timeRemaining.days === 0 &&
      timeRemaining.hours === 0 &&
      timeRemaining.minutes === 0,
    timeRemaining,
  };
};

export default useCountdown;
