import BriefImage from "creators/components/Brief/BriefImage";
import BriefMustInclude from "creators/components/Brief/BriefMustInclude";
import BriefText from "creators/components/Brief/BriefText";
import BriefVideo from "creators/components/Brief/BriefVideo";
import { Block, BLOCK_TYPE } from "shared/BlockBuilder/global";

const BLOCK_MAP = {
  [BLOCK_TYPE.Image]: BriefImage,
  [BLOCK_TYPE.MustInclude]: BriefMustInclude,
  [BLOCK_TYPE.OrderedList]: null,
  [BLOCK_TYPE.Section]: null,
  [BLOCK_TYPE.Text]: BriefText,
  [BLOCK_TYPE.UnorderedList]: null,
  [BLOCK_TYPE.Video]: BriefVideo,
};

interface IProps {
  block: Block;
  className?: string;
  error?: string;
  handleChange?: (value: any) => void;
  id: string;
}

function BlockConductor({ block, className, error, handleChange, id }: IProps) {
  const Component = BLOCK_MAP[block.type] || BriefText;
  return (
    <Component
      block={block}
      className={className}
      error={error}
      handleChange={handleChange}
      id={id}
    />
  );
}

export default BlockConductor;
