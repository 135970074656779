import FormikDropdown from "creators/components/forms/FormikDropdown";
import useCurrencyOptions from "creators/hooks/useCurrencyOptions";

interface IProps {
  className?: string;
  help: string;
  id: string;
  label?: string;
}

const CurrencyDropdown: React.FC<React.PropsWithChildren<IProps>> = ({
  className,
  help,
  id,
  label,
}) => {
  const currencyOptions = useCurrencyOptions();

  return (
    <FormikDropdown
      className={className}
      inputProps={{
        help,
        id,
        label,
        options: currencyOptions,
      }}
      name="currency"
    />
  );
};

export default CurrencyDropdown;
