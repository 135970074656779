import FONTS from "creators/constants/fonts";
import Mode from "creators/types/mode";
import { ReactNode } from "react";
import styled, { css } from "styled-components";

export interface IProps {
  className?: string;
  id?: string;
  mode?: Mode;
  label: string | ReactNode;
  value: string | ReactNode;
}

export const labelStyles = css`
  color: var(--v-color-black);
  display: inline;

  ::after {
    content: ".";
    margin-right: var(--v-spacing-1);
  }
`;

export const valueStyles = css`
  color: var(--v-color-grey-dark);
  display: inline;
`;

export const Label = styled.dt`
  ${labelStyles}
`;

export const Value = styled.dd`
  ${valueStyles}
`;

const darkStyles = css`
  ${Label} {
    color: var(--v-color-white);
  }
  ${Value} {
    color: var(--v-color-grey);
  }
`;

export const Container = styled.div<{ mode?: Mode }>`
  font-size: 1.5rem;
  font-weight: ${FONTS.WEIGHT.BOLD};
  overflow-wrap: break-word;

  ${({ mode }) => mode === Mode.Dark && darkStyles}
`;

export const Stat: React.FC<React.PropsWithChildren<IProps>> = ({
  className,
  id,
  label,
  mode,
  value,
}) => (
  <Container className={className} id={id} mode={mode}>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </Container>
);

export default Stat;
