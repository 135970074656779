import { lazy } from "react";
import { IRouteProps } from "shared/types/routes";

const ChangePasswordPage = lazy(() => import("creators/pages/ChangePassword"));
const LoginPage = lazy(() => import("creators/pages/Login"));
const RegisterPage = lazy(() => import("creators/pages/Register"));
const ResetPasswordPage = lazy(() => import("creators/pages/ResetPassword"));

const VUIChangePasswordPage = lazy(
  () => import("creators/pages/vui/ChangePassword"),
);
const VUILoginPage = lazy(() => import("creators/pages/vui/Login"));
const VUIRegisterPage = lazy(() => import("creators/pages/vui/Register"));
const VUIResetPasswordPage = lazy(
  () => import("creators/pages/vui/ResetPassword"),
);

const routes: IRouteProps[] = [
  {
    component: LoginPage,
    exact: true,
    path: "/login",
    updatedComponent: VUILoginPage,
  },
  {
    component: ResetPasswordPage,
    exact: true,
    path: "/login/reset-password",
    updatedComponent: VUIResetPasswordPage,
  },
  {
    component: ChangePasswordPage,
    exact: true,
    path: "/login/change-password",
    updatedComponent: VUIChangePasswordPage,
  },
  {
    component: RegisterPage,
    exact: true,
    path: "/register",
    updatedComponent: VUIRegisterPage,
  },
];

export default routes;
