import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { NavRailItem } from "vui/components/NavRail";
import NavRailLink from "vui/components/NavRailLink";

export interface IProps {
  active?: boolean;
  className?: string;
  disabled?: boolean;
  href?: string;
  icon: React.FC;
  iconFilled?: React.FC;
  id?: string;
  label?: ReactNode | string;
  onClick?: () => void;
  rel?: string;
  showActiveIcon?: boolean;
  target?: string;
  to?: string;
}

const NavItem: React.FC<React.PropsWithChildren<IProps>> = ({
  active,
  className,
  href,
  id,
  icon,
  iconFilled,
  label,
  onClick,
  rel,
  target,
  to,
}) => {
  const sharedProps = {
    className,
    id,
    onClick,
  };

  const content = (
    <NavRailItem
      active={active}
      icon={active && iconFilled ? iconFilled : icon}
    >
      {label}
    </NavRailItem>
  );

  if (to) {
    return (
      <NavRailLink
        as={Link}
        {...sharedProps}
        $active={active}
        rel={rel}
        target={target}
        to={to}
      >
        {content}
      </NavRailLink>
    );
  }

  if (href) {
    return (
      <NavRailLink
        {...sharedProps}
        $active={active}
        href={href}
        rel={rel}
        target={target}
      >
        {content}
      </NavRailLink>
    );
  }

  if (onClick) {
    return <button {...sharedProps}>{content}</button>;
  }

  return content;
};

export default NavItem;
