import { memoize } from "lodash";

export const camelToSnake = memoize((s: string) =>
  // Take any lowercase letter that preceeds an uppercase letter and add a "_" between them
  // Ignore any chains of consecutive uppercase letters
  // Lowercase the penultimate result
  s.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase(),
);

export const snakeKeys = (params?: Record<string, unknown>) =>
  params
    ? Object.fromEntries(
        Object.entries(params).map(([k, v]) => [camelToSnake(k), v]),
      )
    : undefined;

export default snakeKeys;
