import styled from "styled-components";
import Icon from "vui/components/Icon";

export const NavRailContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  position: fixed;
`;

export const NavRailContent = styled.nav`
  display: flex;
  flex-direction: column;
  margin: var(--vui-spacing-4) 0 0;
  width: var(--vui-spacing-20);

  * + & {
    margin-top: 0;
  }
`;

export const NavRailContentItemLabel = styled.span`
  color: rgb(var(--vui-color-on-surface-secondary));
  font-family: var(--vui-typescale-label-medium-font);
  font-size: var(--vui-typescale-label-medium-size);
  font-weight: var(--vui-typescale-label-medium-weight);
  line-height: var(--vui-typescale-label-medium-line-height);
`;

export const NavRailContentItemIcon = styled(Icon)`
  color: rgb(var(--vui-color-on-surface-secondary));
  display: block;
  height: var(--vui-spacing-6);
  margin: 0 auto var(--vui-spacing-1);
  position: relative;
  width: var(--vui-spacing-6);

  &::before {
    background: transparent;
    border-radius: var(--vui-shape-corner-full);
    content: "";
    height: var(--vui-spacing-8);
    position: absolute;
    opacity: 0;
    transform: translateX(calc(-50% + var(--vui-spacing-3)))
      translateY(calc(-1 * var(--vui-spacing-1)));
    width: var(--vui-spacing-14);
    z-index: -1;
  }
`;

export const NavRailContentItemOuter = styled.div<{ active?: boolean }>`
  display: block;
  margin: 0 auto;
  text-align: center;
`;

export const NavRailFooter = styled.div`
  align-items: center;
  display: flex;
  height: 4.125rem;
  justify-content: center;
  margin-bottom: 2.625rem;
  margin-top: auto;
`;

export const NavRailHeader = styled.div`
  color: rgb(var(--vui-color-vidsy-brand));
  height: 5rem;
  margin-bottom: var(--vui-spacing-10);
  margin-top: var(--vui-spacing-11);
`;
