import { css } from "styled-components";

const colors = css`
  --v-color-black: #17171a;
  --v-color-white: #ffffff;
  --v-color-grey-light: #eeeeee;
  --v-color-grey: #c4c4c5;
  --v-color-grey-dark: #737378;
  --v-color-grey-darkest: #2f2f31;
  --v-color-grey-dark-editor: #29292d;
  --v-color-blue: #3561dc;
  --v-color-green: #048446;
  --v-color-green-active: #14f0a7;
  --v-color-green-light: #c2ffde;
  --v-color-red: #e3274e;
  --v-color-red-active: #ca0e35;
  --v-color-red-light: #ffe3f5;
  --v-color-pink: #f893e8;
  --v-color-pink-light: var(--v-color-red-light);
  --v-color-yellow: #f8ca00;
  --v-color-yellow-light: #ffffac;
  --v-color-tags-blue-text: var(--v-color-blue);
  --v-color-tags-blue-bg: #eeebfc;
  --v-color-tags-green-text: var(--v-color-green);
  --v-color-tags-green-bg: #e8fdf3;
  --v-color-tags-red-text: #d01f49;
  --v-color-tags-red-bg: #f8eaec;

  --v-color-black-rgb: 23, 23, 26;
  --v-color-white-rgb: 255, 255, 255;
  --v-color-grey-light-rgb: 238, 238, 238;
  --v-color-grey-rgb: 196, 196, 197;
  --v-color-red-rgb: 227, 39, 78;
`;

export default colors;
