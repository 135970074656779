import Euro from "creators/images/currencies/euro.svg";
import AE from "shared/images/countries/AE.svg";
import AU from "shared/images/countries/AU.svg";
import BG from "shared/images/countries/BG.svg";
import BW from "shared/images/countries/BW.svg";
import CA from "shared/images/countries/CA.svg";
import CH from "shared/images/countries/CH.svg";
import CL from "shared/images/countries/CL.svg";
import CR from "shared/images/countries/CR.svg";
import CZ from "shared/images/countries/CZ.svg";
import DK from "shared/images/countries/DK.svg";
import EG from "shared/images/countries/EG.svg";
import FJ from "shared/images/countries/FJ.svg";
import GB from "shared/images/countries/GB.svg";
import GE from "shared/images/countries/GE.svg";
import GH from "shared/images/countries/GH.svg";
import HK from "shared/images/countries/HK.svg";
import HR from "shared/images/countries/HR.svg";
import HU from "shared/images/countries/HU.svg";
import ID from "shared/images/countries/ID.svg";
import IL from "shared/images/countries/IL.svg";
import JP from "shared/images/countries/JP.svg";
import KE from "shared/images/countries/KE.svg";
import KR from "shared/images/countries/KR.svg";
import MA from "shared/images/countries/MA.svg";
import MX from "shared/images/countries/MX.svg";
import MY from "shared/images/countries/MY.svg";
import NG from "shared/images/countries/NG.svg";
import NO from "shared/images/countries/NO.svg";
import NP from "shared/images/countries/NP.svg";
import NZ from "shared/images/countries/NZ.svg";
import PH from "shared/images/countries/PH.svg";
import PK from "shared/images/countries/PK.svg";
import PL from "shared/images/countries/PL.svg";
import RO from "shared/images/countries/RO.svg";
import RU from "shared/images/countries/RU.svg";
import SE from "shared/images/countries/SE.svg";
import SG from "shared/images/countries/SG.svg";
import SN from "shared/images/countries/SN.svg";
import TH from "shared/images/countries/TH.svg";
import TR from "shared/images/countries/TR.svg";
import TZ from "shared/images/countries/TZ.svg";
import UG from "shared/images/countries/UG.svg";
import US from "shared/images/countries/US.svg";
import UY from "shared/images/countries/UY.svg";
import VN from "shared/images/countries/VN.svg";
import ZA from "shared/images/countries/ZA.svg";
import ZM from "shared/images/countries/ZM.svg";

interface ICurrencyMap {
  [currency: string]: React.FC<React.PropsWithChildren>;
}

export const CURRENCY_MAP: ICurrencyMap = {
  AED: AE,
  AUD: AU,
  BGN: BG,
  BWP: BW,
  CAD: CA,
  CHF: CH,
  CLP: CL,
  CRC: CR,
  CZK: CZ,
  DKK: DK,
  EGP: EG,
  EUR: Euro,
  FJD: FJ,
  GBP: GB,
  GEL: GE,
  GHS: GH,
  HKD: HK,
  HRK: HR,
  HUF: HU,
  IDR: ID,
  ILS: IL,
  JPY: JP,
  KES: KE,
  KRW: KR,
  MAD: MA,
  MXN: MX,
  MYR: MY,
  NGN: NG,
  NOK: NO,
  NPR: NP,
  NZD: NZ,
  PHP: PH,
  PKR: PK,
  PLN: PL,
  RON: RO,
  RUB: RU,
  SEK: SE,
  SGD: SG,
  THB: TH,
  TRY: TR,
  TZS: TZ,
  UGX: UG,
  USD: US,
  UYU: UY,
  VND: VN,
  XOF: SN,
  ZAR: ZA,
  ZMW: ZM,
};

export default (currency: string) => CURRENCY_MAP[currency] || undefined;
