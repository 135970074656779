import GlobalNav from "creators/components/vui/GlobalNav";
import { NavRailContainer, NavRailHeader } from "vui/components/NavRail";
import VidsyMonogram from "vui/icons/vidsy-monogram.svg";

const DesktopNav: React.FC = () => {
  return (
    <NavRailContainer>
      <NavRailHeader>
        <VidsyMonogram />
      </NavRailHeader>

      <GlobalNav />
    </NavRailContainer>
  );
};

export default DesktopNav;
