import { useMutation } from "@apollo/client";
import Button, { SecondaryButton } from "creators/components/Button";
import FormikInput from "creators/components/forms/FormikInput";
import ANALYTICS from "creators/constants/analytics";
import IDS from "creators/constants/ids";
import SEND_VERIFICATION_EMAIL from "creators/mutations/sendVerificationEmail";
import { SendVerificationEmailMutation as ISendVerificationEmail } from "creators/types/graphql";
import textualize from "creators/utils/textualize";
import { Form, Formik, FormikErrors, FormikProps } from "formik";
import { useCallback, useContext, useState } from "react";
import { ToastContext } from "shared/components/ToastProvider";
import { EMAIL_REGEX } from "shared/constants/regex";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import styled from "styled-components";

interface IProps {
  closeForm: () => void;
  onEmailSent: () => void;
}

interface IFormValues {
  newEmail: string;
}

const NewEmail = styled(FormikInput)`
  margin-bottom: var(--v-spacing-4);
  max-width: 31.75rem;
  width: 100%;
`;

const SendVerificationEmail = styled(Button)`
  margin-bottom: var(--v-spacing-4);
  margin-right: var(--v-spacing-4);
`;

const validation = (values: IFormValues) => {
  const errors: FormikErrors<IFormValues> = {};

  if (!EMAIL_REGEX.test(values.newEmail)) {
    errors.newEmail = textualize(
      "onboarding.verifyEmail.unverified.incorrectEmail.newEmail.error",
    ) as string;
  }

  return errors;
};

function IncorrectEmailForm({ closeForm, onEmailSent }: IProps) {
  const [emailSent, setEmailSent] = useState(false);
  const { addToast } = useContext(ToastContext);

  const [sendVerificationEmail] = useMutation<ISendVerificationEmail>(
    SEND_VERIFICATION_EMAIL,
  );

  const handleSubmit = useCallback(
    async (values: IFormValues) => {
      try {
        const result = await sendVerificationEmail({
          variables: {
            input: {
              pendingEmail: values.newEmail,
            },
          },
        });

        if (result.data?.viewerSendVerificationEmail) {
          setEmailSent(true);
          onEmailSent();

          fireAnalyticsEvent(
            ANALYTICS.CATEGORIES.CREATOR_VERIFICATION,
            ANALYTICS.EVENT.INCORRECT_EMAIL,
            {
              errorMessage: textualize("analytics.noError"),
              outcome: textualize("analytics.success"),
            },
          );
        }
      } catch (e) {
        addToast(
          textualize(
            "onboarding.verifyEmail.unverified.incorrectEmail.error",
          ) as string,
          true,
        );

        fireAnalyticsEvent(
          ANALYTICS.CATEGORIES.CREATOR_VERIFICATION,
          ANALYTICS.EVENT.INCORRECT_EMAIL,
          {
            errorMessage: e.message,
            outcome: textualize("analytics.error"),
          },
        );
      }
    },
    [addToast, onEmailSent, sendVerificationEmail],
  );

  return (
    <Formik
      initialValues={{ newEmail: "" }}
      onSubmit={handleSubmit}
      validate={validation}
    >
      {({ dirty, isSubmitting, isValid, values }: FormikProps<IFormValues>) =>
        emailSent ? (
          <span>
            {textualize(
              "onboarding.verifyEmail.unverified.incorrectEmail.emailSent",
              { newEmail: <strong>{values.newEmail}</strong> },
            )}
          </span>
        ) : (
          <Form>
            <NewEmail
              inputProps={{
                id: IDS.VERIFY_EMAIL.UNVERIFIED.NEW_EMAIL,
                label: textualize(
                  "onboarding.verifyEmail.unverified.incorrectEmail.newEmail.label",
                ) as string,
                required: true,
              }}
              name="newEmail"
            />

            <SendVerificationEmail
              disabled={!dirty || isSubmitting || !isValid}
              id={IDS.VERIFY_EMAIL.UNVERIFIED.SEND_EMAIL}
              isLoading={isSubmitting}
              type="submit"
            >
              {textualize(
                "onboarding.verifyEmail.unverified.incorrectEmail.submit",
              )}
            </SendVerificationEmail>
            <SecondaryButton
              id={IDS.VERIFY_EMAIL.UNVERIFIED.CANCEL}
              onClick={closeForm}
              type="button"
            >
              {textualize("common.cancel")}
            </SecondaryButton>
          </Form>
        )
      }
    </Formik>
  );
}

export default IncorrectEmailForm;
