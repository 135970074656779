export enum BLOCK_TYPE {
  Image = "image",
  MustInclude = "must-include",
  OrderedList = "ordered-list",
  Section = "section",
  Text = "text",
  UnorderedList = "unordered-list",
  Video = "video",
}

// Types which are allowed to be created in the editor
export type EditorBlockTypes = Exclude<
  BLOCK_TYPE,
  BLOCK_TYPE.OrderedList | BLOCK_TYPE.Section | BLOCK_TYPE.UnorderedList
>;

export type BlockValue = (string | BlockValue)[];

export type BlockProperties = {
  ratios?: string | BlockValue;
  value: string | BlockValue;
};

export type Block = {
  content?: string[];
  enabledMarkdown?: string[];
  id: string;
  type: BLOCK_TYPE;
  properties: BlockProperties;
};

export type BlockComponent = {
  block: Block;
  onChange?: (value: any) => void;
};

export interface BlockEditorValidationErrors {
  [sectionID: string]: {
    [blockID: string]: string;
  };
}
