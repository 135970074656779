import VIDEO from "creators/constants/actions/video";
import { IAction } from "shared/types/actions";

export const hideCaptionsAction = (): IAction => ({
  type: VIDEO.HIDE_CAPTIONS,
});

export const muteVideoAction = (): IAction => ({
  type: VIDEO.MUTE,
});

export const pauseVideoAction = (): IAction => ({
  type: VIDEO.PAUSE,
});

export const playVideoAction = (): IAction => ({
  type: VIDEO.PLAY,
});

export const showCaptionsAction = (): IAction => ({
  type: VIDEO.SHOW_CAPTIONS,
});

export const unmuteVideoAction = (): IAction => ({
  type: VIDEO.UNMUTE,
});
