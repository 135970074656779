import { gql } from "@apollo/client";

export const TaxonomyFields = gql`
  fragment TaxonomyFields on Taxon {
    emojiCodePoints
    label
    id
    symbolCodePoints
  }
`;

export const GET_TAXONOMY_BY_CURRENCY_CODE = gql`
  query GetTaxonomyByCurrencyCode(
    $paymentCurrencyCode: String!
    $viewerPaymentCurrencyCode: String!
  ) {
    paymentCurrencyCode: taxonomyByCurrencyCode(
      currencyCode: $paymentCurrencyCode
    ) {
      ...TaxonomyFields
    }
    viewerPaymentCurrencyCode: taxonomyByCurrencyCode(
      currencyCode: $viewerPaymentCurrencyCode
    ) {
      ...TaxonomyFields
    }
  }
  ${TaxonomyFields}
`;

export default {
  GET_TAXONOMY_BY_CURRENCY_CODE,
};
