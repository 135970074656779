import FormInput, {
  IProps as IFormInput,
} from "creators/components/forms/FormInput/FormInput";
import { useField } from "formik";

interface IProps {
  className?: string;
  inputProps?: Omit<IFormInput, "onChange" | "value">;
  name: string;
}

function FormikInput({ className, inputProps, ...props }: IProps) {
  const [field, meta] = useField<string>(props);

  let error;

  if (meta.touched && meta.error) {
    error = meta.error;
  }

  return (
    <FormInput {...field} {...inputProps} className={className} error={error} />
  );
}

export default FormikInput;
