import FormError from "creators/components/forms/FormError";
import FormLabel from "creators/components/forms/FormLabel";
import TextInput from "creators/components/TextInput";
import smallTextStyles from "creators/styling/SmallText";
import { HTMLProps, ReactNode } from "react";
import styled from "styled-components";

export interface IProps
  extends Omit<HTMLProps<HTMLInputElement>, "ref" | "as" | "label" | "value"> {
  error?: string;
  help?: ReactNode | string;
  label?: ReactNode | string;
  value: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const HelpText = styled.div`
  ${smallTextStyles}
  line-height: 1.125rem;
`;

export const FormInput: React.FC<React.PropsWithChildren<IProps>> = ({
  className,
  error,
  help,
  id,
  label,
  ...inputProps
}) => (
  <Container className={className}>
    {label && <FormLabel htmlFor={id}>{label}</FormLabel>}

    {error && (
      <FormError aria-live="assertive" id={`${id}-error`}>
        {error}
      </FormError>
    )}

    <TextInput
      aria-describedby={`${id}-helper ${id}-error`}
      hasError={!!error}
      id={id}
      {...inputProps}
    />

    {help && <HelpText id={`${id}-helper`}>{help}</HelpText>}
  </Container>
);

export default FormInput;
