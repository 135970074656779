import FONTS from "shared/constants/fonts";
import { css } from "styled-components";

export const smallTextStyles = css`
  font-size: 0.625rem;
  font-weight: ${FONTS.WEIGHT.BOLD};
  letter-spacing: 0.85px;
  line-height: 1.5;
  text-transform: uppercase;
`;

export default smallTextStyles;
