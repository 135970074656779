import debounce from "lodash/debounce";
import flattenDeep from "lodash/flattenDeep";
import { useMemo, useState } from "react";
import TextEditor, {
  ENABLED_MARKDOWN,
} from "shared/BlockBuilder/components/TextEditor";
import { Block, BlockProperties } from "shared/BlockBuilder/global";
import { textualize } from "shared/BlockBuilder/utils";
import MARKDOWN from "shared/constants/markdown";
import { Container } from "./styles";

// Allow messaging formatting to be rendered on the creators side
const ENHANCED_ENABLED_MARKDOWN = [...ENABLED_MARKDOWN, MARKDOWN.MESSAGING];

export interface IProps {
  block: Block;
  className?: string;
  error?: string;
  handleChange?: (value: { properties: BlockProperties }) => void;
  id: string;
}

function BriefText({ block, className, error, handleChange, id }: IProps) {
  const [editing, setEditing] = useState(false);

  const value: string[] = Array.isArray(block.properties.value)
    ? flattenDeep(block.properties.value) // TODO: Replace with Array.flat once lib compiler updated
    : [block.properties.value].filter((val) => val !== undefined);

  let content;
  try {
    content = JSON.parse(value[0]);
  } catch {
    // Broken syntax
  }

  const editable = !!handleChange;

  const debouncedHandleChange = useMemo(
    () => debounce(editable ? handleChange : () => null, 250),
    [editable, handleChange],
  );

  return (
    <Container
      className={className}
      editable={editable}
      editing={editing}
      error={error}
      id={id}
    >
      {/* Render the non-editable version of the TextEditor on the creators side so that the Messaging node view is rendered correctly */}
      <div>
        <TextEditor
          aria-label={textualize("briefText.inputLabel")}
          editable={editable}
          enabledMarkdown={
            editable ? block.enabledMarkdown : ENHANCED_ENABLED_MARKDOWN
          }
          initialValue={content}
          onBlur={() => setEditing(false)}
          onChange={(value: string) =>
            debouncedHandleChange!({
              properties: {
                value,
              },
            })
          }
          onFocus={() => setEditing(true)}
        />
      </div>
      {/* Reuse dangerouslySetInnerHTML when it renders the custom Messaging node correctly */}
      {/* <div
          dangerouslySetInnerHTML={{
            __html: content ? generateHTML(content, extensions) : "",
          }}
        /> */}
    </Container>
  );
}

export default BriefText;
