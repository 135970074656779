export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const URL_REGEX = new RegExp(
  "^((http|https)://)" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$", // fragment locator
  "i",
);
export const VIMEO_REGEX =
  /(?:(?:ht|f)tps?:)\/\/(?:www.)?vimeo\.com\/(?:((album\/[a-zA-Z0-9]+\/video|(channels|ondemand)\/[a-zA-Z0-9]+|groups\/[a-zA-Z0-9]+\/videos)\/)?)([a-zA-Z0-9]+)/;
export const YOUTUBE_REGEX =
  /(?:(?:ht|f)tps?:)\/\/(?:www.)?(?:(?:\\youtu.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|vi?\/|watch\?v=|watch\?.&vi?=))((\w|-){11})|youtube\.com\/playlist\?list=)/;
