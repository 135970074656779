import { gql } from "@apollo/client";

export const PageInfoFields = gql`
  fragment PageInfoFields on PageInfo {
    hasPreviousPage
    hasNextPage
  }
`;

export const NextPageOnlyFields = gql`
  fragment NextPageOnlyFields on PageInfo {
    hasNextPage
  }
`;

export default {
  PageInfoFields,
  NextPageOnlyFields,
};
