import { css } from "styled-components";

const shape = css`
  --vui-shape-corner-none: 0;
  --vui-shape-corner-extra-small: 0.25rem;
  --vui-shape-corner-small: 0.5rem;
  --vui-shape-corner-medium: 0.75rem;
  --vui-shape-corner-large: 1rem;
  --vui-shape-corner-extra-large: 1.75rem;
  --vui-shape-corner-full: 1000rem;
`;

export default shape;
