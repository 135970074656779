import { ITimeBetween } from "creators/utils/calculateTimeBetween/calculateTimeBetween";
import textualize from "creators/utils/textualize";
import { ReactNode } from "react";

export const formatTimeBetween = (
  { days, hours, minutes }: ITimeBetween,
  largestOnly = false,
): ReactNode => {
  let time: ReactNode = "";

  const dayUnits = textualize("time.days", { count: days });
  const hourUnits = textualize("time.hours", { count: hours });
  const minuteUnits = textualize("time.minutes", {
    count: minutes,
  });

  const isDaysActive = days > 0;
  const isHoursActive = isDaysActive || hours > 0;

  if (largestOnly) {
    if (isDaysActive && days > 0) {
      time = dayUnits;
    } else if (isHoursActive && hours > 0) {
      time = hourUnits;
    } else {
      time = minuteUnits;
    }
  } else {
    time = [isDaysActive && dayUnits, isHoursActive && hourUnits, minuteUnits]
      .filter((time) => !!time)
      .join(" ");
  }

  return time;
};

export default formatTimeBetween;
