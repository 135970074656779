import Tooltip, { Position } from "creators/components/Tooltip";
import { ImgHTMLAttributes } from "react";
import { Link } from "react-router-dom";
import PlaceholderAvatar from "shared/components/PlaceholderAvatar";
import styled from "styled-components";

export interface IProps extends ImgHTMLAttributes<HTMLImageElement> {
  avatarID: string;
}

const BaseAvatar = styled.img`
  object-fit: cover;
  border-radius: 50%;
  height: 100%;
  width: 100%;
`;

const StyledTooltip = styled(Tooltip)`
  > div {
    &:first-child {
      display: inherit;

      &:focus {
        background: inherit;
        border-radius: inherit;
        height: inherit;
      }
    }

    &:last-child {
      left: 1.5rem;
      transform: translate(-50%, 90%);
      white-space: nowrap;
    }
  }

  > svg {
    height: 100%;
    width: 100%;
  }
`;

const Avatar = ({ alt, avatarID, className, id, src }: IProps) =>
  src ? (
    <BaseAvatar alt={alt} className={className} id={id} src={src} />
  ) : (
    <PlaceholderAvatar
      alt={alt}
      avatarID={avatarID}
      className={className}
      id={id}
    />
  );

export interface IAvatarLink extends IProps {
  href: string;
  imageURI: string;
  label: string;
}

export const AvatarLink = ({
  className,
  href,
  imageURI,
  label,
  ...imgProps
}: IAvatarLink) => (
  <Link className={className} to={href}>
    <Avatar alt={label} src={imageURI} {...imgProps} />
  </Link>
);

export interface IAvatarHoverText extends IProps {
  imageURI: string;
  label: string;
  position?: Position;
}

export const AvatarWithTooltip = ({
  imageURI,
  label,
  position,
  ...imgProps
}: IAvatarHoverText) => (
  <StyledTooltip
    label="Avatar tooltip"
    position={position || Position.Bottom}
    tip={label}
  >
    <Avatar alt={label} src={imageURI} {...imgProps} />
  </StyledTooltip>
);

export default Avatar;
