import { gql } from "@apollo/client";
import { ActiveBriefFields } from "creators/fragments/brief";

export const GET_ACTIVE_BRIEF_PREVIEW = gql`
  query GetActiveBriefPreview($id: ID!, $previewHash: String) {
    brief(id: $id, previewHash: $previewHash) {
      ...ActiveBriefFields
    }
  }
  ${ActiveBriefFields}
`;

export default {
  GET_ACTIVE_BRIEF_PREVIEW,
};
