import DIMENSIONS from "creators/constants/dimensions";
import styled from "styled-components";

const PageContainer = styled.main`
  padding: var(--v-spacing-4);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    padding: var(--v-spacing-12) var(--v-spacing-8);
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding: 3.875rem var(--v-spacing-8);
  }
`;

export const UnauthenticatedPageContainer = styled.div`
  padding: var(--v-spacing-8) var(--v-spacing-4);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    padding: var(--v-spacing-16) 0;
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding: var(--v-spacing-16) 0;
  }
`;

export const FlushPageContainer = styled(PageContainer)`
  padding: 0;
  position: relative;
`;

export default PageContainer;
