import { HTMLAttributes } from "react";
import { secondaryButtonStyles } from "shared/components/Button";
import Icon from "shared/components/Icon";
import COLORS from "shared/constants/colors";
import styled from "styled-components";

interface IPanelButton extends HTMLAttributes<HTMLButtonElement> {
  isActive?: boolean;
  isVisible?: boolean;
  type?: "button" | "reset" | "submit";
}

export const Container = styled.div`
  div.ProseMirror {
    * {
      /* Fix FF issue stripping whitespace */
      white-space: pre-wrap;
      word-wrap: break-word;
    }

    span.messaging {
      border-radius: 0.5rem;
      display: inline;
      padding: 0.125rem 0.25rem;

      > * {
        display: inline;
      }
    }

    ol {
      list-style: decimal;
      margin-left: 1rem;
    }

    ul {
      list-style: disc;
      margin-left: 1rem;

      ul {
        list-style: circle;
      }
    }

    li {
      p {
        margin-bottom: 0.5rem;
      }
    }
  }
`;

export const BubblePanel = styled.div`
  background: ${COLORS.DARK_GREY};
  border: 1px solid ${COLORS.WHITE};
  border-radius: 0.25rem;
  color: ${COLORS.WHITE};
  display: flex;
  padding: 0.5rem;
  position: relative;
`;

export const CopyMessaging = styled.button`
  ${secondaryButtonStyles};
  border: none;
  border-radius: 0;
  color: inherit;
  margin: 0 0 0 0.25rem;
  padding: 0;

  &:active {
    background-color: inherit;
  }

  &:hover {
    box-shadow: unset;
    color: ${COLORS.RED};
  }

  > span {
    border-color: currentColor;
    height: 1rem;
    width: 1rem;
  }
`;

export const InnerRow = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: normal;
  margin-top: 0.5rem;
`;

export const TextLink = styled.button`
  ${secondaryButtonStyles}
  margin: 0;
`;

export const LinkPanel = styled(BubblePanel)`
  flex-direction: column;
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 80%;
`;

export const LinkRow = styled.div<{ hasError?: boolean }>`
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: flex;
`;

export const LinkInput = styled.input<{ hasError?: boolean }>`
  background: ${COLORS.DARK_GREY};
  border: 1px solid #737378;
  color: ${COLORS.WHITE};
  border-radius: 0.25rem;
  font: inherit;
  min-width: 10rem;
  padding: 0.25rem;

  border-bottom-left-radius: ${({ hasError }) => (hasError ? "0" : "0.25rem")};
  border-bottom-right-radius: ${({ hasError }) => (hasError ? "0" : "0.25rem")};
  border-color: ${({ hasError }) => (hasError ? COLORS.RED : "#737378")};

  outline: ${({ hasError }) => (hasError ? "none" : "")};
`;

export const LinkButton = styled.button`
  appearance: none;
  background: transparent;
  border: 0;
  color: ${COLORS.WHITE};
  margin: 0.5rem 0.25rem 0.5rem 0.5rem;
  padding: 0;
  height: 1.5rem;
  width: 1.5rem;

  &:hover {
    color: ${COLORS.RED};
    cursor: pointer;
  }

  &:disabled {
    color: ${COLORS.MID_GREY};
  }
`;

export const InputError = styled.div`
  background: ${COLORS.RED};
  color: ${COLORS.WHITE};
  padding: 0.25rem;
`;

const StyledButton = styled.button<IPanelButton>`
  appearance: none;
  background: none;
  border: ${({ isActive }) =>
    isActive ? `1px solid ${COLORS.WHITE}` : "1px solid transparent"};
  border-radius: 0.25rem;
  color: ${COLORS.WHITE};
  font: inherit;
  height: 2rem;
  width: 2rem;
  margin-right: 0.25rem;
  padding: 0.25rem;

  &:hover {
    color: ${COLORS.RED};
    cursor: pointer;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const PanelButton: React.FC<React.PropsWithChildren<IPanelButton>> = ({
  children,
  isVisible,
  ...props
}) => (isVisible ? <StyledButton {...props}>{children}</StyledButton> : null);

export const StyledIcon = styled(Icon)`
  color: currentColor;
  height: 100%;
  vertical-align: text-top;
  width: 100%;
`;
