import { QueryHookOptions, useQuery } from "@apollo/client";
import { DocumentNode } from "graphql";
import getPaginatedData from "shared/utils/getPaginatedData";

const useLoadMoreQuery = <Q>(
  query: DocumentNode,
  initialMax: number,
  loadMoreMax: number,
  extraOptions?: Partial<QueryHookOptions>,
  pathToPagination?: string[],
) => {
  const getVariables = (max: number, cursor?: string) => ({
    after: cursor,
    first: max,
    ...extraOptions?.variables,
  });

  const { data, error, fetchMore, loading } = useQuery<Q>(query, {
    ...extraOptions,
    variables: getVariables(initialMax, undefined),
  });

  const loadMore = () => {
    let lastCursor;

    if (data) {
      const actualData = getPaginatedData(data, pathToPagination);
      const lastKey = actualData.edges.length - 1;

      lastCursor = actualData.edges![lastKey].cursor;
    }

    return fetchMore({
      variables: getVariables(loadMoreMax, lastCursor),
    });
  };

  return {
    data,
    error,
    loading,
    loadMore,
  };
};

export default useLoadMoreQuery;
