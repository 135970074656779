export interface IMaintenanceMode {
  live: boolean;
  returnEstimation?: string;
}

export enum HealthCheckType {
  Live = "live",
}

export interface HealthCheckResult {
  details?: any;
  type: HealthCheckType;
}

export type HealthCheckTest = Promise<HealthCheckResult>;
