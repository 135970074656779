import GlobalNav from "creators/components/GlobalNav";
import IDS from "creators/constants/ids";
import textualize from "creators/utils/textualize";
import { Link } from "react-router-dom";
import VidsyLogo from "shared/images/vidsy-logo.svg";
import styled from "styled-components";

const Container = styled.nav`
  background: var(--v-color-white);
  border-right: 1px solid var(--v-color-grey-light);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100vh;
  position: sticky;
  top: 0;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  margin: var(--v-spacing-3) 0 0 var(--v-spacing-2);
`;

const StyledVidsyLogo = styled(VidsyLogo)`
  color: var(--v-color-red);
  height: 2.25rem;
`;

const StyledGlobalNav = styled(GlobalNav)`
  flex: 1;
  min-height: 0;
  padding: var(--v-spacing-2);
`;

export const DesktopNav = () => {
  return (
    <Container aria-label={textualize("nav.label") as string}>
      <StyledLink id={IDS.NAV.VIDSY_LOGO} to="/">
        <StyledVidsyLogo
          aria-label={textualize("common.vidsy")}
          preserveAspectRatio="xMinYMid meet"
        />
      </StyledLink>

      <StyledGlobalNav />
    </Container>
  );
};

export default DesktopNav;
