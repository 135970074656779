import { useQuery } from "@apollo/client";
import DataError from "creators/components/DataError";
import EmailUnverified from "creators/components/EmailUnverified";
import EmailVerificationSuccess from "creators/components/EmailVerificationSuccess";
import LoadingDelayed from "creators/components/LoadingDelayed";
import WindowLayout from "creators/components/WindowLayout";
import IDS from "creators/constants/ids";
import { GET_VIEWER } from "creators/queries/GetViewer";
import {
  EmailVerificationStage,
  GetViewerQuery as IGetViewer,
} from "creators/types/graphql";
import onboardingIncomplete from "creators/utils/onboardingIncomplete";
import { Redirect } from "react-router";

function VerifyEmail() {
  const { data, loading, error } = useQuery<IGetViewer>(GET_VIEWER);

  if (loading) {
    return <LoadingDelayed />;
  }

  if (error) {
    return <DataError error={error} />;
  }

  if (!data?.viewer) {
    return null;
  }

  const {
    email,
    emailVerificationStage,
    firstName,
    termsAndConditionsAccepted,
  } = data.viewer;

  // If a user attempts to access the verify email page directly and they have not accepted the Vidsy T&Cs,
  // redirect them to the Terms and Conditions page.
  if (!termsAndConditionsAccepted) {
    return <Redirect to="/terms-and-conditions" />;
  }

  const verificationSuccessful =
    emailVerificationStage === EmailVerificationStage.Verified;
  const linkToOnboarding = onboardingIncomplete(data.viewer);

  return (
    <WindowLayout
      firstName={firstName}
      id={
        verificationSuccessful
          ? IDS.VERIFY_EMAIL.SUCCESS.PAGE
          : IDS.VERIFY_EMAIL.UNVERIFIED.PAGE
      }
    >
      {verificationSuccessful ? (
        <EmailVerificationSuccess linkToOnboarding={linkToOnboarding} />
      ) : (
        <EmailUnverified email={email} />
      )}
    </WindowLayout>
  );
}

export default VerifyEmail;
