export const copyToClipboard = async (text: string) => {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text);
    return;
  }

  const field = document.createElement("textarea");
  field.innerText = text;

  field.style.top = "0";
  field.style.left = "0";
  field.style.opacity = "0";
  field.style.position = "fixed";

  document.body.appendChild(field);
  field.select();
  document.execCommand("copy");
  document.body.removeChild(field);
};

export default copyToClipboard;
