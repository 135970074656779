import { gql } from "@apollo/client";
import { ViewerFields } from "creators/queries/GetViewer";

export const UPDATE_CREATOR = gql`
  mutation updateCreator($input: UpdateCreatorInput!) {
    viewerUpdateCreator(input: $input) {
      ...ViewerFields
    }
  }
  ${ViewerFields}
`;

export default {
  UPDATE_CREATOR,
};
