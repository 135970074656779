import AnimatedIcon from "creators/components/AnimatedIcon";
import DIMENSIONS from "creators/constants/dimensions";
import HelpIcon from "creators/images/animatedIcons/help.json";
import ErrorPage from "creators/pages/Error";
import VuiErrorPage from "creators/pages/vui/Error";
import textualize from "creators/utils/textualize";
import { ComponentType } from "react";
import useFeature from "shared/hooks/useFeature";
import { useHistory } from "react-router-dom";
import ErrorBoundary from "shared/components/ErrorBoundary";
import styled from "styled-components";

export interface IProps {
  children: React.ReactNode;
  fallback?: ComponentType<any>;
}

const StyledAnimatedIcon = styled(AnimatedIcon)`
  height: 1.9rem;
  margin-bottom: var(--v-spacing-4);
  width: 1.75rem;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    height: 3.8125rem;
    margin-bottom: var(--v-spacing-8);
    width: 3.5rem;
  }

  path {
    /* To override direct CSS styling */
    stroke: var(--v-color-white) !important;
  }
`;

export const VuiFallbackComponent = () => (
  <VuiErrorPage>
    <StyledAnimatedIcon path={HelpIcon} playing={true} />
    <h1>{textualize("error.fallback.heading")}</h1>
    <span>{textualize("error.fallback.body")}</span>
  </VuiErrorPage>
);

export const FallbackComponent = () => (
  <ErrorPage>
    <StyledAnimatedIcon path={HelpIcon} playing={true} />
    <h1>{textualize("error.fallback.heading")}</h1>
    <span>{textualize("error.fallback.body")}</span>
  </ErrorPage>
);

export const CrashReporterBoundary = ({ children, fallback: X }: IProps) => {
  let Fallback = X;

  const creatorsVUI = useFeature("creatorsVUI");

  if (!Fallback) {
    if (creatorsVUI) {
      Fallback = VuiFallbackComponent;
    } else {
      Fallback = FallbackComponent;
    }
  }

  const history = useHistory();

  return (
    <ErrorBoundary FallbackComponent={Fallback} history={history}>
      {children}
    </ErrorBoundary>
  );
};

export default CrashReporterBoundary;
