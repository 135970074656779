import DIMENSIONS from "creators/constants/dimensions";
import { StyledVidsyLogo, Wrapper } from "creators/pages/Error/Error";
import textualize from "creators/utils/textualize";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import styled from "styled-components";

export interface IProps {
  returnEstimation?: string;
}

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  > {
    h1 {
      margin-bottom: var(--v-spacing-12);
      font-size: 1.5rem;
      line-height: 1.5;

      @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
        font-size: 3rem;
        line-height: 1.25;
      }
    }

    div {
      margin-bottom: var(--v-spacing-8);
      white-space: break-spaces;
    }
  }
`;

export const MaintenanceMode = ({
  returnEstimation: returnEstimationString,
}: IProps) => {
  let returnEstimation: Date | undefined;

  if (returnEstimationString) {
    returnEstimation = new Date(returnEstimationString);
  }

  return (
    <Wrapper>
      <Helmet title={textualize("maintenanceMode.heading") as string} />

      <StyledVidsyLogo />

      <Content>
        <h1>{textualize("maintenanceMode.heading")}</h1>

        <div>{textualize("maintenanceMode.body")}</div>

        {returnEstimation && (
          <>
            <div>
              {textualize("maintenanceMode.returnEstimation", {
                time: format(returnEstimation, "h:mmaaa"),
              })}
            </div>
          </>
        )}

        <div>{textualize("maintenanceMode.intercom")}</div>
      </Content>

      {/* Empty div keeps main content centered */}
      <div />
    </Wrapper>
  );
};

export default MaintenanceMode;
