import {
  BlockContainer,
  errorStyling,
  InnerContainer,
  IProps as IBlockContainerProps,
} from "creators/components/Brief/BlockContainer/BlockContainer";
import DIMENSIONS from "creators/constants/dimensions";
import FONTS from "creators/constants/fonts";
import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";

export const ExpandingTextarea = styled(TextareaAutosize)`
  border: none;
  font: inherit;
  min-height: 120%;
`;

export const Container = styled(BlockContainer)<IBlockContainerProps>`
  max-width: none;
  margin: 0 var(--v-spacing-4) var(--v-spacing-8);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    margin: 0 var(--v-spacing-16) var(--v-spacing-24);
  }

  ${InnerContainer} {
    border: 0.125rem solid var(--v-color-blue);
    border-radius: 0.5rem;
    font-size: 1.25rem;
    font-weight: ${FONTS.WEIGHT.SEMI_BOLD};
    overflow: hidden; /* Must Include header overlaps border without this */

    ${({ error }) => error && errorStyling}

    &:last-child {
      margin-bottom: 0;
    }

    ul {
      list-style: disc;
      padding: var(--v-spacing-4) var(--v-spacing-10);

      li {
        margin-bottom: var(--v-spacing-4);
        &:last-child {
          margin-bottom: 0;
        }

        ${ExpandingTextarea} {
          resize: none;
          vertical-align: top;
          width: calc(100% - 2rem);
        }
      }
    }

    @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
      font-size: 1.5rem;

      ul {
        columns: 2;
        padding: var(--v-spacing-8) var(--v-spacing-16) 0;

        li {
          margin-bottom: 0;

          > div {
            vertical-align: top;
          }

          > div,
          > textarea {
            border-bottom: 1px solid transparent;
            display: inline-block;
            margin-bottom: var(--v-spacing-8);
            width: 100%;
          }

          > textarea {
            &:focus {
              border-color: var(--v-color-blue);
              outline: none;
            }
          }
        }
      }
    }
  }
`;

export const Header = styled.h4`
  background: var(--v-color-blue);
  color: var(--v-color-white);
  font-size: 1.5rem;
  font-weight: ${FONTS.WEIGHT.BOLD};
  padding: var(--v-spacing-4);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    font-size: 2rem;
    padding: var(--v-spacing-6) var(--v-spacing-8);
  }
`;
