import BriefCard, {
  IProps as IBriefCardProps,
} from "creators/components/BriefCard/BriefCard";
import { SecondaryButton } from "creators/components/Button";
import textualize from "creators/utils/textualize";
import styled from "styled-components";

export type IBrief = Omit<IBriefCardProps, "className" | "id">;

export const Grid = styled.ul`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  margin-bottom: var(--v-spacing-8);

  @media (min-width: 60rem) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const LoadMoreButton = styled(SecondaryButton)`
  display: block;
  margin: 0 auto;
`;

export interface IProps {
  className?: string;
  briefs: IBrief[];
  id: string;
  loading: boolean;
  loadMore: () => void;
  moreBriefsAvailable?: boolean;
}

const BriefGrid: React.FC<React.PropsWithChildren<IProps>> = ({
  briefs,
  className,
  id,
  loading,
  loadMore,
  moreBriefsAvailable,
}) => (
  <div className={className} id={id}>
    <Grid>
      {briefs.map((brief) => (
        <li key={brief.briefID}>
          <BriefCard {...brief} />
        </li>
      ))}
    </Grid>

    {moreBriefsAvailable && (
      <LoadMoreButton
        disabled={loading}
        id={`${id}LoadMore`}
        isLoading={loading}
        onClick={loadMore}
      >
        {textualize("common.loadMore")}
      </LoadMoreButton>
    )}
  </div>
);

export default BriefGrid;
