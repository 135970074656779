import { ReactNode } from "react";
import styled from "styled-components";

export const Container = styled.div`
  border-radius: 0.25rem;
  display: flex;
  height: 0.5rem;
  overflow: hidden;
`;

export const Step = styled.div<{ complete: boolean }>`
  background: ${({ complete }) =>
    complete ? "var(--v-color-red)" : "var(--v-color-grey-light)"};
  flex: 1;

  :not(:last-child) {
    border-right: 0.125rem solid var(--v-color-white);
  }
`;

export interface IProps {
  "aria-label": string;
  className?: string;
  id?: string;
  step: number;
  totalSteps: number;
}

export const SteppedProgress: React.FC<React.PropsWithChildren<IProps>> = ({
  "aria-label": ariaLabel,
  className,
  id,
  step,
  totalSteps,
}) => {
  const steps: ReactNode[] = [];

  for (let i = 1; i <= totalSteps; i++) {
    steps.push(<Step complete={i <= step} key={i} />);
  }

  return (
    <Container
      aria-label={ariaLabel}
      aria-valuemax={totalSteps}
      aria-valuemin={0}
      aria-valuenow={step}
      className={className}
      id={id}
      role="progressbar"
    >
      {steps}
    </Container>
  );
};

export default SteppedProgress;
