import AnimatedIcon from "creators/components/AnimatedIcon";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const ActiveIcon = styled.span`
  background: var(--v-color-red);
  border-radius: 100%;
  height: 0.5rem;
  margin-left: auto;
  width: 0.5rem;
`;

export const linkStyles = css`
  align-items: center;
  border-radius: 0.25rem;
  color: inherit;
  display: flex;
  font-size: 0.75rem;
  padding: var(--v-spacing-2);
  text-decoration: none;

  &.active {
    background: rgba(var(--v-color-grey-light-rgb), 0.5);
  }
`;

export const hoverStyles = css`
  :focus,
  :hover {
    background: var(--v-color-grey-light);
  }
`;

export const StyledAnimatedIcon = styled(AnimatedIcon)`
  height: 1.5rem;
  margin-right: var(--v-spacing-3);
  width: 1.5rem;
`;

export const Disabled = styled.div`
  ${linkStyles};

  ${StyledAnimatedIcon}, span {
    opacity: 0.5;
  }
`;

export const StyledAnchor = styled.a`
  ${linkStyles};
  ${hoverStyles};
`;

export const StyledButton = styled.button`
  ${hoverStyles};
  appearance: none;
  background: none;
  border: none;
  border-radius: 0.25rem;
  color: inherit;
  cursor: pointer;
  display: block;
  font: inherit;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const StyledButtonInner = styled.div`
  ${linkStyles};
`;

export const StyledNavLink = styled(NavLink)`
  ${linkStyles};
  ${hoverStyles};
`;
