export default {
  ACCOUNT_SETTINGS: {
    CHANGE_EMAIL: "accountSettingsChangeEmail",
    EMAIL: {
      ID: "accountSettingsEmail",
      UNVERIFIED: "accountSettingsEmailUnverified",
      VERIFIED: "accountSettingsEmailVerified",
    },
    NEW_EMAIL: {
      CANCEL: "accountSettingsCancel",
      ID: "accountSettingsNewEmail",
      SEND: "accountSettingsSendVerificationEmail",
    },
    RESET_PASSWORD: "accountSettingsResetPassword",
  },
  ACHIEVEMENTS: {
    FEEDBACK: {
      ID: "achievementsFeedbackPrompt",
      LINK: "achievementsFeedbackLink",
    },
    PAGE: "achievementsPage",
    BADGES: {
      COMPLETED_BRIEFS: "achievementsBadgesCompletedBriefs",
      ID: "achievementsBadges",
      MONEY_EARNINGS: "achievementsBadgesMoneyEarnings",
      VIDSY_BIRTHDAY: "achievementsBadgesVidsyBirthday",
    },
  },
  ACTIVE_BRIEF: {
    PAGE: "activeBriefPage",
    NAV: "activeBriefNav",
  },
  BRIEF: {
    HELPFUL_GUIDES: {
      AE_PROJECT_SETUP: "briefHelpfulGuidesAEProjectSetup",
      FINAL_DELIVERY: "briefHelpfulGuidesFinalDelivery",
      ID: "briefHelpfulGuides",
    },
    INTRODUCTION: {
      BLOCK: "briefIntroductionBlock",
      META_STATS: "briefIntroductionMetaStats",
      OVERVIEW_STATS: {
        BRIEF_LEAD: "briefIntroductionOverviewStatsBriefLead",
        CREATORS: "briefIntroductionOverviewStatsCreators",
        DEADLINE: "briefIntroductionOverviewStatsDeadline",
        ID: "briefIntroductionOverviewStats",
        PAYMENT: "briefIntroductionOverviewStatsPayment",
      },
      PAYMENT_TOOLTIP: "briefIntroductionPaymentTooltip",
    },
    VIDEOS: {
      BLOCK: "briefVideoBlock",
      SPECIFICATIONS: {
        ASPECT_RATIO: "briefVideoSpecificationAspectRatio",
        LENGTH: "briefVideoSpecificationLength",
        PLATFORM: "briefVideoSpecificationPlatform",
      },
      SUMMARY: "briefVideoSummary",
    },
  },
  BRIEF_ACTIONS: {
    ACCEPT: "briefActionsAccept",
    ASSETS: "briefActionsAssets",
    CONFIRM_DECLINE: {
      CANCEL: "briefActionsConfirmDeclineCancel",
      CONFIRM: "briefActionsConfirmDeclineConfirm",
    },
    DECLINE: "briefActionsDecline",
    SUBMIT: "briefActionsSubmit",
  },
  BRIEF_CARD: {
    APPLICATION_STATE: "briefCardApplicationState",
    BRAND_IMAGE: "briefCardBrandImage",
    BRAND_NAME: "briefCardBrandName",
    BRIEF_TYPE: "briefCardBriefType",
    COMPONENT: "briefCard",
    PAYMENT_AMOUNT: "briefCardPaymentAmount",
    SUMMARY: "briefCardSummary",
    TITLE: "briefCardTitle",
  },
  BRIEF_HEADER: {
    BRAND: {
      IMAGE: "briefHeaderBrandImage",
      NAME: "briefHeaderBrandName",
    },
    BRIEF: {
      SUMMARY: "briefHeaderBriefSummary",
      TITLE: "briefHeaderBriefTitle",
    },
    TYPE: {
      CGC: "briefHeaderTypeCGC",
      REMIX: "briefHeaderTypeRemix",
    },
  },
  BRIEF_RELEASE_FORMS: {
    LOCATION: {
      CONTAINER: "briefReleaseFormsLocationContainer",
    },
    CONTRIBUTOR: {
      CONTAINER: "briefReleaseFormsContributerContainer",
    },
  },
  BRIEF_SUBMIT: {
    AE_FILES: "briefSubmitAEFilesUpload",
    FINAL_SUBMISSION: {
      CHECKED_FILES: "briefSubmitFinalSubmissionCheckedFiles",
      ID: "briefSubmitFinalSubmission",
      NO_ACCESS: "briefSubmitFinalSubmissionNoAccess",
      SUBMIT: "briefSubmitFinalSubmissionSubmit",
      TERMS_CONDITIONS: "briefSubmitFinalSubmissionTermsConditions",
    },
    HEADER: {
      FINAL_SUBMISSION: "briefSubmitHeaderFinalSubmission",
      TIME_LEFT: "briefSubmitHeaderTimeLeft",
    },
    SUPPORTING_FILES: "briefSubmitSupportingFilesUpload",
    VIDEO_BLOCK: {
      ASPECT_RATIO: "briefSubmitVideoBlockAspectRatio",
      ID: "briefSubmitVideoBlock",
      LENGTH: "briefSubmitVideoBlockLength",
      MUST_INCLUDE: "briefSubmitVideoBlockMustInclude",
      PLATFORM: "briefSubmitVideoBlockPlatform",
      TITLE: "briefSubmitVideoBlockTitle",
    },
  },
  BRIEF_TEASER: {
    CGC: {
      SURVEY: "briefTeaserCGCSurvey",
    },
    CLOSE: "briefTeaserClose",
    FOOTER: {
      APPLY_NOW: "briefTeaserFooterApplyNow",
      ID: "briefTeaserFooter",
      TERMS_AND_CONDITIONS: "briefTeaserFooterTermsAndConditions",
      WITHDRAW_APPLICATION: "briefTeaserFooterWithdrawApplication",
    },
    HEADER: "briefTeaserHeader",
    INTRODUCTION: {
      BLOCK: "briefTeaserIntroductionBlock",
      CONTAINER: "briefTeaserIntroduction",
    },
    PAGE: "briefTeaserPage",
    SUMMARY: {
      ASSETS: "briefTeaserSummaryProvidedAssets",
      DEADLINE: "briefTeaserSummarySubmissionDeadline",
      DELIVERABLES: "briefTeaserSummaryDeliverables",
      LOCATION: "briefTeaserSummaryFilmingLocation",
      NUMBER_OF_CREATORS: "briefTeaserSummaryNumberOfCreators",
      PAYMENT: {
        ID: "briefTeaserSummaryPayment",
        TOOLTIP: "briefTeaserSummaryPaymentTooltip",
      },
      PROPS: "briefTeaserSummaryProps",
      STARRING: "briefTeaserSummaryStarring",
      TONE: "briefTeaserSummaryTone",
      WORKING_TIME: "briefTeaserSummaryWorkingTime",
    },
  },
  CHANGE_PASSWORD: {
    PAGE: "changePasswordPage",
    PASSWORD: "changePasswordPassword",
    SUBMIT: "changePasswordSubmit",
  },
  LIVE_BRIEFS: {
    ACTIVE_BRIEF_PROMPT: {
      CONTAINER: "liveBriefsActiveBriefPrompt",
      LINK: "liveBriefsActiveBriefPromptLink",
    },
    CGC: "liveBriefsCGC",
    REMIX: "liveBriefsRemix",
    PAGE: "liveBriefsPage",
    TAB: {
      CGC: "liveBriefsTabCGC",
      REMIX: "liveBriefsTabRemix",
    },
    TAB_PANEL: {
      CGC: "liveBriefsTabPanelCGC",
      REMIX: "liveBriefsTabPanelRemix",
    },
  },
  LOGIN: {
    CREATE_ACCOUNT: "loginCreateAccount",
    EMAIL: "loginEmail",
    ERROR_MESSAGE: "loginErrorMessage",
    PAGE: "loginPage",
    PASSWORD: "loginPassword",
    SIGN_IN: "loginSignIn",
  },
  MODALS: {
    BRIEF_FEEDBACK: {
      ID: "modalsBriefFeedback",
    },
    SESSION_EXPIRED: {
      ID: "modalsSessionExpired",
      LOGIN_LINK: "modalsSessionExpiredLoginLink",
    },
    SUBMIT_RELEASE_FORMS_ERROR: {
      ID: "modalSubmitReleaseFormsError",
    },
  },
  NAV: {
    ACHIEVEMENTS: "navAchievements",
    ACTIVE_BRIEF: "navActiveBrief",
    ACTIVE_ICON: "navActiveIcon",
    CLOSE: "navClose",
    CREATOR_HUB: "navCreatorHub",
    HELP: "navHelp",
    HOME: "navHome",
    MENU_TOGGLE: "navMenuToggle",
    PAYMENTS: "navPayments",
    PREVIOUS_BRIEFS: "navPreviousBriefs",
    PROFILE: "navProfile",
    SETTINGS: "navSettings",
    SIGN_OUT: "navSignOut",
    VIDSY_LOGO: "navVidsyLogo",
  },
  ONBOARDING: {
    BACK: "onboardingNavigateBack",
    CURRENCY: {
      ID: "onboardingCurrency",
      INPUT: "onboardingCurrencyInput",
      LINK: "onboardingPaymentCurrencyLink",
      VIDEO: "onboardingCurrencyVideo",
    },
    LATEST_BRIEFS: "onboardingLatestBriefs",
    LOCATION: {
      ID: "onboardingLocation",
      INPUT: "onboardingLocationInput",
      VIDEO: "onboardingLocationVideo",
    },
    NEXT: "onboardingNavigateNext",
    PAYEE_NAME: {
      ID: "onboardingPayeeName",
      INPUT: "onboardingPayeeNameInput",
      LINK: "onboardingHowPaymentsWorkLink",
      VIDEO: "onboardingPayeeNameVideo",
    },
    PAGE: "onboardingPage",
    PORTFOLIO: {
      ID: "onboardingPortfolio",
      INPUT: "onboardingPortfolioInput",
      VIDEO: "onboardingPortfolioVideo",
    },
    PROGRESS: "onboardingProgress",
    SKILLS: {
      ID: "onboardingSkills",
      VIDEO: "onboardingSkillsVideo",
    },
  },
  PAYMENT_SETTINGS: {
    CURRENCY: "paymentSettingsCurrency",
    EMAIL: "paymentSettingsEmail",
    FULL_NAME: "paymentSettingsFullName",
    LOCATION: "paymentSettingsLocation",
    SUBMIT: "paymentSettingsSubmit",
  },
  PAYMENTS: {
    APPLY_TO_BRIEFS: "paymentsApplyToBriefs",
    BASE: "paymentsBasePayment",
    BONUS: "paymentsBonusPayment",
    BRAND_IMAGE: "paymentsBrandImage",
    BRAND_NAME: "paymentsBrandName",
    BRIEF_TITLE: "paymentsBriefTitle",
    DOWNLOAD_CSV: {
      ID: "paymentsDownloadCSV",
      TIP: "paymentsDownloadCSVTooltip",
    },
    EXPENSE: "paymentsExpenseTooltip",
    LOAD_MORE: "paymentsLoardMorePayments",
    NO_PAYMENTS: "paymentsNoPayments",
    PAGE: "paymentsPage",
    PAYMENT: "paymentsPagePayment",
    STATUS_EXPECTED: "paymentsStatusPaymentExpected",
    STATUS_PAID: "paymentsStatusPaymentPaid",
    SUMMARY: {
      LIFETIME: "paymentsSummaryLifetimePayments",
      MONTH: "paymentsSummaryPaymentsThisMonth",
      UPCOMING: "paymentsSummaryUpcomingPayments",
    },
    TOTAL: "paymentsTotalPayment",
    YOUR_PAYMENTS: "paymentsYourPayments",
  },
  PREVIOUS_BRIEF: {
    CREATOR: "previousBriefCreator",
    CREATOR_VIDEO: "previousBriefCreatorVideo",
    PAGE: "previousBriefPage",
    RECOMMENDED: {
      TAG: "previousBriefRecommendedTag",
      TOOLTIP: "previousBriefRecommendedTooltip",
    },
    RECOMMENDED_VIDEOS: "previousBriefRecommendedVideos",
    UPLOADED_VIDEO: "previousBriefUploadedVideo",
    VIDEO_POSITION: "previousBriefVideoPosition",
    VIDEO_SUMMARY: "previousBriefVideoSummary",
    VIDEO_TITLE: "previousBriefVideoTitle",
  },
  PREVIOUS_BRIEFS: {
    EASTER_EGG: "EasterEgg",
    BRIEF: "previousBriefsBrief",
    PAGE: "previousBriefsPage",
  },
  PROFILE: {
    AVATAR: "profileAvatar",
    BIO: "profileBio",
    LOCATION: "profileLocation",
    NAME: "profileName",
    PAGE: "profilePage",
    PORTFOLIO: "profilePortfolio",
    SKILLS: {
      CGC: "profileSkillsCGC",
      ID: "profileSkills",
      REMIX: "profileSkillsRemix",
    },
  },
  PROFILE_SETTINGS: {
    AVATAR: {
      ERROR: {
        SIZE: "profileSettingsAvatarErrorSize",
        TYPE: "profileSettingsAvatarErrorType",
      },
      FILE_NAME: "profileSettingsAvatarFileName",
      IMAGE: "profileSettingsAvatarImage",
      UPLOAD: "profileSettingsAvatarUpload",
    },
    BIO: {
      CHARACTER_COUNT: "profileSettingsBioCharacterCount",
      ID: "profileSettingsBio",
    },
    FIRST_NAME: "profileSettingsFirstName",
    LAST_NAME: "profileSettingsLastName",
    PORTFOLIO: "profileSettingsPortfolio",
    PRIVATE: "profileSettingsPrivate",
    SECTIONS: {
      ABOUT: "profileSettingsAbout",
      BASIC_INFORMATION: "profileSettingsBasicInformation",
      SKILLS: "profileSettingsSkills",
    },
    SKILLS: {
      CGC: "profileSettingsSkillsCGC",
      REMIX: "profileSettingsSkillsRemix",
      VALIDATION: "profileSettingsSkillsValidation",
    },
    SUBMIT: "profileSettingsSubmit",
  },
  REGISTER: {
    AGE_VERIFICATION: {
      ID: "registerAgeVerification",
      TOOLTIP: "registerAgeVerificationTooltip",
    },
    CREATE_ACCOUNT: "registerCreateAccount",
    EMAIL: "registerEmail",
    FIRST_NAME: "registerFirstName",
    LAST_NAME: "registerLastName",
    LEGAL_AGREEMENT: "registerLegalAgreement",
    LOGIN: "registerLogin",
    PAGE: "registerPage",
    PASSWORD: "registerPassword",
    PRIVACY_POLICY: "registerPrivacyPolicy",
    TERMS_AND_CONDITIONS: "registerTermsAndConditions",
  },
  RELEASE_FORMS_ENTRY: {
    AGE_CHECK: "releaseFormsEntryAgeCheck",
    EDIT: {
      CANCEL: "releaseFormsEntryEditCancel",
      SAVE: "releaseFormsEntryEditSave",
    },
    NAME: {
      CONTRIBUTOR: "releaseFormsEntryNameContributor",
      LOCATION: "releaseFormsEntryNameLocation",
    },
    SUBMIT: "releaseFormsEntrySubmit",
  },
  RESET_PASSWORD: {
    EMAIL: "resetPasswordEmail",
    LOGIN: "resetPasswordLogin",
    PAGE: "resetPasswordPage",
    SUBMIT: "resetPasswordSubmit",
  },
  SKILLS: {
    CGC: "skillsCGC",
    REMIX: "skillsRemix",
  },
  SUBMIT: {
    DROPZONE: "submitDropzone",
    FILE_NAME: "submitFileName",
    PREVIEW: {
      DELETE: "submitPreviewDeleteButton",
      PLAY: "submitPreviewPlayButton",
    },
    PROGRESS: {
      CANCEL: "submitProgressCancel",
      PERCENTAGE: "submitProgressPercentage",
    },
    STATUS: "submitStatus",
  },
  TERMS_AND_CONDITIONS: {
    AGREE: "termsAndConditionsAgree",
    PAGE: "termsAndConditionsPage",
    PRIVACY_POLICY: "termsAndConditionsPrivacyPolicy",
    SAVE: "termsAndConditionsSave",
    LINK: "termsAndConditionsLink",
  },
  UPLOAD_CARD: {
    CONTAINER: "uploadCard",
    DELETE: {
      BUTTON: "uploadCardDelete",
      CONFIRM: {
        NO: "uploadCardDeleteConfirmNo",
        YES: "uploadCardDeleteConfirmYes",
      },
    },
    DROPZONE: {
      INPUT: "uploadCardDropzoneInput",
    },
    FILE_NAME: "uploadCardFileName",
    PREVIEW: {
      PLAY: "uploadCardPreviewPlay",
    },
    PROCESSING: {
      SPINNER: "uploadCardProcessingSpinner",
    },
    STATUS: "uploadCardStatus",
    UPLOADING: {
      CANCEL: "uploadCardUploadingCancel",
      PROGRESS: "uploadCardUploadingProgress",
    },
  },
  VERIFY_EMAIL: {
    SUCCESS: {
      PAGE: "verifyEmailSuccessPage",
      START: "verifyEmailSuccessStart",
      VIDEO: "verifyEmailSuccessVideo",
    },
    UNVERIFIED: {
      CANCEL: "verifyEmailUnverifiedCancel",
      CHANGE_EMAIL: "verifyEmailUnverifiedChangeEmail",
      NEW_EMAIL: "verifyEmailUnverifiedNewEmail",
      PAGE: "verifyEmailUnverifiedPage",
      RESEND_EMAIL: "verifyEmailUnverifiedResendEmail",
      SEND_EMAIL: "verifyEmailUnverifiedSendEmail",
    },
  },
};
