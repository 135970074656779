import { forwardRef } from "react";
import ReactSelect, {
  GroupBase,
  Props as ReactSelectProps,
  SelectInstance,
} from "react-select";
import COLORS from "shared/constants/colors";
import DropdownCaret from "shared/images/icons/dropdown-caret.svg";
import { IOption } from "shared/types/components/Dropdown";
import isOptionDisabled from "shared/utils/isOptionDisabled";
import styled from "styled-components";
import {
  MultiValueContainer,
  MultiValueLabel,
  MultiValueRemove,
  Option,
  SingleValue,
  createDropdownIndicator,
} from "../DropdownComponents";
import {
  controlStyling,
  dropdownStyling,
  groupStyling,
  indicatorStyling,
  optionStyling,
  valueStyling,
} from "../InlineDropdown/DropdownStyling";

export const DropdownIndicator = createDropdownIndicator(DropdownCaret);

export const UnstyledDropdown = forwardRef(
  <
    Option extends IOption = IOption,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: ReactSelectProps<Option, IsMulti, Group>,
    ref: React.MutableRefObject<SelectInstance<Option, IsMulti, Group>>,
  ) => {
    const customComponents = {
      DropdownIndicator,
      MultiValueLabel,
      MultiValueContainer,
      MultiValueRemove,
      Option,
      SingleValue,
    };

    return (
      <ReactSelect
        classNamePrefix="dropdown" // Required in order to style by class
        components={customComponents}
        isClearable={false}
        isOptionDisabled={isOptionDisabled}
        isSearchable={true}
        ref={ref}
        {...props}
      />
    );
  },
);

export const Dropdown = styled(UnstyledDropdown)`
  ${controlStyling}
  ${dropdownStyling}
  ${groupStyling}
  ${indicatorStyling}
  ${optionStyling}
  ${valueStyling}

  &:hover {
    & .dropdown__control {
      box-shadow: 0 0 0 2px ${COLORS.BLUE};
    }
  }

  & .dropdown__control--is-focused {
    box-shadow: 0 0 0 2px ${COLORS.BLUE};
  }

  & .dropdown__control--menu-is-open {
    box-shadow: 0 0 0 2px ${COLORS.BLUE};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  & .dropdown__menu {
    border-radius: 0;
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
    box-shadow:
      0 2px 0 0 ${COLORS.BLUE},
      2px 2px 0 0 ${COLORS.BLUE},
      -2px 2px 0 ${COLORS.BLUE};
    margin-top: 0;
    z-index: 2;
  }

  & .dropdown__menu-list {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 0.25rem;
      border: 0.125rem solid transparent;
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
    }
  }

  & .dropdown__dropdown-indicator {
    background: ${COLORS.WHITE};
    color: ${COLORS.DARK_GREY};

    &:hover {
      color: ${COLORS.DARK_GREY};
    }
  }
`;

export default Dropdown;
