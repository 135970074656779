import styled from "styled-components";
export const VUIEnabledLabel = styled.div`
  &::before {
    background: red;
    content: "VUI Enabled";
    color: white;
    display: inline-block;
    font-size: 0.5rem;
    font-weight: bold;
    padding: 0.5rem;
    position: fixed;
    right: 1rem;
    top: 0;
    z-index: 5;
  }
`;

export default VUIEnabledLabel;
