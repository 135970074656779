import { SESSION_KEY } from "shared/hooks/useAnalytics/useAnalytics";
import Analytics from "shared/services/Analytics";
import Application from "shared/types/application";
import isApp from "shared/utils/isApp";
import snakeKeys from "shared/utils/snakeKeys";

export const fireAnalyticsEvent = (
  category: string,
  event: string,
  params?: Record<string, unknown>,
) => {
  // Fire analytics events as long as they have not been overridden
  const analyticsDisabled = window.sessionStorage.getItem(SESSION_KEY);

  const outgoingParams = [
    Application.Clients,
    Application.Creators,
    Application.Internal,
  ].some(isApp)
    ? snakeKeys(params)
    : params;

  if (analyticsDisabled !== "true") {
    Analytics.fireEvent(event, {
      ...outgoingParams,
      category,
    });
  }
};

export default fireAnalyticsEvent;
