import FONTS from "creators/constants/fonts";
import { HTMLAttributes } from "react";
import styled from "styled-components";

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  isWarning?: boolean;
}

export const Toast = styled.div<IProps>`
  background: ${({ isWarning }) =>
    isWarning ? "var(--v-color-red)" : "var(--v-color-green-active)"};
  border-radius: 0.5rem;
  color: ${({ isWarning }) =>
    isWarning ? "var(--v-color-white)" : "var(--v-color-black)"};
  display: inline-block;
  font-weight: ${FONTS.WEIGHT.SEMI_BOLD};
  padding: var(--v-spacing-2) var(--v-spacing-4);
  line-height: 1;
`;

export default Toast;
