import Avatar from "boring-avatars";
import { ImgHTMLAttributes } from "react";
import COLORS from "shared/constants/colors";
import styled from "styled-components";

export interface IProps extends ImgHTMLAttributes<HTMLImageElement> {
  avatarID: string;
  square?: boolean;
}

const PLACEHOLDER_COLORS = [
  COLORS.BLUE,
  COLORS.GREEN,
  COLORS.PINK_DARK,
  COLORS.RED,
  COLORS.YELLOW,
];

const Container = styled.div<{ square?: boolean }>`
  border-radius: ${({ square = false }) => !square && "100%"};
  overflow: ${({ square = false }) => !square && "hidden"};
`;

const PlaceholderAvatar = ({
  alt,
  avatarID,
  className,
  id,
  square,
}: IProps) => (
  <Container aria-label={alt} className={className} id={id} square={square}>
    <Avatar
      colors={PLACEHOLDER_COLORS}
      name={avatarID}
      size="100%"
      square={true}
      variant="beam"
    />
  </Container>
);

export default PlaceholderAvatar;
