import { useQuery } from "@apollo/client";
import DataError from "creators/components/DataError";
import GuardedRoute from "creators/components/GuardedRoute";
import LoadingDelayed from "creators/components/LoadingDelayed";
import LiveBriefsPage from "creators/pages/LiveBriefs";
import OnboardingPage from "creators/pages/Onboarding";
import TermsAndConditionsPage from "creators/pages/TermsAndConditions";
import VerifyEmailPage from "creators/pages/VerifyEmail";
import VideoControlsProvider from "creators/providers/VideoControlsProvider";
import { GET_VIEWER } from "creators/queries/GetViewer";
import {
  EmailVerificationStage,
  GetViewerQuery as IGetViewer,
} from "creators/types/graphql";
import onboardingIncomplete from "creators/utils/onboardingIncomplete";
import { Switch } from "react-router";
import { IRouteProps } from "shared/types/routes";

export const routes: IRouteProps[] = [
  {
    component: LiveBriefsPage,
    exact: true,
    path: [
      "/",
      "/brief/:id(\\d+)/:slug?",
      "/brief/:id(\\d+)/teaser/:previewHash",
    ],
    verificationRequired: true,
  },
  {
    component: TermsAndConditionsPage,
    exact: true,
    path: "/terms-and-conditions",
  },
  {
    component: VerifyEmailPage,
    exact: true,
    path: "/verify-email",
  },
  {
    component: OnboardingPage,
    exact: true,
    path: "/onboarding",
  },
];

function Home() {
  const { data, loading, error } = useQuery<IGetViewer>(GET_VIEWER);

  if (loading) {
    return <LoadingDelayed />;
  }

  if (error) {
    return <DataError error={error} />;
  }

  const emailVerified =
    data?.viewer?.emailVerificationStage === EmailVerificationStage.Verified;

  const onboarded = !!data?.viewer && !onboardingIncomplete(data.viewer);

  return (
    <VideoControlsProvider>
      <Switch>
        {routes.map(
          ({ component: Component, exact, path, verificationRequired }, i) => (
            <GuardedRoute
              emailVerified={emailVerified}
              exact={exact}
              key={i}
              onboarded={onboarded}
              path={path}
              termsAndConditionsAccepted={
                !!data?.viewer?.termsAndConditionsAccepted
              }
              verificationRequired={verificationRequired}
            >
              <Component />
            </GuardedRoute>
          ),
        )}
      </Switch>
    </VideoControlsProvider>
  );
}

export default Home;
