import Button, { SecondaryButton } from "creators/components/Button";
import Countdown from "creators/components/Countdown";
import FixedFooter from "creators/components/FixedFooter";
import DATE_FORMATS from "creators/constants/dateFormats";
import DIMENSIONS from "creators/constants/dimensions";
import EXTERNAL_LINKS from "creators/constants/externalLinks";
import FONTS from "creators/constants/fonts";
import IDS from "creators/constants/ids";
import { h3Styles } from "creators/styling/Heading/Heading";
import smallTextStyles from "creators/styling/SmallText";
import { BriefState, SubmissionState } from "creators/types/graphql";
import calculateTimeBetween from "creators/utils/calculateTimeBetween";
import textualize from "creators/utils/textualize";
import { format } from "date-fns";
import { useCallback, useState } from "react";
import styled from "styled-components";

interface IProps {
  applyMutationLoading?: boolean;
  briefState: BriefState;
  className?: string;
  handleApplicationClick?: () => void;
  handleWithdrawClick?: () => void;
  selectionDeadline?: Date;
  submissionState?: SubmissionState;
  successfullyApplied?: boolean;
  successfullyWithdrawn?: boolean;
  withdrawMutationLoading?: boolean;
}

const ApplyButton = styled(Button)`
  width: 100%;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    width: auto;
  }
`;

const WithdrawButton = styled(SecondaryButton)`
  // Provide space for Intercom badge on right side
  width: calc(100% - 5.25rem);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    width: calc(100% - 4.25rem);
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    width: auto;
  }
`;

const Heading = styled.div`
  ${h3Styles}
  font-size: 1rem;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    font-size: 1rem; // to overwrite value from media query in h3Styles
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    font-size: 1.5rem;
    font-weight: ${FONTS.WEIGHT.SEMI_BOLD};
  }
`;

const ResponsiveLayout = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  button {
    white-space: nowrap;
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    align-items: center;
    flex-direction: row;
    gap: 2rem;
  }
`;

const SmallText = styled.span`
  ${smallTextStyles}
`;

const SmallTextWrapped = styled(SmallText)`
  white-space: pre-wrap;
`;

const IntercomPadding = styled.div`
  // Provide space for Intercom badge on right side
  padding-right: 5.25rem;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    padding-right: 4.25rem;
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding-right: 2.25rem;
  }
`;

const IntercomPaddingBottom = styled.div`
  // Provide space for Intercom badge at the bottom
  padding-bottom: var(--v-spacing-3);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding-bottom: 0;
  }
`;

function TeaserFooter({
  applyMutationLoading,
  briefState,
  className,
  handleApplicationClick,
  handleWithdrawClick,
  selectionDeadline,
  submissionState,
  successfullyApplied,
  successfullyWithdrawn,
  withdrawMutationLoading,
}: IProps) {
  const [applicationsClosed, setApplicationsClosed] = useState(
    briefState === BriefState.Closed ||
      (selectionDeadline && selectionDeadline <= new Date()),
  );

  const closeApplications = useCallback(() => {
    setApplicationsClosed(true);
  }, []);

  const selectionDateCountdown = (selectionDeadline: Date) => {
    const countdownDateTime = (
      <Countdown
        date={selectionDeadline}
        largestOnly={true}
        onComplete={closeApplications}
      />
    );

    if (calculateTimeBetween(new Date(), selectionDeadline).days > 0) {
      return textualize("liveBriefs.teaser.footer.open.countdown", {
        countdown: countdownDateTime,
        date: format(selectionDeadline, DATE_FORMATS.DATE),
      });
    } else {
      return textualize("liveBriefs.teaser.footer.open.countdownHours", {
        countdown: countdownDateTime,
      });
    }
  };

  let content;
  if (submissionState === SubmissionState.Declined) {
    content = (
      <IntercomPadding>
        <Heading>
          {textualize("liveBriefs.teaser.footer.notSelected.message")}
        </Heading>
        <SmallText>
          <a
            href={EXTERNAL_LINKS.CREATOR_NOT_SELECTED}
            rel="noopener noreferrer"
            target="_blank"
          >
            {textualize("liveBriefs.teaser.footer.notSelected.link")}
          </a>
        </SmallText>
      </IntercomPadding>
    );
  } else if (submissionState === SubmissionState.InviteDeclined) {
    content = (
      <IntercomPadding>
        <Heading>
          {textualize("liveBriefs.teaser.footer.inviteDeclined")}
        </Heading>
      </IntercomPadding>
    );
  } else if (successfullyApplied) {
    content = (
      <IntercomPadding>
        <Heading>
          {textualize("liveBriefs.teaser.footer.applied.success")}
        </Heading>
        <SmallText>
          {textualize("liveBriefs.teaser.footer.applied.message", {
            selectionDate: format(selectionDeadline!, DATE_FORMATS.DATE),
          })}
        </SmallText>
      </IntercomPadding>
    );
  } else if (successfullyWithdrawn) {
    content = (
      <IntercomPadding>
        <Heading>
          {textualize("liveBriefs.teaser.footer.withdraw.success")}
        </Heading>
        <SmallText>
          {textualize("liveBriefs.teaser.footer.withdraw.message")}
        </SmallText>
      </IntercomPadding>
    );
  } else if (submissionState === SubmissionState.Applied) {
    content = (
      <ResponsiveLayout>
        <IntercomPaddingBottom>
          <Heading>
            {textualize("liveBriefs.teaser.footer.applied.heading")}
          </Heading>
          <SmallTextWrapped>
            {textualize("liveBriefs.teaser.footer.applied.message", {
              selectionDate: format(selectionDeadline!, DATE_FORMATS.DATE),
            })}
          </SmallTextWrapped>
        </IntercomPaddingBottom>
        <WithdrawButton
          id={IDS.BRIEF_TEASER.FOOTER.WITHDRAW_APPLICATION}
          isLoading={withdrawMutationLoading}
          onClick={handleWithdrawClick}
        >
          {textualize("liveBriefs.teaser.footer.withdraw.button")}
        </WithdrawButton>
      </ResponsiveLayout>
    );
  } else if (applicationsClosed) {
    content = (
      <IntercomPadding>
        <Heading>
          {textualize("liveBriefs.teaser.footer.applicationsClosed")}
        </Heading>
      </IntercomPadding>
    );
  } else {
    // covers submissionState === SubmissionState.ApplicationWithdrawn & undefined submissionState
    content = (
      <ResponsiveLayout>
        <ApplyButton
          id={IDS.BRIEF_TEASER.FOOTER.APPLY_NOW}
          isLoading={applyMutationLoading}
          onClick={handleApplicationClick}
        >
          {textualize("liveBriefs.teaser.footer.open.apply")}
        </ApplyButton>
        <IntercomPadding>
          {selectionDeadline && (
            <Heading>{selectionDateCountdown(selectionDeadline)}</Heading>
          )}
          <SmallText>
            {textualize("liveBriefs.teaser.footer.open.legalAgreement", {
              termsAndConditions: (
                <a
                  href={EXTERNAL_LINKS.TERMS_AND_CONDITIONS}
                  id={IDS.BRIEF_TEASER.FOOTER.TERMS_AND_CONDITIONS}
                  rel="noreferrer"
                  target="_blank"
                >
                  {textualize(
                    "liveBriefs.teaser.footer.open.termsAndConditions",
                  )}
                </a>
              ),
            })}
          </SmallText>
        </IntercomPadding>
      </ResponsiveLayout>
    );
  }

  return (
    <FixedFooter className={className} id={IDS.BRIEF_TEASER.FOOTER.ID}>
      {content}
    </FixedFooter>
  );
}

export default TeaserFooter;
