import PageContainer from "creators/components/PageContainer";
import DIMENSIONS from "creators/constants/dimensions";
import textualize from "creators/utils/textualize";
import styled from "styled-components";

interface IProps {
  firstName: string;
  id: string;
}

const StyledPageContainer = styled(PageContainer)`
  background: var(--v-color-grey-light);

  h1 {
    margin-bottom: var(--v-spacing-12);
  }
`;

const Window = styled.div<{ showVideo?: boolean }>`
  background: var(--v-color-white);
  border-radius: 0.5rem;
  padding: var(--v-spacing-4);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    padding: var(--v-spacing-8);
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding: var(--v-spacing-16);
  }
`;

const WindowLayout: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  firstName,
  id,
}) => (
  <StyledPageContainer id={id}>
    <h1>{textualize("onboarding.welcome", { firstName })}</h1>
    <Window>{children}</Window>
  </StyledPageContainer>
);

export default WindowLayout;
